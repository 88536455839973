import { useCallback, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import RegisterForm from 'components/registerForm'

import * as S from './style'

type RegisterModalProps = {
  isOpen: boolean
  closeModal: () => void
}

const RegisterModal = ({ isOpen, closeModal }: RegisterModalProps) => {
  const history = useHistory()

  const [step, setStep] = useState(1)

  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  useEffect(() => {
    if (isOpen) {
      setStep(1)
      setSelectedImage(null)
    }
  }, [isOpen])

  const handleResetState = () => {
    setStep(1)
    setSelectedImage(null)
    closeModal()
  }

  const handleLoginRedirect = () => {
    return setTimeout(() => history.push('/?isLogin=true'), 1000)
  }

  const isMobile = window.innerWidth < 800

  const customStyles = {
    overlay: {
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : '80%',
      height: isMobile ? '100%' : '770px',
      backgroundColor: '#fff',
      borderRadius: '15px',
      padding: 0
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal()
        handleResetState()
      }}
      style={customStyles}
    >
      <S.CloseButton onClick={closeModal}>
        <S.CloseButtonIcon src="/images/close-icon.svg" />
      </S.CloseButton>

      {step === 1 && (
        <S.Wrapper>
          <S.MobileWrapper>
            <S.Banner>
              <S.BannerImage src="/images/register-banner.jpg" />
            </S.Banner>

            <S.Container>
              <S.TitleContainer>
                <S.SecondFormTitle>
                  <span>Cadastre-se,</span>
                  <span>junte-se a nós!</span>
                </S.SecondFormTitle>
              </S.TitleContainer>

              <S.MailWrapper>
                <S.SecondDescription>
                  Continuar com o cadastro:
                </S.SecondDescription>
                <S.MailButton onClick={nextStep}>
                  <S.MailIcon src="/images/mail-icon.svg" /> Continuar com
                  E-mail
                </S.MailButton>
                <S.LoginButton
                  onClick={() => {
                    closeModal()
                    handleLoginRedirect()
                  }}
                >
                  Já tem uma conta? clique aqui pra continuar.
                </S.LoginButton>
              </S.MailWrapper>
            </S.Container>
          </S.MobileWrapper>

          <S.DesktopWrapper>
            <img src="/images/register-banner.jpg" alt="" />

            <S.Container>
              <S.TitleContainer>
                <S.SecondFormTitle>
                  <span>Cadastre-se,</span>
                  <span>junte-se a nós!</span>
                </S.SecondFormTitle>
              </S.TitleContainer>

              <S.MailWrapper>
                <S.SecondDescription>
                  Continuar com o cadastro:
                </S.SecondDescription>
                <S.MailButton onClick={nextStep}>
                  <S.MailIcon src="/images/mail-icon.svg" /> Continuar com
                  E-mail
                </S.MailButton>
                <S.LoginButton
                  onClick={() => {
                    closeModal()
                    handleLoginRedirect()
                  }}
                >
                  Já tem uma conta? clique aqui pra continuar.
                </S.LoginButton>
              </S.MailWrapper>
            </S.Container>
          </S.DesktopWrapper>
        </S.Wrapper>
      )}

      <RegisterForm
        step={step}
        setStep={setStep}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </Modal>
  )
}

export default RegisterModal
