const FlagIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="30"
    width="30"
    viewBox="0 96 960 960"
  >
    <path
      fill="currentColor"
      d="M200 936V256h343l19 86h238v370H544l-19-85H260v309h-60Z"
    />
  </svg>
)

export default FlagIcon
