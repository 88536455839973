import { createContext, useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { useQuery } from '@tanstack/react-query'

import api from 'services/api'

interface UserContextData {
  updateUser(data: any): Promise<void>
  getUserData: () => Promise<void>
  listUsers: () => Promise<{ users: IUser[] }>
  uploadFile(file: any): Promise<string>
  user: IUser
}

export interface IUsers {
  id: string
  firstname: string
  lastname: string
  about?: string
  pictures?: {
    profile: string
    cover: string
  }
}
export interface IUser {
  follows: []
  id: string
  firstname: string
  lastname: string
  country?: string
  birthdate: string
  email: string
  password: string
  gender?: 'M' | 'F' | 'NB' | 'AN'
  about?: string
  resetPasswordToken?: string
  resetPasswordExpire?: Date
  status: boolean
  ranking?: string
  favoriteItineraries: []
  followers: number
  profilePicture?: string
  coverPicture?: string
  facebook: string
  instagram: string
  interests?: Array<{
    interest: { name: string; id: string; description: string }
  }>
  itineraries?: Array<{
    id: string
    title: string
    coverImage: string
  }>
}

const UserContext = createContext<UserContextData>({} as UserContextData)

export const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<IUser | null>(null)

  const { refetch } = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const { data }: { data: IUser } = await api.get('/users')
      setUser(data)
      return data
    },
    staleTime: 5 * 60 * 1000
  })

  const getUserData = useCallback(async () => {
    try {
      await refetch()
    } catch (error) {
      toast.warning(
        'Você não está logado, conecte-se para melhores recursos!',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        }
      )
    }
  }, [refetch])

  const listUsers = async () => {
    try {
      const { data } = await api.get('/users')

      return data
    } catch (error) {
      toast.error(
        'Não foi possível carregar os usuários, tente novamente mais tarde',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        }
      )
    }
  }

  const followUser = async (id: string) => {
    try {
      const response = await api.post('/users/followers', {
        follower: id
      })

      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    } catch (e: any) {
      toast.error(e.response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }

  const updateUser = async (register: any) => {
    try {
      const response = await api.put('/users', {
        ...register,
        instagram: register.instagram,
        facebook: register.facebook
      })

      toast.success('Conta atualizada com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })

      return response.data
    } catch (e: any) {
      toast.error('Não foi possível editar os dados, tente novamente', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }

  const uploadFile = async (file: any) => {
    const formData = new FormData()

    formData.append('file', file)

    const response = await api.post('/users/upload', formData)

    return response.data.locationInS3
  }

  const stringifyUser: any = window.localStorage.getItem('user')

  const storageUser =
    stringifyUser && stringifyUser.length > 1
      ? JSON.parse(stringifyUser)
      : false

  return (
    <UserContext.Provider
      value={{
        user: user ?? storageUser,
        getUserData,
        updateUser,
        uploadFile,
        listUsers
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser(): UserContextData {
  return useContext(UserContext)
}
