import { useState } from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify'

import { Formik, Field } from 'formik'

import { useAuth } from 'contexts/auth.context'

import * as S from './style'

const LoginModal = (props: any) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const { signIn } = useAuth()

  const isMobile = window.innerWidth < 800

  const customStyles = {
    overlay: {
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : '580px',
      height: isMobile ? '100%' : '580px',
      borderRadius: '15px'
    }
  }

  const validate = (values: any) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = 'Campo obrigatório'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'E-mail inválido'
    }
    if (!values.password) {
      errors.password = 'Insira sua senha'
    }
    if (values.password && values.password.length < 6) {
      errors.password = 'Insira no mínimo 6 caracteres'
    }
    return errors
  }

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.closeModal}
        style={customStyles}
      >
        <S.Wrapper>
          <S.Container>
            <S.RowContainer>
              <S.CloseButton onClick={props.closeModal}>
                <img
                  src="/images/close_icon.png"
                  alt="close_icon"
                  height={20}
                  width={20}
                />
              </S.CloseButton>
            </S.RowContainer>

            <S.TitleContainer>
              <S.Title>Entrar</S.Title>
              <S.Description>
                Entre com seu e- mail e senha cadastrados
              </S.Description>
            </S.TitleContainer>

            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validate={validate}
              onSubmit={async (values) => {
                try {
                  await signIn(values)
                  window.location.href = '/minha-conta'
                } catch (error: any) {
                  toast.error('Usuário ou senha incorretos!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                  })
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit}>
                  <S.FormContainer>
                    <S.Label>E-mail</S.Label>
                    <Field
                      name="email"
                      as={S.Input}
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && <span>{errors.email}</span>}
                    <S.Label>Senha</S.Label>
                    <Field
                      name="password"
                      as={S.Input}
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />

                    <S.ShowPassword>
                      <S.ShowPasswordButton
                        type="button"
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? 'Esconder' : 'Mostrar senha'}
                      </S.ShowPasswordButton>
                      <img
                        src="/images/eye.svg"
                        alt=""
                        height={15}
                        width={15}
                      />
                    </S.ShowPassword>

                    {errors.password && <span>{errors.password}</span>}
                    <S.Button type="submit">Entrar</S.Button>
                    {/* <FacebookButton type="submit">
                  Entrar com Facebook
                </FacebookButton> */}
                    <S.AncorWrapper>
                      <S.Ancor onClick={props.closeModal} to="/recuperar-senha">
                        Esqueceu sua senha?
                      </S.Ancor>
                      <S.Ancor onClick={props.closeModal} to="/cadastre-se">
                        Cadastre-se!
                      </S.Ancor>
                    </S.AncorWrapper>
                  </S.FormContainer>
                </form>
              )}
            </Formik>
          </S.Container>
        </S.Wrapper>
      </Modal>
    </div>
  )
}

export default LoginModal
