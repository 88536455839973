import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
`

export const ImageContainer = styled.div`
  height: 300px;
  margin-top: 70px;
  width: 100%;
`

export const WidgetLink = styled.a`
  width: 300px;
  height: 50px;
`

export const WidgetImage = styled.img`
  object-fit: cover;
  object-position: center;
`

export const Image = styled.img<{ src: string }>`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
`

export const CardText = styled.div`
  width: 95%;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 22px rgba(188, 188, 188, 0.25);
  border-radius: 8px;
  padding: 80px 200px;
  justify-content: center;
  align-items: center;
  margin: 30px 0 30px 0;
  @media (max-width: 880px) {
    font-size: 20px;
    padding: 80px 40px;
  }
`

export const Text = styled.div`
  font-family: 'Baloo 2';
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: center;

  color: #343434;

  @media (max-width: 880px) {
    font-size: 18px;
  }
`
export const SubTitle = styled.div`
  font-family: 'Baloo 2';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 51px;
  text-align: center;

  color: #343434;

  @media (max-width: 880px) {
    font-size: 20px;
  }
`

export const Widget = styled.div`
  margin-top: 20px;
  width: 820px;
  height: 280px;

  @media (max-width: 880px) {
    width: 620px;
    height: 280px;
  }
  @media (max-width: 700px) {
    width: 95%;
  }
`

export const Divider = styled.div`
  width: 635px;
  height: 0px;
  margin: 24px 0 24px 0;
  border: 1px solid #858585;
  @media (max-width: 880px) {
    width: 80%;
  }
`

export const LignDivider = styled.img`
  width: 908px;
  height: 22px;

  @media (max-width: 1000px) {
    width: 800px;
  }
  @media (max-width: 880px) {
    width: 100%;
  }
`

export const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  align-items: center;
  justify-content: center;
  @media (max-width: 880px) {
    width: 100%;
  }
`

export const SecondTitle = styled.div`
  font-family: 'Baloo 2';
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  color: #343434;
`

export const HeaderTitle = styled.div`
  margin-bottom: 10px;
  font-family: 'Baloo 2';
  margin-top: 24px;
  font-weight: 600;
  font-size: 42px;
  @media (max-width: 764px) {
    font-size: 32px;
  }
  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }
`

export const Section = styled.section`
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`
