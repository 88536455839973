import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;

  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 40px 0 40px;
  background-color: #ffffff;
  text-align: center;
`
