import React from 'react'

import * as S from './style'
interface RankingProps {
  itinerariesLength: number
}

const RankingIcon: React.FC<RankingProps> = ({ itinerariesLength }) => {
  let icon: string
  let ranking: string

  if (itinerariesLength >= 0 && itinerariesLength <= 4) {
    icon = '/images/latao-icon.svg'
    ranking = 'Latão'
  } else if (itinerariesLength >= 5 && itinerariesLength <= 14) {
    icon = '/images/bronze-icon.svg'
    ranking = 'Bronze'
  } else if (itinerariesLength >= 15 && itinerariesLength <= 29) {
    icon = '/images/silver-icon.svg'
    ranking = 'Prata'
  } else if (itinerariesLength >= 30 && itinerariesLength <= 49) {
    icon = '/images/gold-icon.svg'
    ranking = 'Ouro'
  } else {
    icon = '/images/diamond-icon.svg'
    ranking = 'Diamante'
  }

  return (
    <S.Wrapper>
      <>{ranking} </>
      <img src={icon} height={35} width={32} alt={`Troféu tier ${ranking}`} />
    </S.Wrapper>
  )
}

export default RankingIcon
