import { ToastContainer } from 'react-toastify'

import { createGlobalStyle } from 'styled-components'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import RootProvider from './contexts/index'

import Router from './routes'

import 'react-toastify/dist/ReactToastify.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-datepicker/dist/react-datepicker.css'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100vh;
    background-image: url("/images/background_global.jpg");
    background-position: bottom center;
    background-size: cover;
    background-attachment: fixed;
    padding: 0;
    margin: 0;
    font-family: 'Baloo 2', cursive;

    &::-webkit-scrollbar {
      width: 0.75rem;
      height: 0.75rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 32rem;
      background-color: #d3d3e3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 32rem;
      background-color: #aeaec2;

      &:hover,
      &:active {
        background-color: #7d7f94;
        cursor: pointer;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  @media (prefers-color-scheme: dark) {
    body {
      background: white;
      color: #343434;
    }
  }
`

export default function App() {
  return (
    <RootProvider>
      <GlobalStyle />
      <ToastContainer />
      <Router />
    </RootProvider>
  )
}
