import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Formik, Field } from 'formik'

import { useUser } from 'contexts/user.context'

import DeleteAccountModal from 'components/deleteAccountModal'

import {
  Container,
  Description,
  Title,
  TitleContainer,
  Divider,
  Button,
  Text,
  SaveButton,
  Label,
  Input,
  EditForm,
  TextArea,
  CheckboxWrapper,
  ContainerColumn,
  CheckboxContainer,
  Form,
  BackButton,
  ButtonFooter,
  FileInput,
  Select,
  CoverImage,
  ProfileImage
} from './style'

const Update = () => {
  const { updateUser, user, uploadFile } = useUser()
  const interests = user.interests?.map((interest) => interest.interest.name)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const icons = {
    editIcon: '/images/desktop/profile/edit_icon.png',
    deleteIcon: '/images/desktop/profile/delete_icon.png'
  }

  const uploadFileCallback = async (file: any) => {
    const response = await uploadFile(file)

    return response
  }

  const Collapse = (props: any) => {
    const [visible, setVisible] = useState(false)

    function isOpen() {
      setVisible(!visible)
    }

    return (
      <>
        <Button type="button" onClick={isOpen} style={{ marginTop: 25 }}>
          <Text>{props.title}</Text>
          <img
            src={props.icon}
            height={15}
            width={15}
            alt="Ícone de caneta no papel"
          />
        </Button>
        {visible && props.hiddenContent}
      </>
    )
  }

  const validate = (values: any) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = 'Campo obrigatório'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'E-mail inválido'
    }
    if (!values.password) {
      errors.password = 'Insira sua senha'
    }
    if (!values.firstname) {
      errors.firstname = 'Este campo é obrigatório'
    }
    if (!values.lastname) {
      errors.lastname = 'Este campo é obrigatório'
    }
    if (!values.birthdate) {
      errors.birthdate = 'Este campo é obrigatório'
    }
    if (values.password && values.password.length < 6) {
      errors.password = 'Insira no mínimo 6 caracteres'
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Senhas não conferem'
    }
    if (!values.confirmPolicy) {
      errors.confirmPolicy = 'Campo obrigatório'
    }
    return errors
  }

  const history = useHistory()

  return (
    <Container>
      <DeleteAccountModal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
      <TitleContainer>
        <Title>Editar</Title>
        <Description>- Perfil de usuário -</Description>
      </TitleContainer>
      <Divider />
      {user && (
        <Formik
          initialValues={{
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            facebook: user?.facebook,
            instagram: user?.instagram,
            birthdate: user.birthdate,
            about: user.about,
            interests: interests,
            gender: user.gender,
            coverPicture: user.coverPicture,
            profilePicture: user.profilePicture
          }}
          // validate={validate}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await updateUser(values)

              setTimeout(() => {
                history.push('/minha-conta')
              }, 2000)
            } catch {
              toast.error('Não foi possível salvar os dados', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              })
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Collapse
                title="Dados Pessoais"
                hiddenContent={
                  <EditForm>
                    <label>Nome</label>
                    <Field
                      as={Input}
                      name="firstname"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstname}
                    />
                    {errors.firstname && <span>{errors.firstname}</span>}

                    <Label>Sobrenome</Label>
                    <Field
                      as={Input}
                      name="lastname"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastname}
                    />
                    {errors.lastname && <span>{errors.lastname}</span>}

                    <Label>Data de Nascimento</Label>
                    <Field
                      as={Input}
                      name="birthdate"
                      type="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.birthdate}
                    />
                    {errors.birthdate && <span>{errors.birthdate}</span>}

                    <Label>Gênero</Label>
                    <Field
                      name="gender"
                      as={Select}
                      placeholder="Selecione uma opção"
                    >
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                      <option value="NB">Não binário</option>
                      <option value="AN">Outro</option>
                    </Field>
                    {errors.gender && <span>{errors.gender}</span>}
                  </EditForm>
                }
                icon={icons.editIcon}
              />

              <Collapse
                title="Informações sobre o usuário"
                hiddenContent={
                  <EditForm>
                    <Label>Sobre você</Label>
                    <Field
                      name="about"
                      as={TextArea}
                      onBlur={handleBlur}
                      value={values.about}
                      onChange={handleChange}
                      placeholder="Queremos saber mais! Qual seu perfil de viajante? Qual foi sua melhor viagem? Do que você mais gosta?"
                    />
                    <CheckboxWrapper>
                      <ContainerColumn>
                        <CheckboxContainer>
                          <Field
                            name="interests"
                            value="cidade_historica"
                            type="checkbox"
                          />
                          <Label htmlFor="cidade_historica">
                            Cidade Histórica
                          </Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="praia"
                            name="interests"
                            value="praia"
                            type="checkbox"
                          />
                          <Label htmlFor="cidade_historica">Praia</Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="montanha"
                            name="interests"
                            value="montanha"
                            type="checkbox"
                          />
                          <Label htmlFor="montanha">Montanha</Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="camping"
                            name="interests"
                            value="camping"
                            type="checkbox"
                          />
                          <Label htmlFor="camping">Camping</Label>
                        </CheckboxContainer>
                      </ContainerColumn>

                      <ContainerColumn>
                        <CheckboxContainer>
                          <Field
                            id="cidade_moderna"
                            name="interests"
                            value="cidade_moderna"
                            type="checkbox"
                          />
                          <Label htmlFor="cidade_moderna">Cidade Moderna</Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="campo"
                            name="interests"
                            value="campo"
                            type="checkbox"
                          />
                          <Label htmlFor="campo">Campo</Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="cachoeira"
                            name="interests"
                            value="cachoeira"
                            type="checkbox"
                          />
                          <Label htmlFor="cachoeira">Cachoeira</Label>
                        </CheckboxContainer>

                        <CheckboxContainer>
                          <Field
                            id="trekking"
                            name="interests"
                            value="trekking"
                            type="checkbox"
                          />
                          <Label htmlFor="trekking">Trekking</Label>
                        </CheckboxContainer>
                      </ContainerColumn>
                    </CheckboxWrapper>
                  </EditForm>
                }
                icon={icons.editIcon}
              />

              <Collapse
                title="Dados de Acesso"
                hiddenContent={
                  <EditForm>
                    <Label>E-mail</Label>
                    <Field
                      as={Input}
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && <span>{errors.email}</span>}
                  </EditForm>
                }
                icon={icons.editIcon}
              />

              <Collapse
                title="Redes sociais"
                hiddenContent={
                  <EditForm>
                    <Label>Instagram</Label>
                    <Field
                      as={Input}
                      type="text"
                      name="instagram"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.instagram}
                      placeholder="redsterna_ig"
                    />
                    <Description>
                      Complete somente com seu nome de usuário (sem @)
                    </Description>
                    <Label>Facebook</Label>
                    <Description>
                      Complete somente com seu nome de usuário
                      (https://www.facebook.com/o nome que aparece aqui)
                    </Description>
                    <Field
                      as={Input}
                      type="text"
                      name="facebook"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.facebook}
                      placeholder="redsterna_fb"
                    />
                  </EditForm>
                }
                icon={icons.editIcon}
              />

              <Collapse
                title="Fotos"
                hiddenContent={
                  <EditForm>
                    <Label>Foto de Pefil</Label>
                    <FileInput
                      type="file"
                      onChange={async (e: any) =>
                        await uploadFileCallback(e.target.files[0]).then(
                          (response) =>
                            setFieldValue('profilePicture', response)
                        )
                      }
                    />
                    {values.profilePicture && values.profilePicture !== '' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            marginTop: 10
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => setFieldValue('profilePicture', '')}
                          >
                            X
                          </button>
                          <ProfileImage
                            src={values.profilePicture}
                            alt="cover"
                          />
                        </div>
                      </div>
                    )}
                    <Label> Foto de Capa</Label>
                    <FileInput
                      type="file"
                      onChange={async (e: any) =>
                        await uploadFileCallback(e.target.files[0]).then(
                          (response) => setFieldValue('coverPicture', response)
                        )
                      }
                    />
                    {values.coverPicture && values.coverPicture !== '' && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            marginTop: 10
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => setFieldValue('coverPicture', '')}
                          >
                            X
                          </button>
                          <CoverImage src={values.coverPicture} alt="cover" />
                        </div>
                      </div>
                    )}
                  </EditForm>
                }
                icon={icons.editIcon}
              />

              <Button
                onClick={() => setIsModalVisible(true)}
                type="button"
                style={{ marginTop: 50 }}
              >
                <Text>Excluir conta</Text>
                <img
                  src={icons.deleteIcon}
                  height={15}
                  width={15}
                  alt="Ícone de caneta no papel"
                />
              </Button>
              <ButtonFooter>
                <BackButton to="/minha-conta">Voltar</BackButton>
                <SaveButton type="submit">Salvar</SaveButton>
              </ButtonFooter>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  )
}

export default Update
