import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from 'services/api'

import * as S from './style'

function ChangePassword() {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      // Aqui você pode fazer a requisição para a API para mudar a senha.
      const queryParams = new URLSearchParams(location.search)
      const token = queryParams.get('token')

      if (!token) {
        toast.error('Token ausente, verifique a url', { theme: 'dark' })
      }

      const response = await api.post('/users/password', {
        password: password,
        token: token
      })
      setIsLoading(false)

      if (response.status === 204)
        toast.success('Senha alterada com sucesso!', { theme: 'dark' })
      else toast.error('Não foi possível alterar a senha', { theme: 'dark' })
    } catch (error) {
      setIsLoading(false)
      setErrorMessage(
        'Ocorreu um erro ao mudar a senha. Verifique se colocou uma senha válida'
      )
    }
  }

  return (
    <S.Container>
      <h1>Mudança de senha</h1>
      <S.Form onSubmit={handleSubmit}>
        <S.InputContainer>
          <label htmlFor="password">Senha:</label>
          <S.Input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            required
          />
        </S.InputContainer>
        <S.InputContainer>
          <label htmlFor="confirm-password">Confirme a senha:</label>
          <S.Input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
          />
        </S.InputContainer>
        {errorMessage && <p>{errorMessage}</p>}
        <S.Button type="submit" disabled={isLoading}>
          {isLoading ? 'Enviando...' : 'Enviar'}
        </S.Button>
      </S.Form>
    </S.Container>
  )
}

export default ChangePassword
