import styled, { css } from 'styled-components'

export const styleButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  width: fit-content;
  border-radius: 8px;
  padding: 10px 15px;

  outline: none;
  border: none;
  border: 1px solid #d60000;
  background-color: #d60000;
  cursor: pointer;

  color: #fdfdfd;
  font-size: 20px;
  font-weight: 500;

  transition:
    color 0.25s,
    background-color 0.25s;

  & p {
    margin: 0;
    padding: 0;
    color: #fdfdfd;
  }

  @media (max-width: 800px) {
    font-size: 10px;
  }

  &:hover {
    background-color: #ff3a3a;
  }
`

export const styleSecondaryButton = css`
  background-color: transparent;
  border-color: #343434;

  &:hover {
    background-color: #efefef;
  }

  & p {
    color: #343434;
  }
`

type ButtonProps = {
  secondary?: boolean
}

export const Button = styled.button<ButtonProps>`
  ${styleButton}
  ${({ secondary }) => secondary && styleSecondaryButton}
`

export default Button
