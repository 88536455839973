import { useEffect, useState } from 'react'

import { useUser } from 'contexts/user.context'

import * as S from './style'

const UserSearch = () => {
  const [search, setSearch] = useState('')

  const [users, setUsers] = useState<any>([])

  const { listUsers } = useUser()

  useEffect(() => {
    ;(async () => {
      const response = await listUsers()
      setUsers(response.users)
    })()
  }, [listUsers])

  type userInfoProps = {
    id: string
    profilePicture: string
    firstname: string
    lastname: string
    about: string
  }

  const filteredUsers =
    search.length > 0
      ? users.filter(
          (user: any) =>
            user.firstname.toLowerCase().includes(search.toLowerCase()) ||
            user.lastname.toLowerCase().includes(search.toLowerCase())
        )
      : []

  return (
    <S.Wrapper>
      <S.Container>
        <S.TitleContainer>
          <S.Title>Buscar Viajante</S.Title>
          <S.Divider />
          <S.Description>Pesquisa por nome de usuário:</S.Description>
        </S.TitleContainer>
        <S.RowContainer>
          <S.Input
            type="input"
            placeholder="Procurar..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </S.RowContainer>

        <S.UserWrapper>
          {search.length > 0 ? (
            <>
              {filteredUsers.map((user: userInfoProps) => {
                return (
                  <S.UserContainer
                    key={user.id}
                    to={{
                      pathname: `usuario/${user.id}`,
                      search: `?user_id=${user.id}`,
                      state: { user }
                    }}
                    id={user.id}
                  >
                    <S.RowContainer>
                      <S.ProfileImageContainer>
                        <S.ProfileIcon
                          src={
                            user.profilePicture && user.profilePicture !== ''
                              ? user.profilePicture
                              : '/images/profile-empty.png'
                          }
                        />
                      </S.ProfileImageContainer>

                      <S.ColumnContainer>
                        <S.UserTitle
                          to={{
                            pathname: `usuario/${user.id}`,
                            state: { user }
                          }}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </S.UserTitle>
                        <S.UserSummary>{user.about}</S.UserSummary>
                      </S.ColumnContainer>
                    </S.RowContainer>
                  </S.UserContainer>
                )
              })}
            </>
          ) : (
            <>
              {users.map((user: userInfoProps) => {
                return (
                  <S.UserContainer
                    key={user.id}
                    to={{
                      pathname: `usuario/${user.id}`,
                      search: `?user_id=${user.id}`,
                      state: { user }
                    }}
                    id={user.id}
                  >
                    <S.RowContainer>
                      <S.ProfileImageContainer>
                        <S.ProfileIcon
                          src={
                            user.profilePicture && user.profilePicture !== ''
                              ? user.profilePicture
                              : '/images/profile-empty.png'
                          }
                        />
                      </S.ProfileImageContainer>

                      <S.ColumnContainer>
                        <S.UserTitle
                          to={{
                            pathname: `usuario/${user.id}`,
                            state: { user }
                          }}
                        >
                          {`${user.firstname} ${user.lastname}`}
                        </S.UserTitle>
                        <S.UserSummary>{user.about}</S.UserSummary>
                      </S.ColumnContainer>
                    </S.RowContainer>
                  </S.UserContainer>
                )
              })}
            </>
          )}
        </S.UserWrapper>
        <S.BackButton to="/minha-conta">Voltar</S.BackButton>
      </S.Container>
    </S.Wrapper>
  )
}

export default UserSearch
