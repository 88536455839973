import { BrowserRouter, Switch, Route } from 'react-router-dom'

import styled from 'styled-components'

import { useUser } from 'contexts/user.context'

import ChangePassword from 'pages/ChangePassword'
import ErrorPage from 'pages/Error'
import Ficha from 'pages/Ficha'
import Home from 'pages/Home'
import CreateItinerary from 'pages/Itinerary/Create'
import ListItinerary from 'pages/Itinerary/List'
import ShowItinerary from 'pages/Itinerary/Show'
import UpdateItinerary from 'pages/Itinerary/Update'
import LoginToken from 'pages/LoginToken'
import Map from 'pages/Map'
import Privacy from 'pages/Privacy'
import Profile from 'pages/Profile'
import RecoveryPassword from 'pages/Recovery'
import Register from 'pages/Register'
import Suggestions from 'pages/Suggestions'
import SuggestionsPage from 'pages/Suggestions/Page'
import Update from 'pages/Update'
import User from 'pages/User'
import UserSearch from 'pages/UserSearch'

import Footer from 'components/footer'
import Header from 'components/header'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100vh;
  width: 100%;
  background-color: #ffff;
`

const Router = () => {
  const { user } = useUser()

  const authenticated = !!user

  return (
    <BrowserRouter>
      <Wrapper>
        <Header authenticated={authenticated} />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mudar-senha" component={ChangePassword} />
          <Route exact path="/recuperar-senha" component={RecoveryPassword} />
          <Route exact path="/destinos" component={ListItinerary} />
          <Route exact path="/roteiro/:id" component={ShowItinerary} />
          <Route exact path="/ficha/:city" component={Ficha} />

          <Route exact path="/dicas-de-viagem" component={Suggestions} />
          <Route
            exact
            path="/dicas-de-viagem/:name"
            component={SuggestionsPage}
          />
          <Route exact path="/mapa" component={Map} />
          <Route exact path="/cadastre-se" component={Register} />
          <Route exact path="/usuario/:id" component={User} />
          <Route exact path="/politicas-privacidade" component={Privacy} />
          <Route exact path="/login-token" component={LoginToken} />

          {authenticated && (
            <>
              <Route exact path="/minha-conta" component={Profile} />
              <Route exact path="/editar-perfil" component={Update} />
              <Route exact path="/criar-roteiro" component={CreateItinerary} />
              <Route
                exact
                path="/editar-roteiro/:id"
                component={UpdateItinerary}
              />
              <Route exact path="/buscar-usuarios" component={UserSearch} />
            </>
          )}
          <Route exact path="*" component={ErrorPage} />
          <Route exact path="/error" component={ErrorPage} />
        </Switch>
        <Footer />
      </Wrapper>
    </BrowserRouter>
  )
}

export default Router
