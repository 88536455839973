import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-top: 70px;
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 50px;
  }
`

export const LinkTittle = styled(Link)`
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const HeaderTab = styled.div`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  justify-content: space-between;
  background-color: #fff;
`

export const ProfileImageContainer = styled.div`
  border: 5px solid #fff;
  height: 270px;
  border-radius: 100%;
  width: 270px;
  margin-top: -250px;
  margin-left: 20px;
  display: block;

  @media (max-width: 800px) {
    height: 100px;
    width: 100px;
    margin: 0;
  }
`

export const ProfileIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
`

export const CoverIcon = styled.img`
  height: 500px;
  background-size: cover;
  width: 100%;
  border: 5px solid #fff;
  align-self: center;

  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
`

export const Ancor = styled(Link)``

export const Icon = styled.img`
  display: flex;
`
export const FollowButton = styled.button`
  background-color: #ec0000;
  font-size: 20px;
  padding: 10px 40px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 50px;

  @media (max-width: 700px) {
    font-size: 16px;
    margin-right: 10px;

    padding: 5px 20px;
  }
`

export const FollowIcon = styled.img`
  display: flex;
  margin: 0 15px 0 15px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Mobile = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const RowMobile = styled.div`
  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const MobileProfilePic = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
  }
`

export const DesktopProfilepic = styled.div`
  display: flex;

  @media (max-width: 800px) {
    display: none;
  }
`

export const HeadingContainer = styled.div`
  display: flex;
  text-transform: uppercase;
  margin-left: 40%;
  justify-content: space-between;
  padding: 9px;

  @media (max-width: 800px) {
    margin: 0;
  }
`

export const SocialMediaButton = styled.a`
  display: flex;
  border: none;
  background: none;
  padding: 0;
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #555;
  width: 180px;
  height: auto;
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
`

export const Destination = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 10px;
`

export const Itinerary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 10px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Text = styled.div`
  display: flex;
  font-size: 14px;
  align-self: flex-start;
  text-transform: uppercase;

  overflow-y: auto;
`

export const SmallText = styled.div`
  display: flex;
  font-size: 12px;
  align-self: center;
  text-transform: uppercase;
  margin-left: 10px;
`

export const Title = styled.span`
  font-size: 20px;
  margin: 10px;
  text-transform: uppercase;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`

export const ItineraryTittle = styled.h4`
  @media (max-width: 800px) {
    font-size: 14px;
  }
`

export const UserName = styled.span`
  font-weight: bold;
`

export const LargeText = styled.span`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;

  padding: 20px 20px 0 20px;
`
export const CollapseDescription = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`

export const FollowGroupContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40%;
`

export const InterestsContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  height: auto;
  flex-wrap: wrap;
  width: 55%;

  @media (max-width: 800px) {
    width: auto;
  }
`

export const InterestsHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const FollowColumn = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
`

export const Counter = styled.div`
  display: flex;
  color: #e90000;
  align-items: center;
  font-weight: bold;
  padding-left: 20px;
`

export const Roadmap = styled.span`
  color: #e90000;
  font-weight: bold;
`

export const AboutContainer = styled.div`
  background-color: #f5f5f5;
  color: #000;
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    width: auto;
  }
`

export const Select = styled.div`
  background-color: #f5f5f5;
  color: #000;
  width: 100%;
  height: auto;
  padding: 10px 20px 10px 20px;
`

export const SelectDescription = styled.span`
  font-size: 16px;
  color: #000;
  margin: 0;
  font-weight: bold;
`

export const Divider = styled.div`
  width: auto;
  height: 1px;
  background-color: #000;
  margin-bottom: 25px;
`

export const ItineraryContainer = styled.div<{ children: any }>`
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 30px;
`

export const HeadingItinerary = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  height: auto;
`
export const ItineraryButton = styled(Ancor)`
  display: flex;
  text-transform: uppercase;
  background-color: #e90000;
  color: #fff;
  font-weight: bold;
  border: none;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  padding: 5px;

  @media (max-width: 800px) {
    font-size: 10px;
  }
`

export const Button = styled.button`
  width: 300px;
  height: 40px;
  margin: 0 0 20px;
  border: 1px solid #dcdcdc;
  padding: 12px 6px 12px 6px;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
`

export const FollowContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  margin: 20px 0 20px 0;
  padding: 20px;
  flex-direction: column;
`
