import React, { ReactNode, useState } from 'react'

import * as S from './style'

type CollapseProps = {
  title: string
  children: ReactNode
}

function Collapse(props: CollapseProps) {
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(!isOpen)
  }
  const isMobile = window.innerWidth < 800

  return (
    <S.Collapse>
      <S.Button onClick={toggle}>
        {props.title}

        {isMobile ? null : <S.Icon src="/images/collapse-icon.svg" />}
      </S.Button>
      {isOpen && <S.Content>{props.children}</S.Content>}
    </S.Collapse>
  )
}

export default Collapse
