import { Link } from 'react-router-dom'

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;
  background-color: #ffffff;
  padding-bottom: 200px;
`

export const Tittle = styled.h4`
  width: fit-content;
  margin: 0;
`

export const Anchor = styled(Link)`
  width: auto;
`

export const TittleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 200px;
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
  width: 100%;
  background-image: url('/images/dicas/capa.jpeg');
  height: 400px;
  background-position: center center;
  h2 {
    font-size: 30px;
    color: white;
    font-style: bold;
    position: absolute;
    margin-top: 10%;
    margin-left: 19%;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 300px;
  }
`

export const Section = styled.section`
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  min-height: 500px;
  width: 700px;
  @media (max-width: 620px) {
    width: 95%;
  }
`

export const Card = styled.div`
  width: 176px;
  height: 156px;
  background-color: rgba(206, 205, 202, 0.3);
  border-radius: 15px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
  }
`

export const Line = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 0 10px 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e90000;
    color: white;
    font-weight: bold;
    border: 0;
  }
`

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 800px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`

export const Title = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #454545;
  font-weight: 400;
  margin-bottom: 18px;

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`

export const Image = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`

export const HeaderTitle = styled.div`
  margin-bottom: 20px;
  font-family: 'Baloo 2';
  margin-top: 74px;
  font-weight: 600;
  font-size: 42px;
  @media (max-width: 764px) {
    font-size: 32px;
  }
  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }
`

export const Description = styled.div`
  margin-bottom: 50px;
  font-size: 24px;

  @media (max-width: 764px) {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
`
