import React, { useState, useEffect } from 'react'

import axios from 'axios'

import * as S from './style'

type CambioProps = {
  latitude: number
  longitude: number
}

const Cambio = (props: CambioProps) => {
  const [localCurrency, setLocalCurrency] = useState<string>('')
  const [localValue, setLocalValue] = useState<number | null>(null)
  const [exchangeRates, setExchangeRates] = useState<{ [key: string]: number }>(
    {}
  )

  const OPEN_CAGE_API_KEY = '6315029cc92a473db144b7b21296a510'
  const OPEN_EXCHANGE_RATES_APP_ID = '8d51c48b96ca44e48313aa76ae206b58'
  const OPEN_EXCHANGE_RATES_API_URL = `https://openexchangerates.org/api/latest.json?app_id=${OPEN_EXCHANGE_RATES_APP_ID}`

  useEffect(() => {
    if (props.latitude && props.longitude) {
      // Obter a moeda local com OpenCage
      const url = `https://api.opencagedata.com/geocode/v1/json?key=${OPEN_CAGE_API_KEY}&q=${props.latitude}+${props.longitude}&pretty=1`
      axios.get(url).then((result) => {
        const localCurrency =
          result.data.results[0].annotations.currency.iso_code
        setLocalCurrency(localCurrency)

        // Obter as taxas de câmbio com OpenExchangeRates
        const url2 = `${OPEN_EXCHANGE_RATES_API_URL}&base=USD&symbols=${localCurrency}`
        axios.get(url2).then((result2) => {
          const exchangeRates = result2.data.rates
          setExchangeRates(exchangeRates)
          setLocalValue(1 / exchangeRates[localCurrency]) // valor de 1 USD em moeda local
        })
      })
    }
  }, [props.latitude, props.longitude, OPEN_EXCHANGE_RATES_API_URL])

  return (
    <S.CambioText>
      <S.Text> 1{localCurrency} </S.Text>
      <S.BoldText>$: {localValue?.toFixed(2)}</S.BoldText>
    </S.CambioText>
  )
}

export default Cambio
