import * as S from './style'

const Footer = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.List>
          <S.ContentContainer>
            <S.Logo>
              <S.SternaLogo
                src="/images/redsterna-footer.png"
                alt="Redsterna logo "
              />
            </S.Logo>
          </S.ContentContainer>
          <S.ContentRow>
            <S.Column>
              <S.MediaTittle> Políticas de privacidade</S.MediaTittle>
              <S.Link>Termos de Uso</S.Link>
              <S.Link href="politicas-privacidade">
                Política de Privacidade
              </S.Link>
            </S.Column>

            <S.Column>
              <S.MediaTittle>Sobre nós</S.MediaTittle>
              <S.Link> Quem somos </S.Link>
            </S.Column>

            <S.Column>
              <S.MediaTittle>Suporte</S.MediaTittle>
              <S.LinkContact> contato@redsterna.com.br</S.LinkContact>
            </S.Column>
          </S.ContentRow>
          <S.ContentContainer>
            <S.MediaContainer>
              <S.MediaTittle>Siga-nos</S.MediaTittle>
              <S.RowMedia>
                <S.SocialLogo
                  href="https://www.facebook.com/malasprontasviagens"
                  target="_blank"
                >
                  <S.Image src="/images/facebook.svg" alt="Redsterna logo " />
                </S.SocialLogo>
                <S.SocialLogo
                  href="https://www.instagram.com/redsternaoficial/"
                  target="_blank"
                >
                  <S.Image
                    src="/images/instagram-icon.png"
                    alt="Redsterna logo "
                  />
                </S.SocialLogo>
              </S.RowMedia>
            </S.MediaContainer>
          </S.ContentContainer>
        </S.List>
      </S.Container>
    </S.Wrapper>
  )
}

export default Footer
