import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useQuery } from '@tanstack/react-query'
import FsLightbox from 'fslightbox-react'

import api from 'services/api'

import { useItineraries } from 'contexts/itinerary.context'
import { useUser } from 'contexts/user.context'

import ModalAccommodation from 'models/ModalAccommodation'
import { ModelCity } from 'models/ModelCity'
import { ModelItineraries } from 'models/ModelItineraries'

import LoginModal from 'components/modal'
import StarRating from 'components/starRating'

import { Column, Container, Content, Header, Line } from './style'
import * as S from './style'

export default function ShowItinerary() {
  const [fetch, setFetch] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [toggler, setToggler] = useState<{ [index: number]: boolean }>({})

  const { id } = useParams<{ id: string }>()

  const { getUserData, user } = useUser()

  const { favoriteItinerary, rateItinerary } = useItineraries()

  const {
    isLoading,
    isError,
    data: itinerary
  } = useQuery({
    queryKey: ['Itineraries', id],
    queryFn: async () => {
      if (id) {
        const { data }: { data: ModelItineraries } = await api.get(
          `/itineraries/${id}`
        )

        const { rates } = data

        const rate = rates
          ? rates.reduce((a: number, b: { rate: number }) => a + b.rate, 0) /
            rates.length
          : 0

        setFetch(false)
        return { rate, ...data }
      }
    },
    enabled: !!id && fetch,
    staleTime: 15 * 60 * 1000
  })

  const authenticated = !!user

  useEffect(() => {
    if (itinerary?.cities) {
      itinerary.cities.forEach((_, index: number) => {
        setToggler((oldToggler) => ({ ...oldToggler, [index]: false }))
      })
    }
  }, [itinerary])

  const onFavoriteItinerary = useCallback(
    async (id: string) => {
      if (user) {
        await favoriteItinerary(id)
        await getUserData()
      } else {
        setIsModalVisible(true)
      }
    },
    [favoriteItinerary, getUserData, user]
  )

  const onRateItinerary = useCallback(
    async ({ id, rate }: { id: string; rate: number }) => {
      if (user) {
        await rateItinerary({ id, rate })
      } else {
        setIsModalVisible(true)
      }
    },
    [rateItinerary, user]
  )

  // validar se  o usuario está autenticado antes disso
  const isFavorited = useMemo(() => {
    if (authenticated && itinerary) {
      return (
        user.favoriteItineraries?.find(
          ({ itinerary: favorite }: { itinerary: { id: string } }) => {
            return favorite.id === itinerary.id
          }
        ) !== undefined
      )
    }

    return false
  }, [authenticated, itinerary, user.favoriteItineraries])

  const isMobile = window.innerWidth < 960

  if (isLoading || !itinerary) {
    return <h1>Carregando</h1>
  }

  if (isError) {
    return <h1>Erro</h1>
  }

  return (
    <Container>
      <LoginModal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
      <Header cover={itinerary.coverImage} />
      <Line fullWidth>
        <Content>
          <S.ItineraryHeader>
            <S.HeaderRow>
              <S.HeaderIcon src="/images/explore-icon.png" />
              <div>
                <S.Tittle>{itinerary.title}</S.Tittle>
                {itinerary.author && (
                  <S.AuthorName
                    to={{
                      pathname: `/usuario/${itinerary.author.id}`
                    }}
                  >
                    <span>{itinerary.author.firstname} </span>
                    <span>{itinerary.author.lastname} </span>
                  </S.AuthorName>
                )}
              </div>
            </S.HeaderRow>

            <S.Icons>
              <StarRating
                value={itinerary.rate}
                onClick={(value: number) => {
                  try {
                    onRateItinerary({ id: itinerary.id, rate: value })
                    toast.success('Roteiro avaliado!', {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                      theme: 'dark'
                    })
                  } catch {
                    toast.error('Não foi possível avaliar este roteiro :c', {
                      position: 'top-right',
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                      theme: 'dark'
                    })
                  }
                }}
              />

              {!authenticated ? null : (
                <S.Heart
                  color={isFavorited ? '#c80000' : '#bfbfbf'}
                  onClick={() => onFavoriteItinerary(itinerary.id)}
                  size={40}
                />
              )}
            </S.Icons>
          </S.ItineraryHeader>

          <S.InfosWrapper>
            <S.InfoCard>
              <S.InfoIcon src="/images/calendar-icon.svg" />
              <S.InfoText>Dias de viagem:</S.InfoText>
              <S.BoldText>{itinerary.days}</S.BoldText>
            </S.InfoCard>
            <S.InfoCard>
              <S.InfoIcon src="/images/cards-icon.svg" />
              <S.InfoText>Custo total por pessoa:</S.InfoText>
              <S.BoldText>R$ {itinerary.spent}</S.BoldText>
            </S.InfoCard>
          </S.InfosWrapper>

          <S.Summary>
            <S.InfoCard>
              <S.SummaryTitle>
                <span>Resumo da </span> <span>viagem</span>
              </S.SummaryTitle>
            </S.InfoCard>

            <S.DescriptionCard>
              {itinerary.summary !== ''
                ? itinerary.summary
                : 'Este roteiro não possui resumo'}
            </S.DescriptionCard>
          </S.Summary>
          <Column>
            <S.CheckInTittle>Cidades visitadas:</S.CheckInTittle>
            {itinerary?.cities?.map((city: ModelCity, index: number) => (
              <Column key={city.id}>
                <S.CityCard>
                  <S.CityIcon src="/images/Vector.svg" />
                  <span>{city.name}</span>
                </S.CityCard>

                {city.accommodations?.length > 0 &&
                  city.accommodations.map((host: ModalAccommodation) => (
                    <>
                      {host.name && host.name !== '' && (
                        <S.HotelCard>
                          <S.InfoText>Hospedagem: </S.InfoText>{' '}
                          <S.HotelName>{host.name}</S.HotelName>
                          {host.rate >= 1 ? (
                            <StarRating value={host.rate} />
                          ) : (
                            <span>Sem avaliação</span>
                          )}
                        </S.HotelCard>
                      )}
                    </>
                  ))}

                {city.description ? (
                  <S.SummaryDescription>
                    <S.DescriptionText>{city.description}</S.DescriptionText>
                  </S.SummaryDescription>
                ) : null}

                <S.ImageWrapper>
                  <S.ImageContainer>
                    {city?.images
                      ?.slice(0, isMobile ? 1 : 6)
                      .map(({ file: image }) => (
                        <S.LightboxWrapper key={image}>
                          <S.LightboxButton
                            onClick={() => {
                              setToggler((oldToggler) => ({
                                ...oldToggler,
                                [index]: !oldToggler[index]
                              }))
                            }}
                          >
                            <S.Photos src={image} key={image} alt={city.name} />
                          </S.LightboxButton>
                        </S.LightboxWrapper>
                      ))}

                    {(city?.images?.length ?? 0) > 0 && (
                      <FsLightbox
                        toggler={toggler[index] !== undefined && toggler[index]}
                        type="image"
                        sources={city?.images?.map(
                          ({ file }: { file: string }) => file
                        )}
                      />
                    )}
                  </S.ImageContainer>
                </S.ImageWrapper>
              </Column>
            ))}
          </Column>
        </Content>
      </Line>
    </Container>
  )
}
