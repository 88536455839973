import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 32rem;
    background-color: #d3d3e3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 32rem;
    background-color: #aeaec2;

    &:hover,
    &:active {
      background-color: #7d7f94;
      cursor: pointer;
    }
  }
`

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  width: 100%;
  align-items: center;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding-top: 20px;
  width: 100%;
  align-items: center;
  @media (max-width: 600px) {
    padding: 0 10px 0 10px;
  }
`

export const Banner = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 10px;

  display: flex;
  justify-content: center;
`

type CityPhotoProps = {
  src: string | undefined
}

export const CityPhoto = styled.div<CityPhotoProps>`
  height: 500px;
  background-position: center;
  background-size: cover;
  width: 100%;
  border-radius: 10px;

  ${({ src }) => {
    if (src) {
      css`
        background-image: url(src);
      `
    }

    return css`
      background: linear-gradient(
        110deg,
        #e8e8e8 20%,
        #fdfdfd 60%,
        #e8e8e8 80%
      );
      background-size: 300% 100%;
      animation: 3.5s shine linear infinite;

      @keyframes shine {
        to {
          background-position-x: -300%;
        }
      }
    `
  }}

  @media (max-width: 800px) {
    height: 350px;
  }
`

export const BannerWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: center;
`

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
`

export const WrapperInfos = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 15px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
  border-radius: 3px;
`

export const InfoContainer = styled.div`
  display: flex;
  width: 500px;
  padding: 10px 20px 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px 5px 5px;
  height: fit-content;
  background-color: white;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 90%;
    padding: 8px;
  }
`

export const Cambio = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  font-size: 12px;
`

export const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const CambioText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  align-items: center;
`
export const Text = styled.div`
  font-size: 14px;
  color: #454545;
  @media (max-width: 640px) {
    font-size: 12px;
  }
`

export const BoldText = styled.div`
  font-size: 14px;
  color: #454545;

  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 12px;
  }
`
export const Timezone = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  font-size: 12px;
`

export const Temperature = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  font-size: 12px;
`

export const CarrouselTitle = styled.div`
  font-size: 24px;
`

export const AncorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ButtonsAffiliate = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  @media (max-width: 670px) {
    width: 100%;
  }
`

export const Ancor = styled(Link)`
  font-size: 14px;
  cursor: pointer;
  color: #922b21;
  font-size: 14px;
  font-weight: bold;
`

export const Button = styled.button`
  height: 55px;
  width: 100%;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  background-color: #b22222;
  border-radius: 15px;

  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const CloseButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
    border-radius: 50%;
  }
`

export const FacebookButton = styled.button`
  width: 200px;
  height: 35px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #3a5a97;
  color: #ffffff;
`
export const Description = styled.div`
  font-size: 18px;
  margin-top: 10px;
  line-height: 35px;
  text-align: center;
  width: calc(100% - 2rem);
  padding: 0.5rem;
  height: 200px;
  overflow: auto;
  color: #4f4f4f;

  @media (max-width: 800px) {
    height: 180px;
    width: 100%;
    font-size: 16px;
  }

  &::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.75rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 32rem;
    background-color: #d3d3e3;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 32rem;
    background-color: #aeaec2;

    &:hover,
    &:active {
      background-color: #7d7f94;
      cursor: pointer;
    }
  }
`
export const Label = styled.label`
  font-size: 16px;
  color: #4f4f4f;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: bold;
`

export const Input = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid #dcdcdc;
  border-radius: 15px;
  outline-color: #b22222;
  padding-left: 10px;
  &::placeholder {
    font-size: 16px;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
`

export const AffiliateTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 670px) {
    font-size: 10px;
  }
`

export const Author = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #454545;
  margin-top: 10px;
  @media (max-width: 670px) {
    font-size: 12px;
  }
`

export const AffiliateDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60%;
  padding: 0;
  margin-bottom: 20px;

  @media (max-width: 670px) {
    flex-wrap: wrap;
    width: 100%;
    font-size: 12px;
  }
`

export const Tittle = styled.div`
  font-size: 30px;
  margin: 10px 0 10px 0;
  font-weight: 500;
  width: 500px;
  text-align: center;

  & span:first-child {
    color: #373737;
  }

  & span:last-child {
    color: #e90000;
  }

  @media (max-width: 670px) {
    width: 300px;

    & span:first-child {
      font-size: 20px;
    }

    & span:last-child {
      font-size: 20px;
    }
  }
`

export const ItineraryTittle = styled.div`
  color: #454545;
  font-size: 30px;
  font-weight: 500;

  margin: 10px 0 20px 0;
  @media (max-width: 670px) {
    font-size: 20px;
  }
`

export const AffiliateLink = styled.a`
  font-size: 20px;
  cursor: pointer;
  padding: 0;
`

export const Row = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-around;
  margin: 10px 0 10px 0;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 10px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  span {
    color: #e90000;
    font-size: 10px;
  }
`

export const SwiperDiv = styled(Swiper)`
  width: 100%;
  height: auto;
`

export const SwiperFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const RedirectButton = styled(Link)`
  width: fit-content;
  padding: 0.25rem 1rem;
  font-size: 16px;
  text-transform: uppercase;
  border: 1px solid #aba8a8;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
`

export const Slide = styled(SwiperSlide)`
  height: auto;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    height: 180px;
  }
`

export const Anchor = styled(Link)<CityPhotoProps>`
  text-decoration: none;
  cursor: pointer;
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 2px 9px 88px 1px rgba(150, 150, 150, 0.25);
  margin: 0;
  @media (max-width: 800px) {
    border-radius: 50%;
    height: 160px;
    width: 160px;
  }
`

export const ButtonIcon = styled.img`
  width: 70px;
  height: 70px;
  cursor: pointer;
  @media (max-width: 640px) {
    height: 40px;
    width: 40px;
  }
`
