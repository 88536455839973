import { Link as Anchor } from 'react-router-dom'

import styled from 'styled-components'

export const WrapperHeader = styled.header`
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  padding: 0 10%;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;

  background-size: cover;
  background-image: url('/images/background_global.jpg');

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 50px;
  }
`

export const Image = styled.img`
  height: 60px;
  width: auto;

  @media (max-width: 800px) {
    height: 40px;
    width: auto;
  }

  @media (max-width: 548px) {
    height: 30px;
  }
`

export const LinkIcons = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background-color: #454545;
`

export const LinkHeader = styled(Anchor)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  margin-top: 8px 0 8px 0;

  width: fit-content;
  cursor: pointer;

  color: #343434;
  font-size: 20px;

  &:hover {
    color: #e90000;
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }

  @media (max-width: 800px) {
    font-size: 14px;

    & span {
      display: none;
    }
  }
`

export const AuthDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: fit-content;

  @media (max-width: 800px) {
    width: auto;
  }
`

export const RegistryLink = styled.button`
  display: flex;
  align-items: center;

  margin-top: 8px 0 8px 0;
  font-size: 20px;
  width: fit-content;
  cursor: pointer;
  color: #e90000;
  background: unset;
  border: none;

  @media (max-width: 900px) {
    font-size: 16px;
  }

  @media (max-width: 548px) {
    font-size: 10px;
  }
`

export const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`

export const ButtonHeader = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;

  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  margin-top: 8px 0 8px 0;

  color: #343434;

  & span {
    color: #797979;
    font-size: 14px;
  }

  &:hover {
    color: #e90000;

    & span {
      color: #e90000;
    }
  }

  @media (max-width: 800px) {
    & span {
      display: none;
    }
  }
`

export const ModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  cursor: pointer;
  height: 50px;
  background-color: unset;
  border-radius: 5px;
  border: 2px solid #343434;
  color: #343434;
  padding: 6px 40px 6px 40px;
  height: fit-content;
  margin-left: 40px;

  @media (max-width: 920px) {
    font-size: 16px;

    margin-left: 10px;
    padding: 6px 20px 6px 20px;
  }

  @media (max-width: 548px) {
    font-size: 14px;
  }
`
