import { useHistory } from 'react-router-dom'

import MapPage from '../Map'
import {
  Banner,
  Container,
  RoadmapText,
  Section,
  BannerColumn,
  TittleWrapper,
  BannerButton,
  Tittle,
  BannerLeft,
  BannerRight,
  SecondTittle,
  SecondBanner,
  ImageBannerDiv
} from './style'

const Home = () => {
  const history = useHistory()

  const isMobile = window.innerWidth < 800
  return (
    <Container>
      <MapPage height={isMobile ? 350 : 500} />
      <Section>
        <Banner>
          <ImageBannerDiv>
            <BannerLeft
              src={
                isMobile
                  ? '/images/itinerary-mobile.jpeg'
                  : '/images/itinerary-banner.png'
              }
            />
          </ImageBannerDiv>

          <BannerColumn>
            <TittleWrapper>
              <Tittle>
                <span>Conheça nossos</span>
                <span>roteiros</span>{' '}
                <img
                  src="/images/plane-icon.png"
                  height={isMobile ? 30 : 40}
                  width={isMobile ? 35 : 50}
                  alt="icone avião"
                />
              </Tittle>
            </TittleWrapper>
            <RoadmapText>
              Aqui na RedSterna registramos experiências incríveis dos seus
              sonhos de viagens realizados ou planejados!
            </RoadmapText>
            <BannerButton onClick={() => history.push('/destinos')}>
              <span>Ver roteiros</span>
            </BannerButton>
          </BannerColumn>
        </Banner>
      </Section>
      <Section>
        <SecondBanner>
          <BannerColumn>
            <TittleWrapper>
              <Tittle>
                <span>Faça parte dessa</span>
                <span>
                  viagem
                  <img
                    src="/images/redsternagray-logo.png"
                    height={isMobile ? 25 : 40}
                    width={isMobile ? 30 : 50}
                    alt="ícone de avião"
                  />
                </span>
              </Tittle>
            </TittleWrapper>
            <RoadmapText>
              Faça o registro de suas viagens, compartilhe suas histórias e
              inspire pessoas!
            </RoadmapText>
            <BannerButton onClick={() => history.push('/cadastre-se')}>
              Cadastre-se
            </BannerButton>
          </BannerColumn>
          <ImageBannerDiv>
            <BannerRight
              src={
                isMobile
                  ? '/images/travel-mobile.jpeg'
                  : '/images/registry-banner.png'
              }
            />
          </ImageBannerDiv>
        </SecondBanner>
      </Section>
      <Section>
        <Banner>
          <ImageBannerDiv>
            <BannerLeft src="/images/traveltips-banner.png" />
          </ImageBannerDiv>

          <BannerColumn>
            <TittleWrapper>
              <SecondTittle>
                <span>Dicas de</span> <span> viagem</span>
              </SecondTittle>

              <img
                src="/images/check-icon.png"
                height={isMobile ? 25 : 50}
                width={isMobile ? 30 : 50}
                alt="ícone de caixa checadada"
              />
            </TittleWrapper>
            <RoadmapText>
              Aqui voce encontrará o que precisa para planejar sua viagem e
              ainda sair economizando
            </RoadmapText>
            <BannerButton onClick={() => history.push('/dicas-de-viagem')}>
              Ver Dicas
            </BannerButton>
          </BannerColumn>
        </Banner>
      </Section>
    </Container>
  )
}

export default Home
