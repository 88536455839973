import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Field, Formik, FormikErrors, FormikValues } from 'formik'

import { useAuth } from 'contexts/auth.context'
import { useUser } from 'contexts/user.context'

import * as S from './style'

const validate = (values: FormikValues) => {
  const errors: FormikErrors<FormikValues> = {}

  if (!values.email) {
    errors.email = 'Campo obrigatório'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'E-mail inválido'
  }

  if (!values.password) {
    errors.password = 'Insira sua senha'
  }

  if (!values.firstname) {
    errors.firstname = 'Este campo é obrigatório'
  }

  if (!values.lastname) {
    errors.lastname = 'Este campo é obrigatório'
  }

  if (!values.birthdate) {
    errors.birthdate = 'Este campo é obrigatório'
  }

  if (values.password && values.password.length < 6) {
    errors.password = 'Insira no mínimo 6 caracteres'
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Senhas não conferem'
  }

  if (!values.confirmPolicy) {
    errors.confirmPolicy = 'Campo obrigatório'
  }

  if (!values.gender || values.gender === '') {
    errors.gender = 'Campo obrigatório'
  }

  return errors
}

type RegisterFormProps = {
  step: number
  setStep: (step: number) => void
  selectedImage: string | null
  setSelectedImage: (value: string | null) => void
}

const RegisterForm = ({
  step,
  setStep,
  selectedImage,
  setSelectedImage
}: RegisterFormProps) => {
  const history = useHistory()

  const { signUp } = useAuth()

  const { uploadFile } = useUser()

  const [isLoading, setIsLoading] = useState(false)

  const uploadFileCallback = async (file: File) => {
    const response = await uploadFile(file)
    return response
  }

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [setStep, step])

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        country: '',
        birthdate: '',
        email: '',
        password: '',
        confirmPassword: '',
        interests: [],
        facebook: '',
        instagram: '',
        profilePicture: '',
        coverPicture: '',
        gender: 'M',
        about: '',
        confirmPolicy: []
      }}
      validate={validate}
      onSubmit={async (values) => {
        await signUp(values).then(() => {
          setIsLoading(true)

          try {
            toast.success('Conta criada com sucesso!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'dark'
            })
            setTimeout(() => history.push('/?isLogin=true'), 3500)
          } catch (e) {
            toast.error(
              'Não foi possível criar sua conta, tente novamente mais tarde',
              {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'dark'
              }
            )
          }
        })
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          {step === 2 && (
            <S.FormContainer>
              <S.SecondFormTitle>
                <span>Preencha as</span> <span>informações</span>
              </S.SecondFormTitle>

              <S.InputWrapper>
                <S.Label>
                  Nome <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <Field
                  as={S.Input}
                  name="firstname"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                />
                {errors.firstname && <S.Errors>{errors.firstname}</S.Errors>}
              </S.InputWrapper>

              <S.InputWrapper>
                <S.Label>
                  Sobrenome <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <Field
                  as={S.Input}
                  name="lastname"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                />
                {errors.lastname && <S.Errors>{errors.lastname}</S.Errors>}
              </S.InputWrapper>

              <S.InputWrapper>
                <S.Label>
                  Senha <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <S.Info>
                  Sua senha deve conter, pelo menos, uma letra maiúscula, um
                  número e um caractere especial (por exemplo: ! # $ % &).
                </S.Info>
                <Field
                  as={S.Input}
                  type="password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                />
                {errors.password && <S.Errors>{errors.password}</S.Errors>}
              </S.InputWrapper>

              <S.InputWrapper>
                <S.Label>
                  Repetir Senha <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <Field
                  as={S.Input}
                  type="password"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                />
                {errors.confirmPassword && (
                  <S.Errors>{errors.confirmPassword}</S.Errors>
                )}
              </S.InputWrapper>

              <S.InputDateWrapper>
                <S.Label>
                  Data de Nascimento <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <Field
                  as={S.InputDate}
                  name="birthdate"
                  type="date"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.birthdate}
                />
                {errors.birthdate && <S.Errors>{errors.birthdate}</S.Errors>}
              </S.InputDateWrapper>

              <S.InputDateWrapper>
                <S.Label>
                  Gênero <S.Attention>*</S.Attention>
                </S.Label>
                <Field
                  value={values.gender}
                  name="gender"
                  as={S.Select}
                  onChange={handleChange}
                  placeholder="Selecione uma opção"
                >
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                  <option value="NB">Não binário</option>
                  <option value="AN">Outro</option>
                </Field>
                {errors.gender && <S.Errors>{errors.gender}</S.Errors>}
              </S.InputDateWrapper>

              <S.InputWrapper>
                <S.Label>
                  E-mail <S.Attention>*</S.Attention>{' '}
                </S.Label>
                <Field
                  as={S.Input}
                  name="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && <S.Errors>{errors.email}</S.Errors>}
              </S.InputWrapper>

              <S.InputWrapper>
                <S.Label>Sobre você</S.Label>
                <Field
                  name="about"
                  as={S.TextArea}
                  onBlur={handleBlur}
                  value={values.about}
                  onChange={handleChange}
                  placeholder="Queremos saber mais! Qual seu perfil de viajante? Qual foi sua melhor viagem? Do que você mais gosta?"
                />
              </S.InputWrapper>

              <S.Label>Selecione as suas opções de interesse:</S.Label>

              <S.GridContainer>
                <S.GridItem>
                  <Field
                    id="cidade_historica"
                    name="interests"
                    value="cidade_historica"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="cidade_historica">
                    Cidade Histórica
                  </S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="montanha"
                    name="interests"
                    value="montanha"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="montanha">Montanha</S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="camping"
                    name="interests"
                    value="camping"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="camping">Camping</S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="cidade_moderna"
                    name="interests"
                    value="cidade_moderna"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="cidade_moderna">
                    Cidade Moderna
                  </S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="praia"
                    name="interests"
                    value="praia"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="praia"> Praia </S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field name="interests" value="cachoeira" type="checkbox" />
                  <S.GridLabel>Cachoeira</S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="campo"
                    name="interests"
                    value="campo"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="campo">Campo</S.GridLabel>
                </S.GridItem>
                <S.GridItem>
                  <Field
                    id="trekking"
                    name="interests"
                    value="trekking"
                    type="checkbox"
                  />
                  <S.GridLabel htmlFor="trekking">Trekking</S.GridLabel>
                </S.GridItem>
              </S.GridContainer>

              <S.SocialWrapper>
                <S.InputWrapper>
                  <S.Label>Instagram</S.Label>
                  <Field
                    as={S.InputDate}
                    type="text"
                    name="instagram"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.instagram}
                    placeholder="redsterna_ig"
                  />
                </S.InputWrapper>
                <S.InputWrapper>
                  <S.Label>Facebook</S.Label>
                  <Field
                    as={S.InputDate}
                    type="text"
                    name="facebook"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.facebook}
                    placeholder="redsterna_fb"
                  />
                </S.InputWrapper>
              </S.SocialWrapper>

              <S.NextButton onClick={nextStep}>Continuar</S.NextButton>
            </S.FormContainer>
          )}

          {/* <div>
            <S.Label>País</S.Label>
            <Field
              as={S.Input}
              name="country"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
            />
          </div> */}

          {step === 3 && (
            <S.FormContainer>
              <S.ThirdFormTitle>
                <span> Só falta mais um pouco para finalizar seu</span>
                <span>cadastro!</span>
              </S.ThirdFormTitle>
              <S.ThirdLabel>ADICIONE UMA FOTO DE PERFIL</S.ThirdLabel>
              <input
                type="file"
                id="uploadProfile"
                style={{ display: 'none' }}
                onChange={async (e: any) =>
                  await uploadFileCallback(e.target.files[0]).then(
                    (response) => {
                      setFieldValue('profilePicture', response)
                      setSelectedImage(response)
                    }
                  )
                }
              />
              <label htmlFor="uploadProfile">
                <S.ImageCircle>
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Profile"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Garante que a imagem cubra completamente o espaço
                        borderRadius: '50%' // Transforma a imagem em um círculo
                      }}
                    />
                  ) : (
                    <S.ProfileUploadLogo src="/images/profile-upload-logo.svg" />
                  )}
                </S.ImageCircle>
              </label>

              {values.profilePicture && values.profilePicture !== '' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginTop: 10
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => setFieldValue('coverPicture', '')}
                    >
                      X
                    </button>
                    <img
                      src={values.profilePicture}
                      width={200}
                      height={200}
                      alt="coverPicture"
                    />
                  </div>
                </div>
              )}
              <S.ThirdLabel>ADICIONE UMA FOTO DE CAPA</S.ThirdLabel>
              <input
                type="file"
                id="uploadCover"
                style={{ display: 'none' }}
                onChange={async (e: any) =>
                  await uploadFileCallback(e.target.files[0]).then(
                    (response) => {
                      setFieldValue('coverPicture', response)
                      // setSelectedImageCover(response);
                    }
                  )
                }
              />

              <S.LabelUpload htmlFor="uploadCover">
                <S.CoverDiv>
                  <S.ProfileUploadLogo src="/images/cover-upload-logo.svg" />
                </S.CoverDiv>
              </S.LabelUpload>
              {values.coverPicture && values.coverPicture !== '' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                      marginTop: 10
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => setFieldValue('coverPicture', '')}
                    >
                      X
                    </button>
                    <img
                      src={values.coverPicture}
                      width={200}
                      height={200}
                      alt="coverPicture"
                    />
                  </div>
                </div>
              )}

              <S.AgreeWrapper>
                <S.Checkbox
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="confirmPolicy"
                  value="confirmed"
                />
                <S.Label>
                  <S.Anchor href="politicas-privacidade">
                    Ao cadastrar-se, você concorda com a Política de Privacidade
                    e os Termos de Uso da plataforma.
                  </S.Anchor>
                </S.Label>
                {errors.confirmPolicy && <span>{errors.confirmPolicy}</span>}
              </S.AgreeWrapper>

              <S.Button type="submit" disabled={isLoading}>
                {isLoading ? 'Carregando...' : 'Finalizar Cadastro'}
              </S.Button>
            </S.FormContainer>
          )}
        </form>
      )}
    </Formik>
  )
}

export default RegisterForm
