import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Formik, Field } from 'formik'

import api from 'services/api'

import Header from 'components/header'

import * as S from './style'

const RecoveryPassword = () => {
  const validate = (values: { email: string }) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = 'Campo obrigatório'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'E-mail inválido'
    }
    return errors
  }
  const history = useHistory()

  return (
    <S.Wrapper>
      <Header />
      <S.Container>
        <S.TitleContainer>
          <S.Title>Recuperar conta</S.Title>
          <S.Description>
            {' '}
            Adicione o seu e-mail cadastrado e confirme seus dados.
          </S.Description>
        </S.TitleContainer>

        <Formik
          initialValues={{
            email: ''
          }}
          validate={validate}
          onSubmit={async (values) => {
            const response = await api.post('/users/recover-password', {
              email: values.email
            })

            if (response.status === 201) {
              toast.success('E-mail de recuperação enviado', { theme: 'dark' })
              setTimeout(() => history.push('/?isLogin=true'), 3500)
              return
            }

            toast.error('Não foi possível enviar o email de recuperação', {
              theme: 'dark'
            })
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit}>
              <S.FormContainer>
                <S.Label>E-mail</S.Label>
                <Field
                  name="email"
                  placeholder="nome@email.com"
                  as={S.Input}
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && <span>{errors.email}</span>}
                <S.Button
                  type="submit"
                  className={isSubmitting ? 'loading' : ''}
                >
                  Enviar
                </S.Button>
              </S.FormContainer>
            </form>
          )}
        </Formik>
      </S.Container>
    </S.Wrapper>
  )
}

export default RecoveryPassword
