import styled from 'styled-components'

const PageContainer = styled.main`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #ffff;

  padding: 70px 0 0 0;

  @media (max-width: 768px) {
    padding: 50px 0 0 0;
  }
`

export default PageContainer
