import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Field, FieldArray, Formik } from 'formik'

import api from 'services/api'

import { useItineraries } from 'contexts/itinerary.context'

import CityForm from 'components/cities'

import { CoverImage } from '../Create/style'
import * as S from './style'

const Itinerary = (props: any) => {
  const { updateItinerary, uploadFile } = useItineraries()

  const [itinerary, setItinerary] = useState<any>(null)

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    ;(async () => {
      const response = await api.get(`/itineraries/id/${id}`)
      const _itinerary = response.data.itinerary
      setItinerary({
        id: _itinerary.id,
        made: _itinerary.made,
        title: _itinerary.title,
        tripDate: _itinerary.tripDate.split('T')[0],
        coverImage: _itinerary.coverImage,
        summary: _itinerary.summary,
        days: _itinerary.days,
        spent: _itinerary.spent,
        type: _itinerary.type,
        interests: _itinerary.interests.map(
          ({ interest }: { interest: { name: string } }) => interest.name
        ),
        cities: _itinerary.cities
      })
    })()
  }, [id])

  const uploadFileCallback = async (file: any) => {
    const response = await uploadFile(file)

    return response
  }
  const history = useHistory()
  if (!itinerary) return <div>Carregando</div>
  return (
    <S.Container>
      <S.FormikContainer>
        <S.Cover>
          <S.CoverIcon src="/images/desktop/edit_itinerary.png" />
        </S.Cover>
        <Formik
          initialValues={itinerary}
          onSubmit={async (values: any) => {
            await updateItinerary(values)

            setTimeout(() => {
              history.push('/minha-conta')
            }, 2000)
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }: any) => (
            <S.Form onSubmit={handleSubmit}>
              <S.FormContainer>
                <S.Label>Tipo de roteiro</S.Label>
                <S.RowContainer>
                  <Field
                    name="made"
                    render={({ field }: { field: any }) => (
                      <S.RowContainer>
                        <S.RowContainer>
                          <S.FieldInput
                            {...field}
                            as={S.Checkbox}
                            name="made"
                            value={true}
                            type="radio"
                            checked={field.value.toString() === 'true'}
                          />
                          <S.ColumnContainer>
                            <S.RowLabel>
                              <S.Label>Feito</S.Label>
                              <S.FollowIcon
                                src="/images/desktop/itinerary/check.png"
                                height={15}
                                width={15}
                              />
                            </S.RowLabel>
                            <S.Description>
                              Essa viagem já foi feita
                            </S.Description>
                          </S.ColumnContainer>
                        </S.RowContainer>
                        <S.RowContainer>
                          <Field
                            {...field}
                            as={S.Checkbox}
                            name="made"
                            value={false}
                            checked={field.value.toString() === 'false'}
                            type="radio"
                          />
                          <S.ColumnContainer>
                            <S.RowLabel>
                              <S.Label>Planejado</S.Label>
                              <S.FollowIcon
                                src="/images/desktop/itinerary/send.png"
                                height={20}
                                width={20}
                              />
                            </S.RowLabel>
                            <S.Description>
                              Essa viagem não foi feita
                            </S.Description>
                          </S.ColumnContainer>
                        </S.RowContainer>
                      </S.RowContainer>
                    )}
                  />
                </S.RowContainer>
                <S.RowContainer>
                  <S.SubContainer>
                    <S.Label>Título da viagem</S.Label>
                    <Field
                      as={S.Input}
                      name="title"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="ORLANDO/DISNEY - MIAMI - KEY WEST E NOVA YORK"
                      value={values.title}
                    />
                    {errors.title && <span>{errors.title}</span>}
                  </S.SubContainer>
                </S.RowContainer>
                <S.SubContainer>
                  <S.Label>Data da Viagem</S.Label>
                  <Field
                    as={S.MonthInput}
                    name="tripDate"
                    type="date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tripDate}
                  />
                </S.SubContainer>

                <S.Label>
                  Quais dessas opções melhor descrevem essa viagem?
                </S.Label>
                <S.Description>Selecione: </S.Description>
                <S.CheckboxWrapper>
                  <S.ContainerColumn>
                    <S.CheckboxContainer>
                      <Field
                        id="cidade_historica"
                        as={S.Checkbox}
                        name="interests"
                        value="cidade_historica"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="cidade_historica">
                        Cidade Histórica
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="praia"
                        name="interests"
                        value="praia"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="praia">Praia</S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="montanha"
                        name="interests"
                        value="montanha"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="montanha">
                        Montanha
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="camping"
                        name="interests"
                        value="camping"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="camping">
                        Camping
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>
                  </S.ContainerColumn>

                  <S.ContainerColumn>
                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="cidade_moderna"
                        name="interests"
                        value="cidade_moderna"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="cidade_moderna">
                        Cidade Moderna
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="campo"
                        name="interests"
                        value="campo"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="campo">Campo</S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="cachoeira"
                        name="interests"
                        value="cachoeira"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="cachoeira">
                        Cachoeira
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>

                    <S.CheckboxContainer>
                      <Field
                        as={S.Checkbox}
                        id="trekking"
                        name="interests"
                        value="trekking"
                        type="checkbox"
                      />
                      <S.CheckboxLabel htmlFor="trekking">
                        Trekking
                      </S.CheckboxLabel>
                    </S.CheckboxContainer>
                  </S.ContainerColumn>
                </S.CheckboxWrapper>

                <S.Label>Foto de capa</S.Label>
                <S.Description>
                  Escolha a foto que melhor ilustra sua viagem, ela será a capa
                  do seu roteiro.
                </S.Description>
                <S.FileInput
                  accept="image/*,.heic"
                  type="file"
                  onChange={async (e: any) =>
                    await uploadFileCallback(e.target.files[0]).then(
                      (response) => setFieldValue('coverImage', response)
                    )
                  }
                />
                {values.coverImage && values.coverImage !== '' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginTop: 10
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => setFieldValue('coverImage', '')}
                      >
                        X
                      </button>
                      <CoverImage src={values.coverImage} alt="cover" />
                    </div>
                  </div>
                )}
                <S.RowContainer>
                  <S.ContainerColumn>
                    <S.Label>Dias de viagem</S.Label>
                    <Field
                      as={S.MonthInput}
                      name="days"
                      type="number"
                      min="1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="1"
                    />
                  </S.ContainerColumn>

                  <S.ContainerColumn>
                    <S.Label>Custo total por pessoa em BRL (R$)</S.Label>
                    <Field
                      as={S.MonthInput}
                      name="spent"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      placeholder="R$ 0.0"
                      step="0.01"
                      min="0"
                      value={values.spent}
                    />
                  </S.ContainerColumn>
                </S.RowContainer>

                <S.Label>Resumo da viagem</S.Label>
                <S.TextArea
                  name="summary"
                  value={values.summary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Quais cidades você visitou? O que viu de bacana? Quais os pontos fortes e fracos? Divida suas experiências com a gente!"
                />

                <S.Label>Cidades visitadas</S.Label>
                <S.Description>
                  Insira abaixo as cidades que visitou, onde ficou e qual sua
                  avaliação do local.
                </S.Description>
                <FieldArray
                  name="cities"
                  render={(arrayHelpers) => {
                    return values.cities.length > 0 ? (
                      values.cities.map((city: any, index: any) => (
                        <React.Fragment key={city.latitude}>
                          <button
                            style={{ width: 200, height: 30, marginTop: 20 }}
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remover cidade abaixo:
                          </button>
                          <CityForm
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            city={city}
                            index={index}
                            key={index}
                            arrayHelpers={arrayHelpers}
                            setFieldValue={setFieldValue}
                          />
                          {values.cities.length === index + 1 && (
                            <button
                              style={{ width: 200, height: 30 }}
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  name: '',
                                  description: '',
                                  coordinates: {
                                    latitude: 0,
                                    longitude: 0
                                  },
                                  rate: 0,
                                  accommodations: [
                                    {
                                      name: '',
                                      rate: 0
                                    }
                                  ],
                                  images: [],
                                  order: index + 1
                                })
                              }
                            >
                              Adicionar mais cidades
                            </button>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <button
                        style={{ width: 200, height: 30 }}
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({
                            name: '',
                            description: '',
                            coordinates: {
                              latitude: 0,
                              longitude: 0
                            },
                            rate: 0,
                            accommodations: [
                              {
                                name: '',
                                rate: 0
                              }
                            ],
                            images: [],
                            order: 0
                          })
                        }
                      >
                        Adicionar uma cidade
                      </button>
                    )
                  }}
                />
                <S.Button type="submit">Salvar Roteiro</S.Button>
              </S.FormContainer>
            </S.Form>
          )}
        </Formik>
      </S.FormikContainer>
    </S.Container>
  )
}

export default Itinerary
