import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import api from 'services/api'

import { useUser } from 'contexts/user.context'

import { ModelInterestsPhotos } from 'models/ModelInterestsPhotos'
import { ModelItineraries } from 'models/ModelItineraries'

import DeleteItinerary from 'components/deleteItineraryModal'
import RankingIcon from 'components/rankingIcon'
import StarRating from 'components/starRating'

import { AddIcon } from './assets'
import * as S from './style'

const DEFAULT_TEXT =
  'Viajante RedSterna que busca viver e compartilhar experiências incríveis ao redor do mundo! Siga-me para acompanhar minha jornada.'

const interestsPhotos: ModelInterestsPhotos = {
  cidade_moderna: '/images/smart-city.png',
  cidade_historica: '/images/british-museum.png',
  cachoeira: '/images/waterfall.png',
  camping: '/images/tent.png',
  praia: '/images/summer-holidays.png',
  campo: '/images/campo.png',
  trekking: '/images/hiking.png',
  montanha: '/images/mountain.png'
}

const Profile = () => {
  const { user, getUserData } = useUser()
  const history = useHistory()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [itinerary, setItinerary] = useState({})
  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    getUserData()
  }, [getUserData])

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const { data: itineraries } = useQuery({
    queryKey: ['itinerariesUser', user.id],
    queryFn: async () => {
      const response = await api.get(`/itineraries/user/${user.id}`)
      return response.data
    },
    enabled: !!user.id
  })

  const { data: following } = useQuery({
    queryKey: ['followingUser', user.id],
    queryFn: async () => {
      const response = await api.get(`/user-follower/following/${user.id}`)
      return response.data
    },
    enabled: !!user.id
  })

  const { data: followers } = useQuery({
    queryKey: ['followersUser', user.id],
    queryFn: async () => {
      const response = await api.get(`/user-follower/followers/${user.id}`)
      return response.data
    },
    enabled: !!user.id
  })

  const { data: favoriteItineraries } = useQuery({
    queryKey: ['followingUser', user.id],
    queryFn: async () => {
      const response = await api.get(`/itineraries/user/favorite/${user.id}`)
      return response.data
    },
    enabled: !!user.id
  })

  if (!user) {
    return <></>
  }

  return (
    <S.Container>
      <DeleteItinerary
        itinerary={itinerary}
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />

      <S.Header>
        <S.HeaderTab>
          <S.LargeText>Viajante Redsterna</S.LargeText>
        </S.HeaderTab>
        <S.CoverWrapper>
          <S.SearchButton onClick={() => history.push('/buscar-usuarios')}>
            <S.SearchText>
              Procurar usuário
              <S.SearchIcon src="/images/user-search.svg" />
            </S.SearchText>
          </S.SearchButton>
          <S.CoverIcon src={user?.coverPicture ?? '/images/cover-empty.png'} />
        </S.CoverWrapper>

        <S.Mobile>
          <S.DescriptionWrapper>
            <S.RowMobile>
              <S.InfoWrapper>
                <S.Flex>
                  <S.ProfileImageContainer>
                    <S.ProfileIcon
                      src={user?.profilePicture ?? '/images/profile-empty.png'}
                      alt="Foto de perfil"
                    />
                  </S.ProfileImageContainer>
                  <S.Title>
                    Boas vindas,{' '}
                    <S.UserName>
                      {user.firstname} {user.lastname}!
                    </S.UserName>
                    <S.FollowWrapper>
                      {followers && (
                        <S.FollowTag>{followers.length} SEGUIDORES</S.FollowTag>
                      )}
                      {following && (
                        <S.FollowTag>{following.length} SEGUINDO</S.FollowTag>
                      )}
                    </S.FollowWrapper>
                  </S.Title>

                  <S.PostWrapper>
                    <S.PostsTag>{itineraries?.length} ROTEIROS</S.PostsTag>
                    <S.PostsTag> 0 DESTINOS</S.PostsTag>
                  </S.PostWrapper>
                </S.Flex>
              </S.InfoWrapper>
              <S.AdditionalInfos>
                <S.AddDiv>
                  <S.CollapseTag>
                    <S.Collapse>
                      sobre mim{' '}
                      <S.CollapseButton onClick={toggleCollapse}>
                        {isCollapsed ? (
                          <img
                            src="/images/down-arrow.svg"
                            alt="Seta direcionando para abrir para baixo"
                            height={7}
                            width={15}
                          />
                        ) : (
                          <img
                            src="/images/up-arrow.svg"
                            alt="Seta direcionando para abrir para baixo"
                            height={7}
                            width={15}
                          />
                        )}
                      </S.CollapseButton>
                      {!isCollapsed && (
                        <div className="collapse-content">
                          {user.about && user?.about?.length > 0
                            ? user.about
                            : DEFAULT_TEXT}
                        </div>
                      )}
                    </S.Collapse>
                  </S.CollapseTag>
                </S.AddDiv>

                <S.AddDiv>
                  <S.AdditionalTag>
                    Perfil
                    <RankingIcon itinerariesLength={itineraries?.length} />
                  </S.AdditionalTag>
                </S.AddDiv>
                <S.AddDiv>
                  {' '}
                  <S.InterestTag>
                    <S.InterestsIconsWrapper>
                      Interesses
                      {user.interests?.map(({ interest }) => {
                        return (
                          <S.InterestIconContainer key={interest.id}>
                            <S.InterestIcon
                              src={interestsPhotos[interest.name]}
                            />
                          </S.InterestIconContainer>
                        )
                      })}
                    </S.InterestsIconsWrapper>
                  </S.InterestTag>
                  <S.SocialTag>
                    {user?.instagram && user?.instagram !== '' && (
                      <S.SocialMediaButton
                        href={`https://www.instagram.com/${user.instagram}`}
                        target="_blank"
                      >
                        <S.SocialIcon
                          src="/images/desktop/profile/insta_icon.png"
                          height={47}
                          width={53}
                          alt="Símbolo do instagram"
                        />
                      </S.SocialMediaButton>
                    )}
                    {user?.facebook && user?.facebook !== '' && (
                      <S.SocialMediaButton
                        href={`https://www.facebook.com/${user.facebook}`}
                        target="_blank"
                      >
                        <S.SocialIcon
                          src="/images/desktop/profile/facebook_icon.png"
                          height={47}
                          width={53}
                          alt="Símbolo do Facebook"
                        />
                      </S.SocialMediaButton>
                    )}
                  </S.SocialTag>
                </S.AddDiv>
              </S.AdditionalInfos>
            </S.RowMobile>
          </S.DescriptionWrapper>
        </S.Mobile>
      </S.Header>

      <S.ItineraryButtonProfile to="criar-roteiro">
        <AddIcon />
        <p>Criar novo roteiro</p>
      </S.ItineraryButtonProfile>

      <S.ItineraryContainer>
        <S.HeadingItinerary>
          <S.TitleContainer>
            <S.ItineraryTittle>Roteiros publicados</S.ItineraryTittle>
          </S.TitleContainer>
        </S.HeadingItinerary>
        {itineraries?.length > 0 ? (
          itineraries.map((itinerary: any) => (
            <S.PublishContainer key={itinerary.id}>
              <S.PublishCentralized>
                <S.Publisher>
                  <S.ItineraryImageContainer>
                    <S.ItineraryImage
                      src={
                        itinerary.coverImage ||
                        '/images/desktop/home/redsterna_gray_logo.png'
                      }
                      alt={itinerary.title}
                    />
                  </S.ItineraryImageContainer>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      marginLeft: 20
                    }}
                  >
                    <S.LinkTittle
                      to={{
                        pathname: `/roteiro/${itinerary.id}`,
                        state: { itinerary }
                      }}
                    >
                      {itinerary.title}
                    </S.LinkTittle>
                  </div>
                </S.Publisher>
                {/*<S.Rating>
                  {itinerary.rates.length >= 1 ? (
                    <StarRating
                      value={Number(
                        itinerary.rates.reduce(
                          (a: number, b: { rate: number }) => a + b.rate,
                          0
                        ) / itinerary.rates.length
                      )}
                      hideStar
                    />
                  ) : (
                    <S.UnRated style={{ fontSize: 11 }}>
                      Ainda não há avaliações
                    </S.UnRated>
                  )}

                  <S.Configurations>
                    <Link
                      to={{
                        pathname: `editar-roteiro/${itinerary.id}`,
                        search: `?id=${itinerary.id}`,
                        state: { itinerary }
                      }}
                    >
                      <AiFillEdit
                        size={20}
                        color="red"
                        style={{ cursor: 'pointer' }}
                      />
                    </Link>
                    <AiFillDelete
                      size={20}
                      color="red"
                      onClick={() => {
                        setItinerary(itinerary)
                        setIsModalVisible(true)
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </S.Configurations>
                </S.Rating>*/}
              </S.PublishCentralized>
            </S.PublishContainer>
          ))
        ) : (
          <S.SmallText>Nenhum roteiro publicado</S.SmallText>
        )}
      </S.ItineraryContainer>
      <S.ItineraryContainer>
        <S.ItineraryWrapper>
          <S.HeadingItinerary>
            <S.TitleContainer>
              <S.ItineraryTittle>Roteiros favoritos</S.ItineraryTittle>
            </S.TitleContainer>
          </S.HeadingItinerary>
          {favoriteItineraries?.length > 0 ? (
            favoriteItineraries.map((itinerary: ModelItineraries) => (
              <S.PublishContainer key={itinerary.id}>
                <S.PublishCentralized>
                  <S.Publisher>
                    <S.ItineraryImageContainer>
                      <S.ItineraryImage
                        src={
                          itinerary.coverImage ??
                          '/images/desktop/home/redsterna_gray_logo.png'
                        }
                        alt={itinerary.title}
                      />
                    </S.ItineraryImageContainer>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        marginLeft: 20
                      }}
                    >
                      <S.LinkTittle
                        to={{
                          pathname: `/roteiro/${itinerary.id}`,
                          state: { itinerary }
                        }}
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start'
                        }}
                      >
                        {itinerary.title}
                      </S.LinkTittle>
                    </div>
                  </S.Publisher>
                  <S.Rating>
                    {itinerary.rates && itinerary.rates.length >= 1 ? (
                      <StarRating
                        value={Number(
                          itinerary.rates.reduce(
                            (a: number, b: { rate: number }) => a + b.rate,
                            0
                          ) / itinerary.rates.length
                        )}
                        hideStar
                      />
                    ) : (
                      <p style={{ fontSize: 11 }}>Ainda não há avaliações</p>
                    )}
                  </S.Rating>
                </S.PublishCentralized>
              </S.PublishContainer>
            ))
          ) : (
            <S.SmallText>Nenhum roteiro favoritado</S.SmallText>
          )}
        </S.ItineraryWrapper>
      </S.ItineraryContainer>
    </S.Container>
  )
}

export default Profile
