import NotFound from 'components/NotFound'

import * as S from './style'

const ErrorPage = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <NotFound />
      </S.Container>
    </S.Wrapper>
  )
}

export default ErrorPage
