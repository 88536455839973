import * as S from './style'

const StarRating = (props: any) => {
  const { value, onClick, hideStar } = props

  const starQuantity = hideStar ? value : 5
  return (
    <S.Wrapper>
      {[...Array(Number(starQuantity.toFixed()))].map(
        (star: any, i: number) => {
          return (
            <S.Star
              key={i}
              size={20}
              color={Number(value) >= i + 1 ? '#ffc107' : '#bfbfbf'}
              onClick={() => onClick(i + 1)}
            />
          )
        }
      )}
    </S.Wrapper>
  )
}

export default StarRating
