import { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useQuery } from '@tanstack/react-query'

import api from 'services/api'

import { IUser } from 'contexts/user.context'

import DeleteItinerary from 'components/deleteItineraryModal'
import RankingIcon from 'components/rankingIcon'
import StarRating from 'components/starRating'

import {
  AddDiv,
  AdditionalInfos,
  AdditionalTag,
  Collapse,
  CollapseButton,
  CollapseTag,
  Container,
  CoverIcon,
  CoverWrapper,
  DescriptionWrapper,
  Flex,
  FollowTag,
  FollowWrapper,
  Header,
  HeaderTab,
  HeadingItinerary,
  InfoWrapper,
  InterestIcon,
  InterestIconContainer,
  InterestTag,
  InterestsIconsWrapper,
  ItineraryContainer,
  ItineraryImage,
  ItineraryImageContainer,
  ItineraryTittle,
  ItineraryWrapper,
  LargeText,
  LinkTittle,
  Mobile,
  PostWrapper,
  PostsTag,
  ProfileIcon,
  ProfileImageContainer,
  PublishCentralized,
  PublishContainer,
  Publisher,
  Rating,
  RowMobile,
  SearchButton,
  SearchIcon,
  SearchText,
  SmallText,
  SocialIcon,
  SocialTag,
  Title,
  TitleContainer,
  UserName
} from '../Profile/style'
import * as S from './style'

const DEFAULT_TEXT =
  'Viajante RedSterna que busca viver e compartilhar experiências incríveis ao redor do mundo! Siga-me para acompanhar minha jornada.'

type InterestsPhotos = {
  [key: string]: string
}

const interestsPhotos: InterestsPhotos = {
  cidade_moderna: '/images/smart-city.png',
  cidade_historica: '/images/british-museum.png',
  cachoeira: '/images/waterfall.png',
  camping: '/images/tent.png',
  praia: '/images/summer-holidays.png',
  campo: '/images/campo.png',
  trekking: '/images/hiking.png',
  montanha: '/images/mountain.png'
}

const User = () => {
  const history = useHistory()

  const [fetch, setFetch] = useState(true)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true)

  const { id } = useParams<{ id: string }>()

  const { data: userInfo, isLoading: isLoadingUserInfo } = useQuery({
    queryKey: ['userData', id],
    queryFn: async () => {
      const { data }: { data: IUser } = await api.get(
        `/users/${id}/itineraries`
      )
      setFetch(true)
      return data
    },
    enabled: !!id && fetch,
    staleTime: 15 * 60 * 1000
  })

  const { data: following, isLoading: isLoadingFollowing } = useQuery({
    queryKey: ['userFollowing', id],
    queryFn: async () => {
      const { data } = await api.get(`/user-follower/following/${id}`)
      return data
    },
    enabled: !!id,
    staleTime: 15 * 60 * 1000
  })

  const {
    data: followers,
    isLoading: isLoadingFollowers,
    refetch: refetchFollowers
  } = useQuery({
    queryKey: ['userFollowers', id],
    queryFn: async () => {
      const { data } = await api.get(`/user-follower/followers/${id}`)
      return data
    },
    enabled: !!id,
    staleTime: 15 * 60 * 1000
  })

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed)
  }, [isCollapsed])

  const postFollowers = useCallback(
    async (user: string): Promise<void> => {
      try {
        await api.post('/users/follow', { user })
        await refetchFollowers()

        toast.success('Seguindo com sucesso!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      } catch (e) {
        toast.error('Você já está seguindo este usuário!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      }
    },
    [refetchFollowers]
  )

  if (isLoadingUserInfo || !userInfo) {
    return <h1>Carregando</h1>
  }

  return (
    <Container>
      <DeleteItinerary
        itinerary={userInfo.itineraries}
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />

      <Header>
        <HeaderTab>
          <LargeText> Viajante Redsterna</LargeText>
        </HeaderTab>

        <CoverWrapper>
          <SearchButton onClick={() => history.push('/buscar-usuarios')}>
            <SearchText>
              Procurar usuário
              <SearchIcon src="/images/user-search.svg" />
            </SearchText>
          </SearchButton>
          <CoverIcon
            src={userInfo?.coverPicture ?? '/images/cover-empty.png'}
          />
        </CoverWrapper>

        <Mobile>
          <DescriptionWrapper>
            <RowMobile>
              <InfoWrapper>
                <Flex>
                  <ProfileImageContainer>
                    <ProfileIcon
                      src={
                        userInfo?.profilePicture ?? '/images/profile-empty.png'
                      }
                      alt="Foto de perfil"
                    />
                  </ProfileImageContainer>
                  <Title>
                    Boas vindas,{' '}
                    <UserName>
                      {userInfo.firstname} {userInfo.lastname}!
                    </UserName>
                    <FollowWrapper>
                      <S.FollowButton
                        onClick={() => postFollowers(userInfo.id)}
                      >
                        Seguir
                      </S.FollowButton>
                      <FollowTag>
                        {isLoadingFollowers ? '?' : followers.length} SEGUIDORES
                      </FollowTag>
                      <FollowTag>
                        {isLoadingFollowing ? '?' : following.length} SEGUINDO
                      </FollowTag>
                    </FollowWrapper>
                  </Title>

                  {userInfo.itineraries ? (
                    <PostWrapper>
                      <PostsTag>
                        {userInfo.itineraries.length} ROTEIROS
                      </PostsTag>
                      <PostsTag>0 DESTINOS</PostsTag>
                    </PostWrapper>
                  ) : (
                    <></>
                  )}
                </Flex>
              </InfoWrapper>
              <AdditionalInfos>
                <AddDiv>
                  <CollapseTag>
                    <Collapse>
                      sobre mim
                      <CollapseButton onClick={toggleCollapse}>
                        {isCollapsed ? (
                          <img
                            src="/images/down-arrow.svg"
                            alt="Seta direcionando para abrir para baixo"
                            height={7}
                            width={15}
                          />
                        ) : (
                          <img
                            src="/images/up-arrow.svg"
                            alt="Seta direcionando para abrir para baixo"
                            height={7}
                            width={15}
                          />
                        )}
                      </CollapseButton>
                      {!isCollapsed && (
                        <div className="collapse-content">
                          {userInfo.about && userInfo?.about?.length > 0
                            ? userInfo.about
                            : DEFAULT_TEXT}
                        </div>
                      )}
                    </Collapse>
                  </CollapseTag>
                </AddDiv>

                {userInfo.itineraries ? (
                  <AddDiv>
                    <AdditionalTag>
                      Perfil
                      <RankingIcon
                        itinerariesLength={userInfo.itineraries?.length}
                      />
                    </AdditionalTag>
                  </AddDiv>
                ) : (
                  <></>
                )}

                <AddDiv>
                  <InterestTag>
                    <InterestsIconsWrapper>
                      Interesses
                      {userInfo.interests?.map((interest: any) => {
                        return (
                          <InterestIconContainer key={interest.id}>
                            <InterestIcon
                              src={interestsPhotos[interest.name]}
                            />
                          </InterestIconContainer>
                        )
                      })}
                    </InterestsIconsWrapper>
                  </InterestTag>

                  {userInfo.instagram || userInfo.facebook ? (
                    <SocialTag>
                      {userInfo?.instagram && userInfo?.instagram !== '' && (
                        <S.SocialMediaButton
                          href={`https://www.instagram.com/${userInfo.instagram}`}
                          target="_blank"
                        >
                          <SocialIcon
                            src="/images/desktop/profile/insta_icon.png"
                            height={47}
                            width={53}
                            alt="Símbolo do Instagram"
                          />
                        </S.SocialMediaButton>
                      )}
                      {userInfo?.facebook && userInfo?.facebook !== '' && (
                        <S.SocialMediaButton
                          href={`https://www.facebook.com/${userInfo.facebook}`}
                          target="_blank"
                        >
                          <img
                            src="/images/desktop/profile/facebook_icon.png"
                            height={47}
                            width={53}
                            alt="Símbolo do Facebook"
                          />
                        </S.SocialMediaButton>
                      )}
                    </SocialTag>
                  ) : null}
                </AddDiv>
              </AdditionalInfos>
            </RowMobile>
          </DescriptionWrapper>
        </Mobile>
      </Header>

      <ItineraryContainer>
        <ItineraryWrapper>
          <HeadingItinerary>
            <TitleContainer>
              <ItineraryTittle>Roteiros publicados</ItineraryTittle>
            </TitleContainer>
          </HeadingItinerary>
          {(userInfo.itineraries?.length ?? 0) > 0 ? (
            userInfo.itineraries?.map((itinerary: any, index: any) => (
              <PublishContainer key={itinerary.id}>
                <PublishCentralized>
                  <Publisher>
                    <ItineraryImageContainer>
                      <ItineraryImage
                        src={
                          itinerary.coverImage ||
                          '/images/desktop/home/redsterna_gray_logo.png'
                        }
                        alt={itinerary.title}
                      />
                    </ItineraryImageContainer>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        marginLeft: 20
                      }}
                    >
                      <LinkTittle
                        to={{
                          pathname: `/roteiro/${itinerary.id}`,
                          state: { itinerary }
                        }}
                        key={index}
                      >
                        {itinerary.title}
                      </LinkTittle>
                    </div>
                  </Publisher>
                  <Rating>
                    {itinerary.ratelength >= 1 ? (
                      <StarRating
                        value={Number(
                          itinerary.ratereduce(
                            (a: number, b: { rate: number }) => a + b.rate,
                            0
                          ) / itinerary.ratelength
                        )}
                        hideStar
                      />
                    ) : (
                      <p style={{ fontSize: 11 }}>Ainda não há avaliações</p>
                    )}
                  </Rating>
                </PublishCentralized>
              </PublishContainer>
            ))
          ) : (
            <SmallText>Nenhum roteiro publicado</SmallText>
          )}
        </ItineraryWrapper>
      </ItineraryContainer>
      <ItineraryContainer>
        <ItineraryWrapper>
          <HeadingItinerary>
            <TitleContainer>
              <ItineraryTittle>Roteiros favoritos</ItineraryTittle>
            </TitleContainer>
          </HeadingItinerary>
          {userInfo.favoriteItineraries?.length > 0 ? (
            userInfo.favoriteItineraries.map(
              ({ itinerary }: any, index: any) => (
                <PublishContainer key={index}>
                  <PublishCentralized>
                    <Publisher>
                      <ItineraryImageContainer>
                        <ItineraryImage
                          src={
                            itinerary.coverImage ||
                            '/images/desktop/home/redsterna_gray_logo.png'
                          }
                          alt={itinerary.title}
                        />
                      </ItineraryImageContainer>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          marginLeft: 20
                        }}
                      >
                        <LinkTittle
                          to={{
                            pathname: `/roteiro/${itinerary.id}`,
                            state: { itinerary }
                          }}
                          key={index}
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start'
                          }}
                        >
                          {itinerary.title}
                        </LinkTittle>
                      </div>
                    </Publisher>
                    <Rating>
                      {itinerary.ratelength >= 1 ? (
                        <StarRating
                          value={Number(
                            itinerary.ratereduce(
                              (a: number, b: { rate: number }) => a + b.rate,
                              0
                            ) / itinerary.ratelength
                          )}
                          hideStar
                        />
                      ) : (
                        <p style={{ fontSize: 11 }}>Ainda não há avaliações</p>
                      )}
                    </Rating>
                  </PublishCentralized>
                </PublishContainer>
              )
            )
          ) : (
            <SmallText>Nenhum roteiro favoritado</SmallText>
          )}
        </ItineraryWrapper>
      </ItineraryContainer>
    </Container>
  )
}

export default User
