import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-top: 70px;

  @media (max-width: 1038px) {
    margin-top: 50px;
  }
`

export const TittleP = styled.p`
  color: #343434;
  font-weight: 600;
  font-size: 40px;
  display: flex;
  margin: 0 40px 0 40px;
  justify-content: space-evenly;
  & span {
    color: #ec0000;
    margin: 0 10px 0 10px;
  }

  @media (max-width: 912px) {
    font-size: 32px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
  }
  @media (max-width: 762px) {
    font-size: 16px;
    flex-grow: 1;
  }
  @media (max-width: 500px) {
    & span {
      margin: 0;
    }
    width: 260px;
    margin: 0;
    flex-grow: 0;
  }
`

export const PlaneDiv = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  @media (max-width: 500px) {
    flex-grow: 0;
  }
`

export const UnRated = styled.div`
  font-size: 11px;
  width: auto;
  text-align: end;
  display: flex;
  flex-wrap: wrap;
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  background-color: #d9d9d9;
`
export const SearchP = styled.p`
  color: #343434;
  font-weight: 600;
  width: auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin: 0;

  & span {
    color: #ec0000;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    font-weight: 600;
  }
`

export const Row = styled.div`
  display: flex;
  height: 100%;
  background-color: #d9d9d9;
  @media (max-width: 1038px) {
    flex-direction: column;
  }
  width: 100%;
`

export const RowCard = styled.div`
  display: flex;
  width: auto;
`

export const CollapseButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  width: 200px;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  color: #3e3e3e;
  margin-bottom: 20px;
  @media (max-width: 1038px) {
    width: 130px;
  }
`

export const CollapseWrapper = styled.div`
  overflow: hidden;
  transition: height 0.3s ease;
`

export const CollapseContent = styled.div`
  width: 100%;
  height: auto;
`

export const FilterDiv = styled.div`
  height: fit-content;
  padding: 40px 0 30px 0;
  background-color: #efefef;
  @media (max-width: 1038px) {
    display: flex;
    padding: 0;
    justify-content: flex-start;
    padding-left: 45px;
  }

  @media (max-width: 650px) {
    padding: 0;
    justify-content: center;
  }
`

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  padding: 20px 10px 60px 10px;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 4px 32px 7px rgba(202, 202, 202, 0.38);
  border-radius: 6px;

  @media (max-width: 1038px) {
    width: auto;
    flex-direction: row;
    height: auto;
    justify-content: flex-start;
    padding: 0 0 0 10px;
    align-self: center;
    margin-top: 20px;
  }
  @media (max-width: 950px) {
    padding: 10px 0 10px 10px;
  }
  @media (max-width: 650px) {
    width: 90%;
  }
`

export const Image = styled.div<{ cover?: string }>`
  background-image: url(${(props) => props.cover});
  width: 90px;
  height: 90px;
  background-size: cover;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;

  @media (max-width: 550px) {
    width: 60px;
    height: 60px;
  }
`

export const HeaderIcon = styled.img`
  height: 80px;
  object-fit: contain;
  flex-grow: 3;
  @media (max-width: 1180px) {
    height: 60px;
    flex-grow: 2;
  }
  @media (max-width: 787px) {
    height: 40px;
    flex-grow: 1;
  }

  @media (max-width: 787px) {
    height: 30px;
  }

  @media (max-width: 500px) {
    height: 14px;
    flex-grow: 0;
  }
`

export const PlaneIcon = styled.img`
  width: auto;
  height: 100px;
  object-fit: cover;
  @media (max-width: 1180px) {
    height: 70px;
  }
  @media (max-width: 787px) {
    height: 50px;
  }
  @media (max-width: 500px) {
    height: 20px;
  }

  @media (max-width: 787px) {
    height: 20px;
  }
`
export const List = styled.div`
  width: 80%;
  flex-grow: 1;
  height: auto;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #efefef;
  flex-direction: column;
  padding-top: 20px;
  @media (max-width: 1038px) {
    width: 100%;
    height: auto;
  }
`

export const Line = styled.div<{ fullWidth?: boolean }>`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 648px) {
    & span {
      font-size: 12px;
      margin-right: 5px;
    }
  }
`

export const SecondLine = styled.div<{ fullWidth?: boolean }>`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  display: flex;
  flex-direction: row;

  @media (max-width: 1038px) {
    width: auto;
    height: auto;
    flex-wrap: wrap;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
`

export const Title = styled.h4`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled.span`
  display: flex;
  align-items: center;
  height: auto;
  font-family: 'Baloo 2', cursive;
  justify-content: center;
  font-size: 20px;
  color: #4f4f4f;
`

export const AuthorName = styled.span`
  font-family: 'Baloo 2', cursive;
  font-size: 14px;
  color: #4f4f4f;
  margin-left: 3px;
`

export const AuthorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  padding-bottom: 20px;
  align-items: center;
  @media (max-width: 1038px) {
    width: auto;
    height: auto;
    padding: 0;
  }
`

export const CheckboxLabel = styled.label`
  font-size: 14px;
  margin-top: 10px;
  color: #4f4f4f;
  font-weight: 600;
  @media (max-width: 1038px) {
    font-size: 14px;
  }

  @media (max-width: 650px) {
    font-size: 12px;
  }
`

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 10px;
  @media (max-width: 1038px) {
    font-size: 10px;
  }
`

export const SearchButton = styled.div`
  width: 45px;
  height: 40px;
  background-color: #ec0000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  @media (max-width: 650px) {
    height: 30px;
  }
`

export const InputsDiv = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
`

export const Input = styled.input`
  flex-grow: 1;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 16px;
    padding: 5px;
    color: #a9a9a9;
  }
  @media (max-width: 1038px) {
    width: 380px;
  }

  @media (max-width: 650px) {
    width: 300px;
    &::placeholder {
      font-size: 14px;
      padding: 5px;
      color: #a9a9a9;
    }
    height: 30px;
  }

  @media (max-width: 511px) {
    width: 180px;
    &::placeholder {
      font-size: 12px;
    }
  }
`

export const InputDiv = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  @media (max-width: 1038px) {
    height: auto;
  }
`

export const InputCost = styled.input`
  width: fit-content;
  margin-left: 20px;
  height: 30px;
  font-size: 12px;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  &::placeholder {
    font-size: 12px;
    padding: 5px;
    color: #7c7c7c;
  }
  @media (max-width: 1038px) {
    width: 100%;
  }
`

export const Card = styled(Link)`
  width: 90%;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  justify-content: center;
  margin-bottom: 20px;
  height: 150px;

  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid #d9d9d9;
  @media (max-width: 1038px) {
    flex-direction: column;
  }
  @media (max-width: 635px) {
    height: 150px;
  }
`

export const Header = styled.div`
  background-size: cover;
  width: 100%;
  height: 250px;
  color: white;
  font-size: 40px;
  font-weight: bold;
  justify-content: center;
  display: flex;
  align-items: center;
  background-position: center;

  @media (max-width: 635px) {
    height: 100px;
  }
`

export const Ghost = styled.div`
  width: 30%;
  padding: 0 10px 0 10px;
  margin: 0 10px 0 10px;
`

export const HeaderContainer = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  @media (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    justify-content: space-around;
    padding: 0 10px 0 10px;
  }
`

export const Banner = styled.div`
  background-image: url('/images/beach-background.jpeg');
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  @media (max-width: 635px) {
    height: 200px;
  }
`

export const Search = styled.div`
  border-radius: 5px;
  background-color: white;
  width: 60%;
  justify-content: space-around;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: fit-content;
  padding: 15px;
  display: flex;
  align-items: center;
  font-size: 18px;

  @media (max-width: 960px) {
    width: 90%;

    font-size: 16px;
    padding: 5px;
    justify-content: space-around;
  }
  @media (max-width: 550px) {
    font-size: 12px;
  }
`
