import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Formik, useFormikContext } from 'formik'

import { useItineraries } from 'contexts/itinerary.context'

import { ModelItineraries } from 'models/ModelItineraries'

import PageContainer from 'components/page-container'

import { PainelCities, PainelDescription, PainelFace } from './componentes'
import {
  ContainerCreateItinerary,
  CoverCreateItinerary,
  CoverIcon,
  FormCreateItinerary,
  FormContainerCreateItinerary
} from './style'

const formEmptyValues: ModelItineraries = {
  id: '',
  made: 'true',
  title: '',
  tripDate: '',
  coverImage: '',
  summary: '',
  days: 1,
  spent: 0.0,
  type: 'simple',
  authorId: '',
  interests: [],
  cities: []
}

const FormikObserver = () => {
  const { values } = useFormikContext()

  React.useEffect(() => {
    window.localStorage.setItem('itinerary', JSON.stringify(values))
  }, [values])

  return null
}

const CreateItinerary = () => {
  const history = useHistory()

  const { createItinerary, uploadFile } = useItineraries()

  const [step, setStep] = useState(1)

  const nextStep = useCallback(() => {
    setStep(step + 1)
  }, [step])

  const prevStep = useCallback(() => {
    setStep(step - 1)
  }, [step])

  const uploadFileCallback = useCallback(
    async (file: File) => {
      const [name, ext] = file.name.split('.')
      const noSpecialChars = name.replace(/[^\w\s]/gi, '')
      const newName = `${noSpecialChars}.${ext}`

      const newFile = new File([file], newName, { type: file.type })
      const response = await uploadFile(newFile)

      return response
    },
    [uploadFile]
  )

  const initialValues: ModelItineraries = useMemo(() => {
    const cached: string | null = window.localStorage.getItem('itinerary')
    return cached ? JSON.parse(cached) : formEmptyValues
  }, [])

  return (
    <PageContainer>
      <ContainerCreateItinerary>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            values.made = values.made === 'true'
            window.localStorage.setItem('itinerary', '')
            await createItinerary(values)
            setTimeout(() => {
              history.push('minha-conta')
            }, 2000)
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue
          }) => {
            return (
              <>
                <CoverCreateItinerary>
                  <CoverIcon src="/images/desktop/create_itinerary.png" />

                  {/*<S.Label>Foto de capa</S.Label>
          <S.Description>
            Escolha a foto que melhor ilustra sua viagem, ela será a capa do seu
            roteiro.
          </S.Description>
          <S.FileInput
            accept="image/*,.heic"
            type="file"
            name="cover"
            onChange={async (e: any) =>
              await uploadFileCallback(e.target.files[0]).then((response) =>
                setFieldValue('coverImage', response)
              )
            }
          />
          {values.coverImage && values.coverImage !== '' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginTop: 10
                }}
              >
                <button
                  type="button"
                  onClick={() => setFieldValue('coverImage', '')}
                >
                  X
                </button>
                <S.CoverImage src={values.coverImage} alt="coverImage" />
              </div>
            </div>
          )}*/}
                </CoverCreateItinerary>

                <FormCreateItinerary onSubmit={handleSubmit}>
                  <FormContainerCreateItinerary>
                    <PainelFace
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      className={step === 1 ? '' : 'hidden'}
                      nextStep={nextStep}
                    />

                    <PainelCities
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      setFieldValue={setFieldValue}
                      className={step === 2 ? '' : 'hidden'}
                      nextStep={nextStep}
                      prevStep={prevStep}
                    />

                    <PainelDescription
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      className={step === 3 ? '' : 'hidden'}
                      prevStep={prevStep}
                    />
                  </FormContainerCreateItinerary>
                  <FormikObserver />
                </FormCreateItinerary>
              </>
            )
          }}
        </Formik>
      </ContainerCreateItinerary>
    </PageContainer>
  )
}

export default CreateItinerary
