import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: #ffff;
  height: 500px;
  z-index: 0;
  @media (max-width: 948px) {
    height: 100%;
  }
`

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`
