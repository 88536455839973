import { ChangeEventHandler, FocusEventHandler, Fragment } from 'react'

import { FieldArray } from 'formik'

import Button from 'components/button'
import CityForm from 'components/cities'

import * as S from '../../style'

const emptyCityData = {
  id: '',
  name: '',
  description: '',
  coordinates: {
    latitude: 0,
    longitude: 0
  },
  rate: 0,
  accommodations: [
    {
      name: '',
      rate: 0
    }
  ],
  images: []
}

type PainelCitiesProps = {
  handleChange: ChangeEventHandler<HTMLTextAreaElement>
  handleBlur: FocusEventHandler<HTMLTextAreaElement>
  values: any
  setFieldValue: (
    field: string,
    value: number | string | Array<string>,
    shouldValidate?: boolean
  ) => void
  className: string
  nextStep: () => void
  prevStep: () => void
}

const PainelCities = ({
  handleChange,
  handleBlur,
  values,
  setFieldValue,
  className,
  nextStep,
  prevStep
}: PainelCitiesProps) => (
  <S.PainelCreateItinerary className={className}>
    <S.ContainerColumn>
      <S.Label>Cidades visitadas</S.Label>
      <S.Description>
        Insira abaixo as cidades que visitou, onde ficou e qual sua avaliação do
        local.
      </S.Description>

      <FieldArray
        name="cities"
        render={(arrayHelpers) => {
          return values.cities.length > 0 ? (
            values.cities.map((city: any, index: any) => (
              <Fragment key={index}>
                <button
                  style={{
                    width: 200,
                    height: 30,
                    marginTop: 20
                  }}
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  Remover cidade abaixo:
                </button>
                <CityForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  city={city}
                  index={index}
                  arrayHelpers={arrayHelpers}
                  setFieldValue={setFieldValue}
                />
                {values.cities.length === index + 1 && (
                  <button
                    style={{ width: 200, height: 30 }}
                    type="button"
                    onClick={() => arrayHelpers.push(emptyCityData)}
                  >
                    Adicionar mais cidades
                  </button>
                )}
              </Fragment>
            ))
          ) : (
            <Button
              type="button"
              onClick={() => arrayHelpers.push(emptyCityData)}
            >
              Adicionar uma cidade
            </Button>
          )
        }}
      />
    </S.ContainerColumn>

    <S.ContentCreateItinerary>
      <Button type="button" onClick={nextStep}>
        <p>Continuar</p>
      </Button>
      <Button type="button" secondary onClick={prevStep}>
        <p>Voltar</p>
      </Button>
    </S.ContentCreateItinerary>
  </S.PainelCreateItinerary>
)

export default PainelCities
