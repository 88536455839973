import { ChangeEventHandler, FocusEventHandler } from 'react'

import { Field } from 'formik'

import Button from 'components/button'

import * as S from '../../style'

type PainelDescriptionProps = {
  handleChange: ChangeEventHandler<HTMLTextAreaElement>
  handleBlur: FocusEventHandler<HTMLTextAreaElement>
  values: any
  className: string
  prevStep: () => void
}

const PainelDescription = ({
  handleChange,
  handleBlur,
  values,
  className,
  prevStep
}: PainelDescriptionProps) => (
  <S.PainelCreateItinerary className={className}>
    <S.ContainerColumn>
      <S.Label>
        QUAIS DESSES OPÇÕES MELHOR DESCREVEM ESSA <span>VIAGEM?</span>
      </S.Label>
      <S.CheckboxWrapper>
        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="cidade_historica"
            name="interests"
            value="cidade_historica"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="cidade_historica">
            Cidade Histórica
          </S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="praia"
            name="interests"
            value="praia"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="praia">Praia</S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="montanha"
            name="interests"
            value="montanha"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="montanha">Montanha</S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="camping"
            name="interests"
            value="camping"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="camping">Camping</S.CheckboxLabel>
        </S.CheckboxContainer>
        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="cidade_moderna"
            name="interests"
            value="cidade_moderna"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="cidade_moderna">
            Cidade Moderna
          </S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="campo"
            name="interests"
            value="campo"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="campo">Campo</S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="cachoeira"
            name="interests"
            value="cachoeira"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="cachoeira">Cachoeira</S.CheckboxLabel>
        </S.CheckboxContainer>

        <S.CheckboxContainer>
          <Field
            as={S.Checkbox}
            id="trekking"
            name="interests"
            value="trekking"
            type="checkbox"
          />
          <S.CheckboxLabel htmlFor="trekking">Trekking</S.CheckboxLabel>
        </S.CheckboxContainer>
      </S.CheckboxWrapper>
    </S.ContainerColumn>

    <S.ContainerColumn>
      <S.Label htmlFor="summary">
        RESUMO DA <span>VIAGEM</span>
      </S.Label>
      <S.TextArea
        id="summary"
        name="summary"
        value={values.summary}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Quais cidades você visitou? O que viu de bacana? Quais os pontos fortes e fracos? Divida suas experiências com a gente!"
      />
    </S.ContainerColumn>

    <S.ContentCreateItinerary>
      <Button type="submit">Salvar Roteiro</Button>
      <Button type="button" secondary onClick={prevStep}>
        <p>Voltar</p>
      </Button>
    </S.ContentCreateItinerary>
  </S.PainelCreateItinerary>
)

export default PainelDescription
