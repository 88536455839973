const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M20.859 13.74H1.419C1.259 13.452 1.115 13.132 0.987 12.78C0.891 12.396 0.843 11.964 0.843 11.484C0.843 10.62 1.051 9.996 1.467 9.612C1.883 9.196 2.427 8.988 3.099 8.988H22.587C22.715 9.244 22.843 9.58 22.971 9.996C23.099 10.412 23.163 10.86 23.163 11.34C23.163 12.172 22.955 12.78 22.539 13.164C22.123 13.548 21.563 13.74 20.859 13.74ZM14.763 2.652V22.572C14.475 22.636 14.107 22.716 13.659 22.812C13.211 22.876 12.747 22.908 12.267 22.908C11.307 22.908 10.587 22.732 10.107 22.38C9.627 22.028 9.387 21.324 9.387 20.268V0.396C9.675 0.331999 10.043 0.251999 10.491 0.156C10.939 0.0599995 11.419 0.0119991 11.931 0.0119991C12.859 0.0119991 13.563 0.203999 14.043 0.587997C14.523 0.939999 14.763 1.628 14.763 2.652Z"
    />
  </svg>
)

export default AddIcon
