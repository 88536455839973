import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from 'contexts/auth.context'

import { RegisterModal } from './components'
import LoginModal from 'components/modal'

import { FlagIcon, LightIcon, LogoutIcon, ProfileUser } from './assets'
import {
  WrapperHeader,
  LinkHeader,
  ButtonHeader,
  ModalButton,
  AuthDiv,
  Image,
  RegistryLink,
  UserHeader
} from './style'

type HeaderProps = { authenticated?: boolean }

const Header = ({ authenticated }: HeaderProps) => {
  const { signOut } = useAuth()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false)

  function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  useEffect(() => {
    if (query.get('isLogin')) {
      setIsModalVisible(true)
    }
    if (query.get('isRegister')) {
      setIsRegisterModalVisible(true)
    }
  }, [query])

  return (
    <>
      <LoginModal
        isOpen={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
      />
      <RegisterModal
        isOpen={isRegisterModalVisible}
        closeModal={() => setIsRegisterModalVisible(false)}
      />
      <WrapperHeader>
        <LinkHeader to="/">
          <Image src="/images/header-logo.png" alt="redsterna logo" />
        </LinkHeader>

        <LinkHeader to="/destinos">
          <FlagIcon />
          <span>Destinos</span>
        </LinkHeader>

        <LinkHeader to="/dicas-de-viagem">
          <LightIcon />
          <span>Dicas de Viagem</span>
        </LinkHeader>

        {!authenticated ? (
          <AuthDiv>
            <RegistryLink onClick={() => setIsRegisterModalVisible(true)}>
              Cadastrar-se
            </RegistryLink>
            <ModalButton onClick={() => setIsModalVisible(true)}>
              Login
            </ModalButton>
          </AuthDiv>
        ) : (
          <UserHeader>
            <LinkHeader to="/minha-conta">
              <ProfileUser />
              <span>Minha Conta</span>
            </LinkHeader>

            <ButtonHeader onClick={() => signOut()}>
              <LogoutIcon />
              <span>SAIR</span>
            </ButtonHeader>
          </UserHeader>
        )}
      </WrapperHeader>
    </>
  )
}

export default Header
