import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { FieldArray } from 'formik'

import { useItineraries } from 'contexts/itinerary.context'

import StarRating from '../starRating'
import * as S from './style'

type CityFormProps = {
  index: any
  handleChange: any
  city: any
  handleBlur: any
  values: any
  setFieldValue: (
    field: string,
    value: number | string | Array<string>,
    shouldValidate?: boolean
  ) => void
  arrayHelpers: any
}

const CityForm = ({
  index,
  handleChange,
  handleBlur,
  values,
  setFieldValue
}: CityFormProps) => {
  const { uploadFile } = useItineraries()

  const [cityName, setCityName] = useState<string>(
    values.cities[index].name || ''
  )
  const [listOptions, setListOptions] = useState<any>([])

  useEffect(() => {
    async function changeOptions() {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${cityName}.json?language=pt&access_token=pk.eyJ1IjoibGF1cmFoZGV2IiwiYSI6ImNsY3BtMTN4dDFueG0zcG81em41YXh4emIifQ.eGKakvFNW6mcmRuHze23mg`
      )

      if (
        cityName !== values.cities[index].name &&
        response.data.features.length > 0
      ) {
        setListOptions(response.data.features)
      }

      if (
        cityName === '' ||
        (cityName !== values.cities[index].name &&
          response.data.features.length <= 0)
      ) {
        setListOptions([])

        setFieldValue(`cities.${index}.id`, '')
        setFieldValue(`cities.${index}.name`, '')
        setFieldValue(`cities.${index}.latitude`, 0)
        setFieldValue(`cities.${index}.longitude`, 0)
      }
    }
    changeOptions()
  }, [cityName, setFieldValue, index, values.cities])

  const uploadFileCallback = async (file: any) => {
    const response = await uploadFile(file)
    const image = response.replace(' ', '%20')
    return image
  }

  const uploadFiles = async (files: any) => {
    const images = await Promise.all(
      [...files].map((file: any) => uploadFileCallback(file))
    )
    const imageFiles = images.map((image) => ({ file: image }))
    setFieldValue(`cities.${index}.images`, [
      ...values.cities[index].images,
      ...imageFiles
    ])
  }

  return (
    <S.Container>
      <S.FormContainer>
        <S.Label>Cidade</S.Label>
        <S.RowLine>
          <S.Input
            autoComplete="off"
            name={`cities.${index}.name`}
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
            placeholder="Orlando"
            required
          />
          <S.Label>Avaliação</S.Label>
          <StarRating
            value={Number(values.cities[index].rate)}
            onClick={(value: number) => {
              setFieldValue(`cities.${index}.rate`, Number(value))
            }}
            name={`cities.${index}.rate`}
          />
        </S.RowLine>

        {listOptions.filter((item: any) =>
          item.place_name_pt.toLowerCase().includes(cityName.toLowerCase())
        ).length > 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              overflowY: 'auto'
            }}
          >
            {listOptions
              .filter((item: any) =>
                item.place_name_pt
                  .toLowerCase()
                  .includes(cityName.toLowerCase())
              )
              .map((item: any, optionIndex: any) => (
                <button
                  type="button"
                  style={{
                    backgroundColor: 'transparent',
                    minHeight: 40,
                    border: '0.1px solid #343434',
                    width: '100%'
                  }}
                  key={optionIndex}
                  onClick={() => {
                    setCityName(item.place_name_pt)

                    setFieldValue(`cities.${index}.id`, item.id)
                    setFieldValue(`cities.${index}.name`, item.place_name_pt)
                    setFieldValue(
                      `cities.${index}.latitude`,
                      item.geometry.coordinates[1]
                    )
                    setFieldValue(
                      `cities.${index}.longitude`,
                      item.geometry.coordinates[0]
                    )
                    setListOptions([])
                  }}
                >
                  <p>{item.place_name_pt}</p>
                </button>
              ))}
          </div>
        )}

        {values.cities[index].accommodations && (
          <FieldArray
            name={`cities.${index}.accommodations`}
            render={(arrayHelpers) => {
              return values.cities[index].accommodations.length > 0 ? (
                values.cities[index].accommodations.map(
                  (accommodation: { name: string; rate: 1 }, indexAcc: any) => (
                    <Fragment key={indexAcc}>
                      <S.RowHotel>
                        <S.Label>Hospedagem</S.Label>
                        <S.DeleteButton
                          type="button"
                          onClick={() => arrayHelpers.remove(indexAcc)}
                        >
                          <S.ButtonIcon src="/images/caixote-de-lixo.png" />
                        </S.DeleteButton>
                      </S.RowHotel>

                      <S.RowLine>
                        <S.Input
                          name={`cities.${index}.accommodations.${indexAcc}.name`}
                          value={
                            values.cities[index].accommodations[indexAcc].name
                          }
                          onChange={handleChange}
                          placeholder="Orlando Hotel"
                        />
                        <S.Label>Avaliação</S.Label>
                        <StarRating
                          value={Number(
                            values.cities[index].accommodations[indexAcc].rate
                          )}
                          onClick={(value: number) => {
                            setFieldValue(
                              `cities.${index}.accommodations.${indexAcc}.rate`,
                              value
                            )
                          }}
                          name={`cities.${index}.accommodations.${indexAcc}.rate`}
                        />
                      </S.RowLine>

                      {values.cities[index].accommodations.length ===
                        indexAcc + 1 && (
                        <button
                          style={{ width: 250 }}
                          type="button"
                          onClick={() =>
                            arrayHelpers.insert(indexAcc + 1, {
                              name: '',
                              rate: 0
                            })
                          }
                        >
                          Adicionar hospedagem
                        </button>
                      )}
                    </Fragment>
                  )
                )
              ) : (
                <button
                  style={{ width: 250, height: 20 }}
                  type="button"
                  onClick={() => arrayHelpers.push({ name: '', rate: 0 })}
                >
                  Adicionar uma hospedagem
                </button>
              )
            }}
          />
        )}
        <S.Label>Descrever destino</S.Label>
        <S.Description>
          Caso queira descrever como foi sua estada nessa cidade, preencha o
          campo abaixo.
        </S.Description>
        <S.TextArea
          onChange={handleChange}
          onBlur={handleBlur}
          name={`cities.${index}.description`}
          value={values.cities[index].description}
          placeholder="Orlando é uma cidade muito turística e cheia de opções de lazer, compras e entretenimento. Nos 3 primeiros dias de minha viagem, pude..."
        />
        {values.cities[index].images && (
          <FieldArray
            name={`cities.${index}.images`}
            render={(arrayHelpers) => (
              <>
                <S.FileInput
                  accept="image/*,.heic"
                  type="file"
                  onChange={async (e: any) => uploadFiles(e.target.files)}
                  multiple
                />
                <S.ImagesWrapper>
                  {values.cities[index].images.length > 0 &&
                    values.cities[index].images.map(
                      ({ file: img }: any, indexImg: any) => (
                        <S.ImagesContainer key={img}>
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(indexImg)}
                          >
                            X
                          </button>
                          <S.CityImage src={img} alt="cover" />
                        </S.ImagesContainer>
                      )
                    )}
                </S.ImagesWrapper>
              </>
            )}
          />
        )}
      </S.FormContainer>
    </S.Container>
  )
}

export default CityForm
