import styled from 'styled-components'

export const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  color: #4f4f4f;
  font-size: 18px;
`

export const Divider = styled.hr`
  width: 360px;
  height: 1px;

  margin: 10px 0 20px;

  color: #a9a9a9;
`

export const TitleContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.span`
  margin-top: 10px;

  font-size: 24px;
  text-align: center;
`
