export const getButtonAffiliateLinks = [
  {
    url: 'https://www.rentcars.com/pt-br/?requestorid=4376&utm_source=redsterna.com.br&utm_medium=afiliado-link',
    image: '/images/car-icon.png',
    title: 'Aluguel de Carros'
  },
  {
    url: 'https://www.parceirospromo.com.br/partners/affiliate/americachip?utm_medium=afiliado&pcrid=4662&utm_source=site-blog&cupom=AMERICA10',
    image: '/images/chip-icon.png',
    title: 'CHIP'
  },
  {
    url: 'https://www.segurospromo.com.br/?utm_medium=afiliado&pcrid=4662&utm_source=site-blog&cupom=MALASPRONTASVIAG',
    image: '/images/health-icon.png',
    title: 'Seguro Viagem'
  },
  {
    url: 'https://www.booking.com/index.pt-br.html?aid=376377&label=booking-name-pt-row-bwMffLz%2AfdB8PTKNsC9tlgS267778091920%3Apl%3Ata%3Ap1%3Ap22.563.000%3Aac%3Aap%3Aneg%3Afi%3Atikwd-65526620%3Alp1001775%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YXwxhKG0pUU-3JdcXtALQMg&sid=bf844347c8a2442294f8f9502f2ec724',
    image: '/images/hotel-icon.png',
    title: 'Hotel'
  },
  {
    url: 'https://www.passagenspromo.com.br/?utm_medium=afiliado&pcrid=4662&utm_source=site-blog',
    image: '/images/planing-icon.png',
    title: 'Passagem'
  }
]

// testar um  roteiro de multiplas cidades com varias cidades + de 10 pra ver se a ordem persistem da lista
// testar adicionar mais de uma imagem  e varias descrições de roteiros pela cidade, validar se não embaralhou entre eals
// testar local storage de roteiro criado > roteiro novo e roteiro sendo editado (pagina fechar, cair a)
// verificar preços da api
// filtro valor 0 defaault
// redirecionar roteiro pelo link
// testar filtrar pelo texto da descrição da cidade do roteiro para conseguir acessar informações da cidade exmplo praias, locais etc
