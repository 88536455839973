import { createContext, useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import api from 'services/api'

import { ModelItineraries } from 'models/ModelItineraries'

interface ICityData {
  name: string
  description: string
  coordinates: {
    latitude: number
    longitude: number
  }
  rate: number
  accommodations: IAccommodationData[]
  images: string[]
}

interface IAccommodationData {
  name: string
  rate: number
}

interface ItinerariesContextData {
  uploadFile(file: any): Promise<string>
  favoriteItinerary(id: string): Promise<void>
  rateItinerary({ id, rate }: { id: string; rate: number }): Promise<void>
  createItinerary(itineraryData: ModelItineraries): Promise<void>
  setItineraries: React.Dispatch<React.SetStateAction<ModelItineraries[]>>
  itineraries: ModelItineraries[]
  updateItinerary(itineraryData: ModelItineraries): Promise<void>
  getItineraries(itineraryData: ModelItineraries): Promise<void>
  getAllItineraries(): Promise<ModelItineraries[]>
}

const ItinerariesContext = createContext<ItinerariesContextData>(
  {} as ItinerariesContextData
)

export const ItinerariesProvider = ({ children }: any) => {
  const [itineraries, setItineraries] = useState<ModelItineraries[]>([])

  const uploadFile = useCallback(async (file: any) => {
    const formData = new FormData()

    formData.append('file', file)

    const response = await api.post('/itineraries/upload', formData)

    return response.data.locationInS3
  }, [])

  const getAllItineraries = useCallback(async () => {
    const response = await api.get('/itineraries')
    return response.data as ModelItineraries[]
  }, [])

  const getItineraries = useCallback(async (itineraryData: any) => {
    try {
      await api.get('/itineraries', {
        ...itineraryData
      })
    } catch {
      toast.error(
        'Não foi possível visualizar os roteiros, tente novamente mais tarde',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        }
      )
    }
  }, [])

  const createItinerary = useCallback(async (itineraryData: any) => {
    try {
      await api.post('/itineraries', {
        ...itineraryData,
        type: 'simple',
        made: Boolean(itineraryData.made === 'true')
      })
      toast.success('Roteiro criado com sucesso', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    } catch {
      toast.error('Não foi possível criar o roteiro', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }, [])

  const updateItinerary = useCallback(async (itineraryData: any) => {
    try {
      await api.put('/itineraries', {
        ...itineraryData,
        type: 'simple',
        made: Boolean(itineraryData.made === 'true')
      })
      toast.success('Roteiro editado com sucesso', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    } catch {
      toast.error('Não foi possível editar o roteiro', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }, [])

  const favoriteItinerary = useCallback(async (id: string) => {
    try {
      const response = await api.post('/itineraries/user/favorite', {
        itinerary: id
      })

      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    } catch (e: any) {
      toast.error(e.response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }, [])

  const rateItinerary = useCallback(
    async ({ id, rate }: { id: string; rate: number }) => {
      try {
        const response = await api.post('/itineraries/rate', {
          id: id,
          rate: rate
        })

        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      } catch (e: any) {
        toast.error(e.response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'dark'
        })
      }
    },
    []
  )

  return (
    <ItinerariesContext.Provider
      value={{
        rateItinerary,
        favoriteItinerary,
        uploadFile,
        setItineraries,
        itineraries,
        createItinerary,
        updateItinerary,
        getItineraries,
        getAllItineraries
      }}
    >
      {children}
    </ItinerariesContext.Provider>
  )
}

export function useItineraries(): ItinerariesContextData {
  return useContext(ItinerariesContext)
}
