import React, { useEffect } from 'react'

const ViatorBanner = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://partners.vtrcdn.com/static/scripts/banners/banners.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      data-id="viator-banner"
      data-partner-id="P00037907"
      data-url="https://www.viator.com/Oahu/d672-ttd?pid=P00037907&mcid=42383&medium=link"
      data-banner-width="300"
      data-banner-height="250"
      data-banner-language="en"
      data-banner-selection="banner1"
    ></div>
  )
}

export default ViatorBanner
