import { Link as LinkRouter } from 'react-router-dom'

import styled from 'styled-components'

type props = {
  src: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: 70px;

  @media (max-width: 948px) {
    margin-top: 50px;
  }
`

export const TittleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  line-height: 20px;
  @media (max-width: 948px) {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    width: fit-content;
    display: flex;
  }
`

export const LogoIcon = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  line-height: 20px;
`

export const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  background-color: white;

  @media (max-width: 948px) {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
  }
`

export const SecondBanner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  background-color: white;

  @media (max-width: 948px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: normal;

    width: 100%;
    height: 100%;
  }
`

export const TipBanner = styled.div`
  display: flex;
  justify-content: center;
`

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  position: relative;
`

export const Title = styled.h2`
  display: flex;
  font-size: 30px;
  width: fit-content;
  font-weight: 700;
`

export const Section = styled.section`
  flex-direction: column;
  display: flex;
  margin-top: 40px;
  background-color: transparent;
  box-shadow: 4px 20px 20px rgb(0 0 0 / 10%);

  @media (max-width: 948px) {
    height: auto;
  }

  @media (max-width: 500px) {
    height: 100%;
    margin-top: 20px;
  }
`

export const LinkIcons = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  background-color: #868686;
`

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  margin-top: 8px 0 8px 0;
  font-size: 14px;
  cursor: pointer;
  color: #777;
  text-transform: uppercase;

  &:hover {
    color: #ec0000;
  }
`

export const RoadmapButton = styled(LinkRouter)`
  position: absolute;
  margin-top: 10%;
  margin-left: 35%;
  height: 230px;
  width: 230px;
  background-image: url('/images/desktop/home/roadmap_button.png');
  background-color: transparent;
`

export const BannerButton = styled.button`
  color: white;
  width: fit-content;
  height: 52px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 40px 10px 40px;
  margin-top: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: linear-gradient(90deg, #ec0000 -0.09%, #d60000 100.09%);

  @media (max-width: 508px) {
    height: 30px;
    font-size: 14px;
    border-radius: 10px;

    padding: 5px 15px 5px 15px;
  }
`

export const AditionalInfoButton = styled.a`
  position: absolute;
  margin-top: 5%;
  margin-right: 45%;
  height: 230px;
  width: 230px;
  background-image: url('/images/desktop/home/tip_button.png');
  background-color: transparent;
`

export const RoadmapText = styled.p`
  color: #868686;
  width: 450px;
  font-size: 20px;
  text-align: center;

  @media (max-width: 948px) {
    font-size: 20px;
    width: 70%;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    width: 80%;
  }
`

export const Tittle = styled.h2`
  font-size: 52px;
  margin: 0 10px 0 10px;
  font-weight: 400px;
  width: 450px;
  line-height: 50px;

  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }

  @media (max-width: 948px) {
    font-size: 28px;
    width: auto;
  }

  @media (max-width: 500px) {
    font-size: 20px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;

    & span:first-child {
      font-size: 20px;

      width: fit-content;
    }

    & span:last-child {
      font-size: 20px;

      width: fit-content;
    }
  }
`

export const SecondTittle = styled.h2`
  font-size: 52px;
  margin: 0 10px 0 10px;
  font-weight: 700;
  width: 200px;
  line-height: 40px;

  & span:first-child {
    color: #343434;
  }

  & span:last-child {
    color: #ec0000;
  }

  @media (max-width: 948px) {
    font-size: 28px;
    width: auto;
  }

  @media (max-width: 500px) {
    & span:first-child {
      font-size: 16px;

      width: fit-content;
    }

    & span:last-child {
      font-size: 16px;

      width: fit-content;
    }
  }
`

export const RedText = styled.h2`
  color: #ec0000;
  font-size: 40px;
  margin: 0 10px 0 10px;
  font-weight: 700;
`

export const BannerLeft = styled.img<props>`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 0 50px 50px 0;

  @media (max-width: 948px) {
    height: 100%;
  }
`

export const ImageBannerDiv = styled.div`
  width: 56%;
  height: auto;

  margin-left: 2px;
  border-radius: 60px 0 0;

  @media (max-width: 948px) {
    width: 100%;
    height: 300px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const BannerRight = styled.img<props>`
  width: 100%;
  height: 500px;
  object-fit: cover;
  border-radius: 0 50px 50px 0;

  @media (max-width: 948px) {
    height: 100%;
  }

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`
export const BannerColumn = styled.div`
  width: 44%;
  height: 500px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: white;

  @media (max-width: 948px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 20px 0 20px 0;
    height: fit-content;
  }
`

export const RegisterText = styled.p`
  margin-top: 260px;
  margin-left: 280px;
  position: absolute;
  word-break: break-word;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
`

export const TipsText = styled.p`
  margin-top: 40px;
  margin-left: 32%;
  position: absolute;
  word-break: break-word;
  width: 10%;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
`
