import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import api from 'services/api'

import { ModelItineraries } from 'models/ModelItineraries'

import Collapse from 'components/collapse'
import StarRating from 'components/starRating'

import {
  AuthorDiv,
  AuthorName,
  Banner,
  Card,
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  Column,
  Container,
  FilterDiv,
  Filters,
  Header,
  HeaderContainer,
  HeaderIcon,
  Image,
  Input,
  InputCost,
  InputDiv,
  InputsDiv,
  Line,
  List,
  PlaneDiv,
  PlaneIcon,
  Row,
  RowCard,
  Search,
  SearchButton,
  SearchP,
  Text,
  Title,
  TittleP,
  UnRated,
  Wrapper
} from './style'

type SearchType = {
  city: string
  interests: Array<string>
  min_price: string
  max_price: string
}

const getQueryString = ({
  city,
  interests,
  min_price,
  max_price
}: SearchType) => {
  const queryString = []

  city && queryString.push(`city=${city}`)
  interests.length > 0 && queryString.push(`interests=${interests}`)
  min_price && queryString.push(`min_price=${min_price}`)
  max_price && queryString.push(`max_price=${max_price}`)

  return queryString.length > 0 ? `?${queryString.join('&')}` : ''
}

export default function ListItinerary() {
  const savedTimer = useRef<NodeJS.Timeout | null>(null)

  const { search } = useLocation()
  const { push } = useHistory()

  const parameters = useMemo(() => {
    const query = new URLSearchParams(search)

    return {
      city: query.get('city') ?? '',
      interests: query.get('interests')?.split(',') ?? [],
      min_price: query.get('min_price') ?? '',
      max_price: query.get('max_price') ?? ''
    }
  }, [search])

  const [params, setParams] = useState<SearchType>(parameters)

  useEffect(() => {
    savedTimer.current = setTimeout(() => {
      push(`/destinos${getQueryString(params)}`)
    }, 500)

    return () => {
      savedTimer.current && clearTimeout(savedTimer.current)
    }
  }, [params, push])

  const { data: itineraries, isLoading } = useQuery({
    queryKey: [
      'itineraries',
      parameters.city,
      parameters.interests,
      parameters.min_price,
      parameters.max_price
    ],
    queryFn: async () => {
      const { data }: { data: Array<ModelItineraries> } = await api.get(
        `/itineraries${getQueryString(params)}`
      )
      return data
    },
    staleTime: 5 * 60 * 1000,
    enabled: !!parameters
  })

  const handleField: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setParams({ ...params, [e.target.name]: e.target.value })
    },
    [params]
  )

  const handleInterests: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const index = params.interests.indexOf(e.target.value)

      if (index === -1) {
        params.interests.push(e.target.value)
      } else {
        params.interests.splice(index, 1)
      }

      setParams({ ...params, [e.target.name]: params.interests.join(',') })
    },
    [params]
  )

  const isMobile = window.innerWidth < 510

  return (
    <Container>
      <Header>
        <HeaderContainer>
          <HeaderIcon src="/images/Vector.svg" />
          <TittleP>
            Navegue pelos <span>roteiros</span> publicados
          </TittleP>
          <PlaneDiv>
            <PlaneIcon src="/images/travel-design.png" />
          </PlaneDiv>
        </HeaderContainer>
      </Header>
      <Banner>
        <Search>
          <SearchP>
            Buscar <span>roteiros</span>
          </SearchP>
          <InputsDiv>
            <Input
              type="text"
              placeholder="Procurar por cidade..."
              name="city"
              value={params.city}
              onChange={handleField}
            />
            <SearchButton>
              <img
                src="/images/search-icon.svg"
                alt="busca"
                height={isMobile ? 15 : 20}
                width={isMobile ? 15 : 35}
              />
            </SearchButton>
          </InputsDiv>
        </Search>
      </Banner>

      <Row>
        <FilterDiv>
          <Filters>
            <Collapse title="Filtrar por custo">
              <InputDiv>
                De
                <InputCost
                  type="number"
                  placeholder="Valor Mínimo"
                  step="0.01"
                  min="0"
                  name="min_price"
                  value={params.min_price}
                  onChange={handleField}
                />
              </InputDiv>
              <InputDiv>
                Até
                <InputCost
                  type="number"
                  placeholder="Valor Máximo"
                  step="0.01"
                  min="0"
                  name="max_price"
                  value={params.max_price}
                  onChange={handleField}
                />
              </InputDiv>
            </Collapse>

            <Collapse title="Filtrar por interesses">
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="cidade_historica"
                  value="cidade_historica"
                  checked={params.interests.includes('cidade_historica')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="cidade_historica">
                  Cidade Histórica
                </CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="praia"
                  value="praia"
                  checked={params.interests.includes('praia')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="praia">Praia</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="montanha"
                  value="montanha"
                  checked={params.interests.includes('montanha')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="montanha">Montanha</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="camping"
                  value="camping"
                  checked={params.interests.includes('camping')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="camping">Camping</CheckboxLabel>
              </CheckboxContainer>
              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="cidade_moderna"
                  value="cidade_moderna"
                  checked={params.interests.includes('cidade_moderna')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="cidade_moderna">
                  Cidade Moderna
                </CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="campo"
                  value="campo"
                  checked={params.interests.includes('campo')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="campo">Campo</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="cachoeira"
                  value="cachoeira"
                  checked={params.interests.includes('cachoeira')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="cachoeira">Cachoeira</CheckboxLabel>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  type="checkbox"
                  id="trekking"
                  value="trekking"
                  checked={params.interests.includes('trekking')}
                  onChange={handleInterests}
                />
                <CheckboxLabel htmlFor="trekking">Trekking</CheckboxLabel>
              </CheckboxContainer>
            </Collapse>
          </Filters>
        </FilterDiv>

        {isLoading || !itineraries ? (
          <></>
        ) : (
          <Wrapper>
            <List>
              {itineraries.length > 0 ? (
                itineraries.map((itinerary: ModelItineraries) => (
                  <Card
                    key={itinerary.id}
                    to={{
                      pathname: `/roteiro/${itinerary.id}`,
                      search: `?itineraryId=${itinerary.id}`,
                      state: { itinerary }
                    }}
                  >
                    <Line>
                      <RowCard>
                        <Column>
                          <Image
                            cover={
                              itinerary.coverImage
                                ? itinerary.coverImage
                                : '/images/desktop/home/redsterna_gray_logo.png'
                            }
                          />
                        </Column>

                        <AuthorDiv>
                          <Text>{itinerary.title}</Text>
                          {itinerary.author && (
                            <div>
                              <AuthorName>
                                {itinerary.author.firstname}
                              </AuthorName>
                              <AuthorName>
                                {itinerary.author.lastname}
                              </AuthorName>
                            </div>
                          )}
                        </AuthorDiv>
                      </RowCard>

                      <Column>
                        {!itinerary.rates ||
                        (itinerary.rates?.length ?? 0) === 0 ? (
                          <UnRated style={{ fontSize: 11 }}>
                            Ainda não há avaliações
                          </UnRated>
                        ) : (
                          <Line>
                            <StarRating
                              value={
                                itinerary.rates.reduce(
                                  (a: number, b: { rate: number }) =>
                                    a + b.rate,
                                  0
                                ) / itinerary.rates.length
                              }
                              hideStar
                            />
                          </Line>
                        )}
                      </Column>
                    </Line>
                  </Card>
                ))
              ) : (
                <Title>Não há roteiros para serem listados</Title>
              )}
            </List>
          </Wrapper>
        )}
      </Row>
    </Container>
  )
}
