import { useState, useEffect, useRef } from 'react'
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl
} from 'react-map-gl'

import { useQuery } from '@tanstack/react-query'

import { useItineraries } from 'contexts/itinerary.context'

import { ModelCity } from 'models/ModelCity'

import CityModal from 'components/cityModal'

import * as C from './styles'

const MapPage = (props: any) => {
  const { getAllItineraries, setItineraries, itineraries } = useItineraries()

  const [selectedCity, setSelectedCity] = useState<ModelCity | null>()

  const [isOpen, setIsOpen] = useState(false)
  const mapRef = useRef<any>()

  const { data } = useQuery({
    queryKey: ['AllItineraries'],
    queryFn: getAllItineraries,
    staleTime: 15 * 60 * 1000
  })

  useEffect(() => {
    setItineraries(data as never[])
  }, [data, setItineraries])

  return (
    <C.Container>
      <Map
        style={{ width: '100%', height: props.height }}
        mapboxAccessToken="pk.eyJ1IjoibGF1cmFoZGV2IiwiYSI6ImNsY3BtMTN4dDFueG0zcG81em41YXh4emIifQ.eGKakvFNW6mcmRuHze23mg"
        mapStyle="mapbox://styles/mapbox/streets-v9"
        initialViewState={{ longitude: -770.3, latitude: -30.2, zoom: 4 }}
        minZoom={1}
        ref={mapRef}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />

        {itineraries?.map((itinerary: any) => {
          return itinerary.cities.map(
            (city: ModelCity) =>
              city.latitude &&
              city.longitude && (
                <Marker
                  key={city.id}
                  longitude={city.longitude}
                  latitude={city.latitude}
                  anchor="bottom"
                  onClick={(e) => {
                    e.originalEvent.stopPropagation()
                    setSelectedCity(city)
                    setIsOpen(true)
                  }}
                >
                  <img
                    src="/images/pin.png"
                    alt="ícone redsterna"
                    height={25}
                    width={23}
                  />
                </Marker>
              )
          )
        })}
      </Map>
      {selectedCity && (
        <CityModal
          isOpen={isOpen}
          city={selectedCity}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </C.Container>
  )
}

export default MapPage
