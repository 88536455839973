import { ReactNode, useMemo } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AuthProvider } from './auth.context'
import { ItinerariesProvider } from './itinerary.context'
import { UserProvider } from './user.context'

type RootProps = {
  children: ReactNode
}

const Root = ({ children }: RootProps) => {
  const queryClient = useMemo(() => new QueryClient(), [])

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ItinerariesProvider>{children}</ItinerariesProvider>
        </UserProvider>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default Root
