import { AiFillStar } from 'react-icons/ai'

import styled from 'styled-components'

export const Input = styled.input`
  display: none;
`

export const Star = styled(AiFillStar)`
  cursor: pointer;
  transition: color 200ms;
  height: 20px;
  width: 30px;
  @media (max-width: 948px) {
    height: 15px;
    width: 15px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: cente;
  justify-content: center;
  margin-right: 10px;
`
