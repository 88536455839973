import React from 'react'

import * as S from './style'

const Privacy = () => {
  return (
    <S.Container>
      <h2>Política de privacidade </h2>
      <h4>Última atualização: 09/04/2023</h4>
      <S.Description>
        Esta política descreve as informações que processamos para viabilizar a
        operação do RedSterna. Sabemos que para fornecer tais informações
        pessoais conosco requer confiança, e o RedSterna leva muito a sério a
        segurança da informação a fim de garantir o respeito à sua privacidade e
        aos seus dados. Operamos serviços que oferecem planejamento e
        recomendações de viagens, tais como hotéis, compra de passagens, aluguel
        de veículos, atrações, restaurantes entre outros. Nessa política
        chamamos tais serviços de “dicas de viagens”.
      </S.Description>
      <h3> Quais informações coletamos?</h3>
      <S.Description>
        O RedSterna coleta os dados pessoais apenas no cadastro do seu perfil,
        os dados solicitados são nome, sobrenome, país, data de nascimento e
        email. O RedSterna nunca pede outros dados pessoais fora do momento do
        cadastro. Como somos uma rede social, o usuário pode criar e
        compartilhar conteúdo, enviar mensagens e se comunicar com outras
        pessoas, então recomendamos que nessas interações não seja compartilhado
        dados sensíveis e esse conteúdo é de inteira responsabilidade do
        usuário. Coletamos a localização do usuário para uma melhor experiência
        no nosso site, mas não é obrigatório dar o aceite para localização. Se
        tratando do conteúdo publicado pelo usuário, nosso sistema processa as
        informações que são fornecidas a fim de analisar o contexto e realizar
        moderação caso seja necessário. O RedSterna trabalha com parceiros
        afiliados, e no momento de acesso do usuário aos nossos serviços de
        dicas de viagens a coleta de dados passa para a responsabilidade de cada
        parceiro, cabendo ao usuário aceitar a política de privacidade de cada
        um deles. O RedSterna pode coletar dados técnicos de acesso dos usuários
        como: cookies, dados de identificação (ID da sessão, ID do usuário, ID
        do dispositivo), status de acesso/código de status HTTP, software e
        versão do navegador, sistema operacional e a interface dele, provedor de
        serviços de internet, idioma e outras configurações.
      </S.Description>
      <h3>Retenção das informações</h3>
      <S.Description>
        As informações dos nossos usuários serão mantidas em nosso sistema
        enquanto o usuário tiver uma conta conosco. A partir do momento que a
        conta for excluída, todas as informações são retiradas de nossa base de
        dados. O usuário pode deletar sua conta quando achar necessário. Nunca
        compartilhamos os dados pessoais de nossos usuários, nem mesmo comF
        nossos parceiros afiliados. Cabe ao usuário passar seus dados para esses
        parceiros caso execute seus serviços.
      </S.Description>
      <h3>Atualização da Política de Privacidade</h3>
      <S.Description>
        Esta política poderá ser atualizada a qualquer momento e sempre que
        houver atualização enviaremos um email para nossa base cadastral para
        que aceite a nova política. Caso tenha alguma dúvida com relação a
        política de privacidade envie um email para redsterna@gmail.com com o
        assunto: “Dúvida sobre a política de privacidade” que responderemos em
        até 48h.
      </S.Description>
    </S.Container>
  )
}

export default Privacy
