import React, { useEffect } from 'react'

const ViatorWidget = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.viator.com/orion/partner/widget.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div
      data-vi-partner-id="P00037907"
      data-vi-widget-ref="W-a5745fab-4e2d-4a05-bbcc-a896735a8fba"
    ></div>
  )
}

export default ViatorWidget
