import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import api from 'services/api'

const LoginToken = () => {
  const { search } = useLocation()

  useEffect(() => {
    const query = new URLSearchParams(search)
    const access_token = query.get('token')

    if (access_token) {
      api.defaults.headers.common.token = `Bearer ${access_token}`

      window.localStorage.setItem('token', access_token)

      api.get('users').then((response) => {
        if (response.status === 200) {
          window.localStorage.setItem('user', JSON.stringify(response.data))

          window.location.href = '/'
        }
      })
    }
  }, [search])

  return <></>
}

export default LoginToken
