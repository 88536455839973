import ViatorWidget from 'components/viatorWidget'
import ViatorBanner from 'components/viatorWidget/secondBanner'

import * as C from './styles'

const SuggestionPage = (props: any) => {
  const name: string = props.match.params.name

  const page: any = {
    aluguel: {
      title_1: 'Aluguel de',
      title_2: 'carro',
      image: '/images/dicas/aluguel.jpeg',
      description: (
        <C.Article>
          <C.Text>
            Aqui você encontrará informações de como alugar seu carro no Brasil
            e no exterior
          </C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.LignDivider src="/images/tips-lign.png" alt="" />
              <C.SubTitle>
                Por que a Rentcars em parceria com a Red Sterna é a melhor opção
                para aluguel de carros no exterior?
              </C.SubTitle>
              <C.Divider />

              <C.Text>
                Além de já sair do Brasil com a reserva do carro garantida, você
                tem a opção de fazer o pagamento do aluguel em reais, com cartão
                de crédito ou boleto e sem IOF – o que já significa uma economia
                de 6,38%! E tem mais: a Rent Cars da todo o suporte necessário,
                com atendimento personalizado em português independentemente de
                onde você estiver.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SubTitle> Como contratar um aluguel de carro?</C.SubTitle>
              <C.Divider />

              <C.Text>
                Para contratar o aluguel do seu carro para o Brasil ou para o
                exterior com segurança, agilidade e conforto e melhor preço
                garantido, basta clicar na imagem abaixo:
              </C.Text>
              <C.Widget>
                <object
                  data="https://widgets.rentcars.com/widget-v8.html?requestor=4376&amp;locale=pt-br&amp;utm_source=redsterna.com.br&amp;utm_medium=afiliado-widget"
                  width="100%"
                  height="100%"
                ></object>
              </C.Widget>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.SecondTitle>Outras dúvidas</C.SecondTitle>
          <C.CardText>
            <C.SubTitle>Como comparar preços de aluguel de carros?</C.SubTitle>
            <C.Divider />
            <C.Text>
              Para pesquisar pelos melhores preços, basta inserir o local onde
              você deseja retirar o carro (pode ser uma cidade ou aeroporto), a
              data e o horário de retirada e devolução. Também é possível
              retirar o carro em um local e devolvê-lo em outro, basta
              selecionar a opção ‘’devolver em outra cidade’’. Depois de clicar
              em pesquisar, é listado as melhores ofertas de aluguel de carros e
              você pode filtrar pela sua locadora de preferência, categoria de
              carro desejada, intervalo de preços, entre outros.
            </C.Text>
            <C.Widget>
              <object
                data="https://widgets.rentcars.com/widget-v4.html?requestor=4376&locale=pt-br&utm_source=redsterna.com.br&utm_medium=afiliado-widget"
                width="100%"
                height="100%"
              ></object>
            </C.Widget>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SubTitle>
                Quais são os requisitos mínimos para aluguel de carros?
              </C.SubTitle>
              <C.Divider />

              <C.Text>
                Existem algumas regras diferentes para aluguel de carros no
                Brasil e no exterior. No Brasil o locatário precisar ter 21
                anos, carteira nacional de habilitação (CNH) válida e emitida há
                2 anos (CNH definitiva) e cartão de crédito com limite
                suficiente para o bloqueio do caução, também chamado de
                pré-autorização. Já no exterior, a idade mínima é de 25 anos e,
                além da CNH, é necessário apresentar o passaporte e, em alguns
                países, a permissão internacional para dirigir (PID). O cartão
                de crédito para caução precisa ser internacional. Países que
                exigem a permissão internacional para dirigir: Áustria, Grécia,
                Itália e em alguns países da África, Oriente Médio e da Oceania
                o documento costuma ser exigido, portanto, não recomendamos
                viajar sem.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>

          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Como funciona o caução no aluguel de carros?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                O bloqueio caução ou pré-autorização é um valor retido no cartão
                de crédito do locatário para a segurança da locadora. Esta
                quantia, que é determinada pela própria locadora, não é cobrada
                como uma compra comum, mas sim bloqueada do limite do cartão de
                crédito. Essa é uma exigência das locadoras, que fazem o
                bloqueio no momento da retira do veículo alugado. Mas pode ficar
                tranquilo! O valor é desbloqueado após a vistoria e entrega do
                carro nas mesmas condições de retirada.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    },
    seguro: {
      title_1: 'Seguro',
      title_2: 'Viagem',
      image: '/images/dicas/seguro.jpeg',
      description: (
        <C.Article className="col-xs-12 travel-tip-body">
          <C.Text>Tudo o que você precisa saber sobre despesas médicas!</C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.LignDivider src="/images/tips-lign.png" alt="" />

              <C.SecondTitle>
                Por que fazer o seguro viagem com a Redsterna?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                {' '}
                Antes de fazer uma viagem é importante tomar alguns cuidados.
                Para não ter dores de cabeça no futuro. Por isso, o mais
                indicado é contratar um seguro viagem que te deixa tranquilo
                quanto alguns problemas. Além disso, em alguns países como os da
                União Europeia ele é obrigatório. Mas afinal, você sabe como
                funciona a cobertura de um seguro viagem? Sabe quais despesas
                médicas estão inclusas? Se você não sabe ou ainda tem alguma
                dúvida, fique tranquilo, neste post vamos te explicar melhor
                como funciona o seguro viagem.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                O que é a cobertura de despesas médicas hospitalares no seguro
                viagem?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                A cobertura para despesas médicas hospitalares consiste no valor
                disponibilizado para eventualidades médicas de acordo com o
                plano contratado. Essa cobertura garante o reembolso de despesas
                médicas emergenciais ou para o tratamento imediato decorrido de
                acidente pessoal ou doença aguda contraída durante a viagem.{' '}
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Como acionar a cobertura de despesas médicas hospitalares?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Em caso de alguma eventualidade, o segurado deverá entrar em
                contato com a seguradora através dos telefones ou canais de
                atendimento disponibilizados na apólice. A ligação pode ser
                feita à cobrar (Collect Call) de qualquer telefone fixo no
                exterior. Algumas seguradoras oferecem o atendimento até mesmo
                por Skype, Whatsapp ou aplicativo próprio. Ao entrar em contato,
                o segurado deve informar a necessidade do atendimento e, em
                seguida, a seguradora o encaminhará ao hospital e/ ou rede
                credenciada mais próxima.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Como é realizada o pagamento das despesas médicas hospitalares?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                O pagamento das despesas médicas pode ser realizado de duas
                formas:
              </C.Text>
              <C.Text>
                Através da seguradora: neste caso, o pagamento das despesas
                médicas será feito pela seguradora diretamente com o hospital
                e/ou rede credenciada;
              </C.Text>
              <C.Divider />

              <C.Text>
                Através do reembolso das despesas: neste caso, o segurado arca
                com as despesas médicas em um primeiro momento e depois solicita
                o reembolso à seguradora, mediante toda documentação apresentada
                e de acordo com as condições gerais das mesmas.
              </C.Text>
              <C.Divider />
              <C.Text>
                Fique atento: É de extrema importância que o segurado solicite
                ao hospital, no momento da sua saída, o relatório médico da sua
                internação e/ou atendimento, tanto para o acionamento da
                cobertura médica quanto para o reembolso. Esse relatório deve
                conter o motivo pelo qual ele foi atendido e todos os
                procedimentos que foram realizados, bem como a relação de
                valores gastos.{' '}
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Encontre o melhor seguro para sua viagem!
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Vai fazer uma viagem internacional e não sabe qual seguro
                adquirir? Nós temos a melhor solução para você! Pensando nisso
                nos afiliamos a uma das maiores plataformas de seguro viagem do
                Brasil, a Seguros Promo. Basta clicar no link abaixo e
                aproveitar o cupom de 5% de desconto
              </C.Text>
              <iframe
                src="https://www.segurospromo.com.br/site/banner/redsterna/113?tt=banner113"
                style={{
                  height: 350,
                  width: 300,
                  order: 'none',
                  padding: 0,
                  margin: 20,
                  overflow: 'hidden'
                }}
              ></iframe>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>O que é seguro viagem?</C.SecondTitle>
              <C.Divider />
              <C.Text>
                O seguro viagem é o serviço que vai certificar a segurança e
                tranquilidade da sua viagem. Ele garante ao viajante o amparo
                necessário em qualquer situação de emergência, oferecendo
                assistência em caso de eventualidades. Assim, dentro do valor
                das coberturas, o segurado estará coberto de diversas situações
                como consultas médicas hospitalares, assistência jurídica,
                extravio de bagagem e até cancelamento de voos.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Como faço para escolher o seguro ideal para minha viagem?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Na hora de escolher o seguro viagem, contrate o que atenderá
                melhor às necessidades da sua viagem. O destino, a duração da
                viagem, o roteiro e a idade dos viajantes são alguns fatores que
                influenciam essa decisão. Se, por exemplo, você estiver viajando
                durante uma gestação ou for praticar esportes, contrate o plano
                de seguro viagem que apresente coberturas específicas para a sua
                viagem.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Por que contratar com o Seguros Promo?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Uma das maiores vantagens de contratar com o Seguros Promo é a
                possibilidade de comparar planos de seguros diferentes. Dessa
                forma, você garante o melhor valor e a melhor cobertura para sua
                viagem. Além disso, contam com profissionais capacitados para
                esclarecer quaisquer eventuais dúvidas que possam surgir no
                momento da pesquisa ou compra. Você pode entrar em contato com o
                Seguros Promo via telefone, e-mail, chat e whatsapp. Eles
                garantem também, total segurança no momento da compra. Dados,
                como número de cartão e senha, são criptografados e permanecem
                particulares em todo o tempo.
              </C.Text>
              <C.Divider />
              <a
                target="_blank"
                href="https://www.segurospromo.com.br/?utm_medium=afiliado&amp;utm_source=banner&amp;pcrid=4662&amp;pcrtt=parceiros_banner99"
                rel="noreferrer"
              >
                <img
                  alt="Seguros Promo"
                  width="300"
                  height="250"
                  style={{ height: '250px', width: '300px' }}
                  src="https://static.parceirospromo.com.br/segurospromo/banners/79349796-de30-902e-6e7e-dea0d57ba90b/banner_institucional_300x250.png"
                />
              </a>
            </C.SubTitleWrapper>
          </C.CardText>
          ={' '}
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Vale a pena utilizar apenas o seguro de viagem do cartão de
                crédito?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                O benefício oferecido por algumas categorias de cartões pode até
                parecer economia, mas a cobertura disponibilizada pode ser
                insuficiente para a viagem planejada. Nas principais empresas do
                mercado - como Visa, Mastercard, American Express - esse serviço
                está disponível apenas para portadores das bandeiras superiores,
                como platinum e black.
              </C.Text>
              <C.Divider />

              <C.Text>
                Além disso, para ter direito ao seguro, a compra da passagem
                deve ter sido realizada pelo cartão de crédito e a viagem deve
                ter duração máxima de 30 dias. Quem viaja para fazer intercâmbio
                ou mochilão terá que contratar um seguro viagem direto da
                seguradora. Práticas de esportes e complicações na gravidez
                também não costumam ser cobertas.
              </C.Text>
              <C.Divider />

              <a
                target="_blank"
                href="https://www.segurospromo.com.br/?utm_medium=afiliado&amp;utm_source=banner&amp;pcrid=4662&amp;pcrtt=parceiros_banner91"
                rel="noreferrer"
              >
                <img
                  alt="Seguros Promo"
                  width="300"
                  height="250"
                  style={{ height: '250px', width: '300px' }}
                  src="https://static.parceirospromo.com.br/segurospromo/banners/4d1c1c09-3f27-bf47-e3f5-f02e040a4dee/banner_compareplanos_300x250.png"
                />
              </a>
              <C.Divider />
              <C.Text>
                Por isso, mesmo tendo direito a esse benefício, é preciso estar
                bastante atento a cobertura. O ideal é sempre ligar para a
                central do cartão, avisar que vai viajar e solicitar as
                condições gerais da cobertura de do seguro viagem
                disponibilizada.
              </C.Text>
              <C.Divider />
              <C.Text>
                Lembre-se também de comparar o seguro das operadoras de cartão
                de crédito com os planos das seguradoras. Além de serem
                especializadas em seguros para viagem, essas empresas possuem
                planos mais completos e mais opções de cobertura.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    },
    planejamento: {
      title_1: 'Planejando sua',
      title_2: 'Viagem',
      image: '/images/dicas/planejamento.jpg',
      description: (
        <C.Article className="col-xs-12 travel-tip-body">
          <C.Text>
            Aqui você encontrará informações de como planejar sua viagem sem
            entrar numa fria!
          </C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.LignDivider src="/images/tips-lign.png" alt="" />

              <C.SecondTitle>
                Por que é necessário planejar sua viagem?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Planejar uma viagem é essencial para uma experiência
                bem-sucedida. Isso permite otimizar tempo e recursos,
                estabelecer um orçamento realista, personalizar a viagem de
                acordo com suas preferências, criar antecipação e garantir
                segurança. O planejamento oferece eficiência, economia,
                personalização, antecipação e tranquilidade, garantindo uma
                experiência de viagem memorável.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    },
    descontos: {
      title_1: 'Descontos em ',
      title_2: 'ingressos e passeios',
      image: '/images/dicas/descontos.jpeg',
      description: (
        <C.Article>
          <C.Text>
            Aqui você encontrará informações de como adquirir seus ingressos e
            passeios de sites renomados com descontos e dicas imperdíveis!
          </C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Por que devo conhecer os passeios Redsterna?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                <p>
                  Você também sente dificuldades em planejar sua viagem? Em
                  saber por exemplo quais são as principais atrações do local, o
                  custo dos passeios e shows que quer conhecer?{' '}
                </p>
                <p>
                  Pensando nisso nos afiliamos a maior plataforma de
                  entretenimento do Brasil e do mundo para que você se planeje e
                  adquira as melhores informações e promoções possíveis.
                </p>
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>Sobre a Viator</C.SecondTitle>
              <C.Divider />
              <C.Text>
                A Viator é uma empresa da Trip Advisor, o maior site de viagens
                do mundo e fornece ingressos pra diversas atrações no mundo
                inteiro. Possui sistema de cancelamento gratuito, para a maioria
                das experiências, você receberá um reembolso integral se
                cancelar com até 24 horas de antecedência.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>Comprando ingressos pela Viator</C.SecondTitle>
              <C.Divider />
              <C.Text>
                <p>
                  Aqui você encontrará informações da cidade como lugares para
                  conhecer, shows e eventos em geral além de é claro roteiros de
                  pessoas que já conheceram o local. Porém caso esta cidade
                  ainda não tenha esta informação, não se preocupe! Através do
                  link abaixo você poderá encontrar todas as atrações da cidade
                  desejada! O link te levará a Viator, uma empresa da Trip
                  Advisor que garante o melhor preço para atração desejada! Veja
                  um exemplo de passeis pelo Havaí!
                </p>
                <ViatorWidget />
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Caso a cidade desejada não possua dica ainda, você poderá clicar
                aqui nesse link e realizar sua pesquisa, siga o passo a passo
                abaixo:
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                <ViatorBanner />
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    },
    chip: {
      title_1: 'Chip',
      title_2: 'Internacional',
      image: '/images/dicas/chip.jpg',
      description: (
        <C.Article>
          <C.Text>
            Aqui você encontrará informações de como comprar seu chip
            internacional para usar no exterior.
          </C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                {' '}
                A melhor forma de adquirir um chip para viagem internacional
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                {' '}
                Vamos supor que você acabou de chegar em um país completamente
                desconhecido, acabou de sair pela sala de desembarque do
                aeroporto e está totalmente offline! Não seria muito mais fácil
                já sair conectado, poder se localizar, pedir seu uber e sair
                andando tranquilamente por aí?
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                A solução é mais fácil que você imagina!
              </C.SecondTitle>
              <C.Divider />
              <C.Text>Sobre a Viaje Conectado:</C.Text>
              <C.Text>
                A Viaje Conectado é uma empresa criada há mais de 30 anos com
                sede em Orlando EUA, que vende chip internacional para vários
                lugares do mundo. O chip internacional da Viaje Conectado é
                prático de comprar e usar. É uma opção para já sair do Brasil
                com tudo funcionando e chegar lá com internet habilitada e eles
                entregam na sua casa.
              </C.Text>
              <C.Divider />

              {/* <a
                href="https://viajeconectado.com/?ref=redsterna"
                target="_blank"
                rel="noreferrer"
              >
                <img src="//372978-1166811-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/336x280_3.jpg" />
              </a>
              <C.Divider /> */}
              <C.Text>
                {' '}
                Você compra o seu chip online, recebe em casa e já sai do Brasil
                com a certeza de que terá internet em qualquer um dos países que
                fazem parte da área de cobertura da empresa.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Quais são as maiores utilidades de você ter um chip
                internacional em sua viagem?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                <p> Utilizar o Whatsapp, Instagram e Facebook</p>
                <p> Chamar o Uber ao chegar no aeroporto</p>
                <p> Utilizar o google maps, google e e-mails</p>
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Quais os planos disponibilizados pela Viaje Conectado
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Os planos oferecidos pela empresa Viaje Conectado variam de
                acordo com seu destino. Veja cada um deles, o que oferecem e
                quanto custa para entender qual é o mais adequado para você.
              </C.Text>
              <C.Divider />
              <C.Text>
                Os pacotes de Dados é basicamente um plano de internet ilimitada
                que funciona em diversos países. Esse chip NÃO permite a
                realização de chamadas ou SMS, mas lembre-se que você pode fazer
                chamadas pelo WhatsApp!
              </C.Text>
              <C.Divider />
              <C.Text>
                Os pacotes de Dados é basicamente um plano de internet ilimitada
                que funciona em diversos países. Esse chip NÃO permite a
                realização de chamadas ou SMS, mas lembre-se que você pode fazer
                chamadas pelo WhatsApp!
              </C.Text>
              <C.Divider />
              <C.Text>
                Já o pacote de Voz e Dados, é válido apenas nos EUA e Europa e
                com ele você tem internet, ligações e SMS ilimitadas, podendo
                até ligar para o Brasil também sem pagar nada.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Onde eu compro o chip Viaje Conectado?
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                Para comprar o chip basta clicar neste link: e efetuar sua
                compra. É totalmente seguro e prático. Eles entregam em casa e
                você já sai do Brasil conectado para sua viagem!
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>Como comprar?</C.SecondTitle>
              <C.Divider />
              <C.Text>
                <h4>
                  <strong>1. Monte seu plano</strong>
                </h4>
                <p>
                  Clique na imagem abaixo, ela te direcionará até o site da
                  Viaje conectado, escolha o plano levando em conta as suas
                  necessidades
                </p>
                {/* <a
            href="https://viajeconectado.com/?ref=redsterna"
            target="_blank"
            rel="noreferrer"
          >
            <img src="//372978-1166811-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2020/02/300x250_4.jpg" />
          </a> */}
                <h4>
                  <strong>2. Escolha o local para onde irá viajar </strong>
                </h4>
                <p></p>
                <p>
                  Escolha qual lugar vai viajar: Europa, EUA, Canada, México ou
                  outros;
                </p>
                <h4>
                  <strong>
                    3. Selecione a quantidade de dias que irá precisar
                  </strong>
                </h4>
                <p></p>
                Selecione a quantidade de dias de acordo com a sua viagem e
                necessidade.
                <h4>
                  <strong>4. Clique em compre agora</strong>
                </h4>
                <h4>
                  <strong>5. Escolha o tipo de entrega</strong>
                </h4>
                <p></p>
                <p>
                  Dependendo do seu endereço, aparecerão diferentes opções de
                  frete. Escolha a que melhor funciona para você e clique em
                  ‘Prosseguir para pagamento’.
                </p>
                <h4>
                  <strong>6. Realizando o pagamento</strong>
                </h4>{' '}
                <p></p>
                <p>
                  Escolha as opções de pagamento: paypal, cartão de crédito
                  (note que para parcelar é preciso escolher cartão de crédito
                  parcelamento) ou pagamento com débito online. OBS: O cartão de
                  crédito deve estar autorizado para transações internacionais.
                  Os locais de retirada do chip Viaje Conectado são diversos
                  espalhados por todo Brasil. Várias agências de intercambio,
                  viagens e turismo estão na lista em diversas capitais,
                  interior de São Paulo, entre outras cidades. A seguir, estão
                  as informações de entrega fornecidas pelo site.
                </p>
                <h4>
                  <strong>
                    Qualquer dúvida para comprar ou problemas técnicos para usar
                    o chip, dá para entrar em contato com eles diretamente pelo
                    whatsapp ou chat online. O número do WhatsApp é +1 (407)
                    334-3633 e para o chat é só entrar no site deles
                  </strong>
                </h4>
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    },
    hospedagens: {
      title_1: 'Hospedagens -',
      title_2: 'Melhores preços',
      image: '/images/dicas/hospedagem.jpeg',
      description: (
        <C.Article>
          <C.Text>
            Aqui você encontrará informações de onde se hospedar com maior
            conforto!
          </C.Text>
          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Vai viajar e não sabe onde ficar? Vamos te ajudar!
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                <p>
                  {' '}
                  Antes de realizar uma hospedagem seja em um hotel ou em um
                  hostel, é preciso ter em mente o que você vai precisar. Por
                  exemplo, se está fazendo um mochilão na europa e não precisará
                  em momento algum de atender uma vídeo chamada com seu chefe,
                  então você não precisa se preocupar muito com a infraestrutura
                  de internet local podendo ser apenas um wifi básico para
                  utilizar o whatsapp e pesquisar lugares de interesse. Pensando
                  em exemplos desse tipo listamos os principais pontos que
                  deverão ser levados em consideração antes de definir onde vai
                  ficar em sua viagem:
                </p>
                {/* <ins
                  className="bookingaff"
                  data-aid="2110244"
                  data-target_aid="2110244"
                  data-prod="dfl2"
                  data-width="300"
                  data-height="350"
                  data-lang="xb"
                  data-currency="BRL"
                  data-df_num_properties="3"
                  data-bk-touched="true"
                >
                  <iframe
                    src="//www.booking.com/flexiproduct.html?product=dfl2&amp;w=300&amp;h=350&amp;lang=xb&amp;aid=2110244&amp;target_aid=2110244&amp;selected_currency=BRL&amp;df_num_properties=3&amp;fid=1662861827351&amp;"
                    style={{
                      height: 350,
                      width: 300,
                      order: "none",
                      padding: 0,
                      margin: 0,
                      overflow: "hidden",
                    }}
                    id="booking_widget__2110244__1662861827351"
                    data-responsive="false"
                  ></iframe>
                </ins> */}
              </C.Text>
              <C.Divider />

              <C.Text>Localização</C.Text>
              <C.Text>
                O ideal é sempre que você fique mais próximo dos lugares de
                interesse, dos passeios que você irá realizar. Se isso não for
                possível, procure escolher um local próximo a avenida de fácil
                acesso aos seus lugares de interesse pois assim você economizará
                tempo e dinheiro uber ou transporte público até o local de
                interesse.
              </C.Text>
              <C.Divider />

              <C.Text>Infraestrutura</C.Text>
              <C.Text>
                Como já comentando anteriormente, se você está fazendo uma
                viagem de negócios ou precisará atendar vídeo chamadas,
                atente-se a internet e locais onde poderá levar seu computador
                ou celular e atender com tranquilidade e pouco barulho.
              </C.Text>

              <C.Divider />

              <C.Text>Conforto</C.Text>
              <C.Text>
                Se você é uma pessoa que não liga muito para conforto e não se
                incomoda em compartilhar o quarto e está com o orçamento
                apertado, um hostel pode ser uma ótima opção. O Airbnb também
                poderá te oferecer opções de casa compartilhada, onde você
                poderá alugar um quarto ao invés de todo local, podendo assim
                economizar bastante em sua viagem.
              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText>

          <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>
                Recomendações de hospedagem e segurança na contratação
              </C.SecondTitle>
              <C.Divider />
              <C.Text>
                No Redsterna você pode ler relatos através dos roteiros dos
                viajantes. Além disso as hospedagens possuem uma avaliação de 1
                a 5 estrelas onde nossos viajantes poderão emitir sua opinião
                sobre os locais onde ficaram
              </C.Text>

              <C.Divider />
              <C.Text>
                Em cada cidade selecionada no mapa você poderá utilizar o mapa
                interativo do Booking para olhar localização e preços. Caso a
                cidade desejada não possua esta opção, você poderá{' '}
                <strong>Utilizar o simulador do booking abaixo</strong> e ir
                direto para o Booking pesquisar o local desejado.
              </C.Text>
              <C.Divider />

              <ins
                className="bookingaff"
                data-aid="2110245"
                data-target_aid="2110245"
                data-prod="banner"
                data-width="300"
                data-height="250"
                data-lang="xb"
                data-bk-touched="true"
              >
                <iframe
                  src="//www.booking.com/flexiproduct.html?product=banner&amp;w=300&amp;h=250&amp;lang=xb&amp;aid=2110245&amp;target_aid=2110245&amp;tmpl=affiliate_banner&amp;fid=1662861827352&amp;"
                  style={{
                    height: 260,
                    width: 305,
                    order: 'none',
                    padding: 0,
                    margin: 0,
                    overflow: 'hidden'
                  }}
                  id="booking_widget__2110245__1662861827352"
                  data-responsive="true"
                ></iframe>
              </ins>
            </C.SubTitleWrapper>
          </C.CardText>
        </C.Article>
      )
    }
  }

  const content = page[name]

  return (
    <C.Container>
      <C.Section>
        <C.ImageContainer>
          <C.Image src={content.image} />
        </C.ImageContainer>
        <C.HeaderTitle>
          <span>{content.title_1}</span> <span>{content.title_2}</span>
        </C.HeaderTitle>
        {content.description}
      </C.Section>
    </C.Container>
  )
}

export default SuggestionPage

{
  /* <C.CardText>
            <C.SubTitleWrapper>
              <C.SecondTitle>

              </C.SecondTitle>
              <C.Divider/>
              <C.Text>

              </C.Text>
            </C.SubTitleWrapper>
          </C.CardText> */
}
