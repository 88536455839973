import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useItineraries } from 'contexts/itinerary.context'

const Ficha = () => {
  // const [cityName, setCityName] = useState<any>([]);
  //const [description, setDescription] = useState<any>([]);
  const { getAllItineraries, setItineraries, itineraries } = useItineraries()

  const { data } = useQuery({
    queryKey: ['AllItineraries'],
    queryFn: getAllItineraries,
    staleTime: 2 * 60 * 1000
  })

  useEffect(() => {
    setItineraries(data as never[])
  }, [data, setItineraries])

  return (
    <div>
      {itineraries?.map((itinerary: any) => {
        return itinerary.cities.map(
          (city: {
            coordinates: { latitude: number; longitude: number }
            id: string
            name: string
          }) => <div key={city.id}>{city.id && <h2> {city.name} </h2>}</div>
        )
      })}
    </div>
  )
}

export default Ficha
