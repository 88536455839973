import { Link } from 'react-router-dom'

import * as C from './styles'

const Suggestions = () => {
  const cardprops = [
    {
      title: 'Seguro Viagem',
      image: '/images/dicas/seguro.jpeg',
      action: '/dicas-de-viagem/seguro'
    },
    {
      title: 'Aluguel de Carro',
      image: '/images/dicas/aluguel.jpeg',
      action: '/dicas-de-viagem/aluguel'
    },
    {
      title: 'Chip Internacional',
      image: '/images/dicas/chip.jpg',
      action: '/dicas-de-viagem/chip'
    },

    {
      title: 'Planejamento',
      description:
        'Aqui você encontrará informações de como planejar sua viagem sem entrar numa fria! Dicas e...',
      image: '/images/dicas/planejamento.jpg',
      action: '/dicas-de-viagem/planejamento'
    },
    {
      title: 'Descontos',
      image: '/images/dicas/descontos.jpeg',
      action: '/dicas-de-viagem/descontos'
    },

    {
      title: 'Hospedagens',
      image: '/images/dicas/hospedagem.jpeg',
      action: 'dicas-de-viagem/hospedagens'
    }
  ]

  const Card = (props: any) => (
    <Link to={props.action}>
      <C.Title>{props.title}</C.Title>
      <C.Card>
        <C.Image src={props.image} />
      </C.Card>
    </Link>
  )

  return (
    <C.Container>
      <C.HeaderTitle>
        <span>Dicas de</span> <span>Viagem</span>
      </C.HeaderTitle>
      <C.Description>
        Viaje de forma prática e segura, nós facilitamos tudo para você!
      </C.Description>
      <C.Section>
        {cardprops.map((props, index) => (
          <Card {...props} key={index} />
        ))}
      </C.Section>
    </C.Container>
  )
}

export default Suggestions
