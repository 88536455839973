import styled from 'styled-components'

export const CoverCreateItinerary = styled.div`
  display: flex;
  height: 650px;

  @media (max-width: 600px) {
    height: 300px;
  }
`

export const CoverIcon = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`

export const CoverImage = styled.img`
  height: 350px;
  object-fit: cover;
  width: 600px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    height: 200px;
    width: 100%;
  }
`

export const RowContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const RowContainerOne = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`

export const ColumnContainer = styled.div`
  flex: 0 0 33%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const FollowIcon = styled.img`
  display: flex;

  margin-left: 10px;
`

export const ContainerCreateItinerary = styled.div`
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Title = styled.span`
  margin-top: 10px;
  margin-bottom: 10px;

  color: #4f4f4f;
  font-size: 18px;
  font-weight: bold;
`

export const InformationText = styled.span`
  width: 50%;

  margin-bottom: 10px;

  color: #a9a9a9;
  font-size: 10px;
`

export const Text = styled.span`
  width: 50%;

  margin-bottom: 10px;

  font-size: 14px;
  font-weight: 300;
`

export const Divider = styled.hr`
  width: 360px;
  height: 1px;

  margin: 10px 0 20px;

  color: #a9a9a9;
`

export const TitleContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Link = styled.span`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
  color: #337ab7;
`

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 10px;

  color: #4f4f4f;
`

export const Label = styled.label`
  margin-bottom: 10px;
  margin-top: 10px;

  color: #343434;
  font-size: 14px;
  font-weight: 600;

  & span {
    color: #d60000;
    font-size: 14px;
  }
`

export const Input = styled.input`
  width: 400px;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  padding-left: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  outline-color: #b22222;

  &::placeholder {
    font-size: 14px;
    color: #a9a9a9;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const MonthInput = styled.input`
  width: 200px;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  outline-color: #b22222;
  box-sizing: border-box;
  padding-left: 10px;

  &::placeholder {
    font-size: 16px;
  }
`

export const UploadInput = styled.input`
  width: 200px;
  height: 40px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  outline-color: #b22222;

  &::placeholder {
    font-size: 16px;
  }
`

export const PainelCreateItinerary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &.hidden {
    display: none;
  }
`

export const ContentCreateItinerary = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
`

export const TextArea = styled.textarea`
  width: 500px;
  height: 200px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  outline-color: #b22222;
  margin-bottom: 20px;

  &::placeholder {
    font-size: 14px;
    padding-left: 10px;
    padding-top: 10px;
    color: #a9a9a9;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Select = styled.select`
  width: 300px;
  height: 30px;
  color: #000;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  border-radius: 5px;
`

export const Upload = styled.input`
  width: 300px;
  border-radius: 5px;
  height: 30px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FileInput = styled.input`
  padding-bottom: 20px;
  font-size: 0;
  ::-webkit-file-upload-button {
    visibility: hidden;
  }
  ::after {
    visibility: hidden;
  }
  ::before {
    content: 'Escolha a capa da sua viagem';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  :hover::before {
    border-color: #343434;
  }
  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`

export const CheckboxLabel = styled.label`
  font-size: 14px;
  margin-top: 10px;
  color: #4f4f4f;
  font-weight: 600;
`

export const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  margin: 0 auto;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 10px;
`

export const FormCreateItinerary = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FormContainerCreateItinerary = styled.div`
  padding: 20px;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const RadioButton = styled.button`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 100%;
`

export const OptionsCreateItinerary = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`

export const ScriptCreateItinerary = styled.div`
  display: flex;
  flex-direction: column;
`

export const OptionCreateItinerary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  & input,
  & label {
    margin: 0;
    padding: 0;
  }
`
