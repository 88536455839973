import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  @media (max-width: 508px) {
    width: 100%;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const ImageDiv = styled.div`
  height: 400px;
  width: 400px;
  @media (max-width: 508px) {
    height: 300px;
    width: 300px;
  }
`

export const Title = styled.div`
  font-size: 32px;
  color: #343434;
  font-family: 'Baloo 2', cursive;
`

export const Description = styled.div`
  font-size: 20px;
  color: #454545;
  font-family: 'Baloo 2', cursive;
  @media (max-width: 508px) {
    width: 100%;
  }
`

export const Button = styled.button`
  width: 200px;
  height: 40px;
  padding: 5px;
  margin-top: 20px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  background-color: #e90000;
`
