import React, { useState } from 'react'

import PageContainer from 'components/page-container'
import RegisterForm from 'components/registerForm'

import { Description, Divider, Title, TitleContainer } from './style'

const Register = () => {
  const [step, setStep] = useState(2)

  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  return (
    <PageContainer>
      <TitleContainer>
        <Title>Cadastre-se</Title>
        <Description>Novo usuário Redsterna</Description>
      </TitleContainer>

      <Divider />

      <RegisterForm
        step={step}
        setStep={setStep}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </PageContainer>
  )
}

export default Register
