import { AiFillHeart } from 'react-icons/ai'
import { Link } from 'react-router-dom'

import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: 'Baloo 2';
  @media (max-width: 948px) {
    width: 98%;
  }
`

export const Image = styled.div<{ cover?: string }>`
  background-image: url(${(props) => props.cover});
  width: 50px;
  height: 50px;
  background-size: contain;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
`

export const List = styled.div`
  width: 100%;
  flex-grow: 1;
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Line = styled.div<{ fullWidth?: boolean }>`
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const Title = styled.h4`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: white;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 300px;
  flex-grow: 1;
  margin-bottom: 10px;
`

export const Heart = styled(AiFillHeart)`
  cursor: pointer;
  transition: color 200ms;

  @media (max-width: 948px) {
    height: 30px;
    width: 30px;
  }
`
type HeaderProps = { cover?: string }

export const Header = styled.div<HeaderProps>`
  background-image: url(${({ cover }) =>
    cover && cover !== ''
      ? cover
      : '/images/desktop/home/redsterna_gray_logo.png'});
  background-size: ${({ cover }) => (cover ? 'cover' : '')};
  background-repeat: no-repeat;
  background-position: center;

  width: 100%;
  min-height: 500px;

  color: white;
  font-size: 30px;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const ItineraryHeader = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: auto;
  padding: 20px 10px 20px 10px;
  box-shadow: 33px 4px 41px rgba(0, 0, 0, 0.1);
`

export const HeaderIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`

export const HeaderRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
`

export const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;

  @media (max-width: 948px) {
    flex-direction: column;
  }
`

export const Tittle = styled.div`
  font-size: 24px;
  font-weight: 500;
  @media (max-width: 948px) {
    font-size: 18px;
  }
`

export const AuthorName = styled(Link)``

export const InfosWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;

    align-items: center;
  }
`

export const InfoCard = styled.div`
  display: flex;
  box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: fit-content;
  background-color: #ffffff;
  align-items: center;
  padding: 5px;
  margin-top: 10px;

  justify-content: space-around;
  @media (max-width: 500px) {
  }
`

export const InfoText = styled.div`
  font-size: 20px;
  margin-left: 10px;
  @media (max-width: 948px) {
    font-size: 16px;
  }
`

export const BoldText = styled.div`
  font-size: 20px;
  margin-left: 10px;
  font-weight: bold;
  @media (max-width: 948px) {
    font-size: 16px;
  }
`

export const InfoIcon = styled.img`
  height: 35px;
  width: 35px;
  @media (max-width: 948px) {
    height: 25px;
    width: 25px;
  }
`

export const SummaryTitle = styled.div`
  font-size: 24px;
  margin: 0 10px 0 10px;
  font-weight: 400;
  text-align: center;

  & span:first-child {
    color: #373737;
  }

  & span:last-child {
    color: #c62a31;
  }
`

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 20px;
`

export const DescriptionCard = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  height: auto;
`

export const CheckInTittle = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  height: auto;
  margin-top: 40px;
`

export const CityCard = styled.div`
  display: flex;
  box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: fit-content;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  align-items: center;
  padding: 5px;
  justify-content: space-around;
  border-left: 10px solid #c62a31;
  align-self: flex-start;
  margin-top: 40px;
`

export const CityIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
`

export const HotelCard = styled.div`
  display: flex;
  box-shadow: -3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: fit-content;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 20px;
  align-items: center;
  padding: 5px;
  justify-content: space-around;
  align-self: flex-start;
  margin-top: 40px;
  border: 1px solid #e0e0e0;
`
export const HotelName = styled.div`
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 400;
`

export const SummaryDescription = styled.div`
  width: 95%;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  height: 200px;
  overflow-y: scroll;
  padding: 20px 50px 20px 50px;

  margin-top: 20px;
  color: #454545;
`

export const DescriptionText = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
`

export const Photos = styled.img`
  width: 450px;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
  @media (max-width: 700px) {
    width: 100%;
    height: 250px;

    margin-right: 0;
  }
`

export const Row = styled.div`
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: flex-end;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0 20px 0;

  padding-left: 40px;
  @media (max-width: 700px) {
    width: auto;
    padding-left: 0;
    justify-content: center;
  }
`

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 640px) {
    margin-right: 0;
  }
`

export const LightboxButton = styled.button`
  height: fit-content;
  background-color: white;
  border: 1px solid #343434;
  width: fit-content;
  padding: 5px 10px 5px 10px;
  border: none;
  border-radius: 5px;
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const LightboxWrapper = styled.button`
  background: unset;
  border: none;
  height: auto;
  cursor: pointer;

  width: auto;
  @media (max-width: 700px) {
    width: 370px;
    height: 250px;
  }
`
