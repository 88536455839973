import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'https://api.redsterna.com/'
})

api.interceptors.request.use((config: any) => {
  try {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  } catch (err) {
    console.error(err)
  }

  return config
})

export default api
