import { useState, useEffect, useCallback } from 'react'
import Modal from 'react-modal'

import axios from 'axios'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Navigation } from 'swiper'

import api from 'services/api'

import { ModelCity } from 'models/ModelCity'
import { ModelItineraries } from 'models/ModelItineraries'

import Cambio from './getCurrency'
import GoogleMapsImage from './googleMapsImage'
import { getButtonAffiliateLinks } from './links'
import * as S from './style'

type CityModalProps = {
  isOpen: boolean
  closeModal: () => void
  city: ModelCity
}

const CityModal = ({
  isOpen,
  closeModal,
  city: { name, latitude, longitude }
}: CityModalProps) => {
  const [cityDescription, setCityDescription] = useState('')
  const [temperature, setTemperature] = useState<number | null>()
  const [time, setTime] = useState<Date>(new Date())
  const [itineraries, setItineraries] =
    useState<Array<ModelItineraries> | null>()

  const getClimate = useCallback(async () => {
    const CLIMATE_KEY = '49a6620df221ca8bea432b575bed29e3'
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${CLIMATE_KEY}`
    )
    const tempFixed = window.Math.round(response.data.main.temp - 273.15)

    const timezone = response.data.timezone * 1000
    const date = new Date()
    const localTime = new Date(
      date.getTime() + timezone + date.getTimezoneOffset() * 60 * 1000
    )

    setTime(localTime)
    setTemperature(tempFixed)
  }, [latitude, longitude])

  const getItinerariesByCoordinate = useCallback(async () => {
    const { data } = await api.get(
      `/itineraries/coordinates?latitude=${latitude}&longitude=${longitude}`
    )

    setItineraries(data)
  }, [latitude, longitude])

  useEffect(() => {
    getDescriptionByCoordinate(latitude, longitude)
    getItinerariesByCoordinate()
    getClimate()
  }, [getClimate, getItinerariesByCoordinate, latitude, longitude])

  const getDescriptionByCoordinate = async (
    latitude: number,
    longitude: number
  ) => {
    const wikiApiUrl = `https://pt.wikipedia.org/w/api.php`

    const result = await axios.get(wikiApiUrl, {
      params: {
        action: 'query',
        list: 'geosearch',
        gsradius: '1000',
        gscoord: `${latitude}|${longitude}`,
        format: 'json',
        origin: '*'
      }
    })

    const { pageid } = result.data.query.geosearch[0]

    const descriptionQueryResult = await axios.get(wikiApiUrl, {
      params: {
        action: 'query',
        prop: 'extracts',
        exintro: true,
        explaintext: true,
        redirects: 1,
        pageids: pageid,
        format: 'json',
        origin: '*'
      }
    })

    const description = descriptionQueryResult.data.query.pages[pageid].extract
    setCityDescription(description)
  }

  const isMobile = window.innerWidth < 800

  const customStyles = {
    overlay: {
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: isMobile ? '100%' : '80%',
      height: isMobile ? '95%' : '85%',
      padding: 0,
      overflow: 'auto'
    }
  }

  const linkInfo = getButtonAffiliateLinks

  const mediaQuery = window.matchMedia('(max-width: 768px)')

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
      <S.Wrapper>
        <S.BannerContainer>
          <S.BannerWrapper>
            <S.RowContainer>
              <S.CloseButton onClick={closeModal}>
                <img
                  src="/images/close_icon.png"
                  alt="close_icon"
                  height={isMobile ? 10 : 20}
                  width={isMobile ? 10 : 20}
                />
              </S.CloseButton>
            </S.RowContainer>
            <GoogleMapsImage name={name}>
              <S.WrapperInfos>
                <S.TitleContainer>
                  <S.Title>{name}</S.Title>
                </S.TitleContainer>

                <S.InfoContainer>
                  <S.Cambio>
                    <img
                      src="/images/currency.svg"
                      height={isMobile ? 30 : 35}
                      width={isMobile ? 30 : 35}
                      alt="money icon"
                    />
                    <Cambio
                      latitude={Number(latitude)}
                      longitude={Number(longitude)}
                    />
                  </S.Cambio>
                  <S.Timezone>
                    <S.InfoText>
                      {time ? (
                        <S.InfoText>
                          <S.Text> HORÁRIO ATUAL:</S.Text>
                          <S.BoldText>
                            {format(time, 'eeee, HH:mm', { locale: ptBR })}
                          </S.BoldText>
                        </S.InfoText>
                      ) : (
                        <S.InfoText>Horário indisponível.</S.InfoText>
                      )}
                    </S.InfoText>
                  </S.Timezone>
                  <S.Temperature>
                    <img
                      src="/images/temperature.svg "
                      height={isMobile ? 30 : 40}
                      width={isMobile ? 30 : 40}
                      alt="temperature icon"
                    />

                    <S.InfoText>
                      {temperature ? (
                        <S.InfoText>
                          <S.Text>Temperatura: </S.Text>
                          <S.BoldText>{temperature}°C</S.BoldText>
                        </S.InfoText>
                      ) : (
                        <S.Text>Carregando a temperatura...</S.Text>
                      )}
                    </S.InfoText>
                  </S.Temperature>
                </S.InfoContainer>
              </S.WrapperInfos>
            </GoogleMapsImage>
          </S.BannerWrapper>
        </S.BannerContainer>

        <S.BodyContainer>
          <S.Description>
            {cityDescription ??
              `Bem-vindos à cidade de ${name}, um destino turístico fascinante que oferece uma ampla variedade de atividades e atrações para todos os gostos e idades. Nossa cidade é conhecida por sua beleza natural, arquitetura histórica, gastronomia saborosa e hospitalidade calorosa. Ao visitar esse destino, você terá a oportunidade de explorar as muitas atrações e maravilhas que nossa cidade tem a oferecer. Você pode começar sua jornada explorando nossos monumentos históricos, museus e galerias de arte, que apresentam exposições impressionantes artefatos e obras de arte.  Venha descobrir tudo o que este local  tem a oferecer. Com paisagens deslumbrantes e uma atmosfera acolhedora, estamos ansiosos para recebê-lo em nossa cidade e fazer da sua estadia uma experiência inesquecível. Veja nossos roteiros e marque sua viagem por aqui mesmo!`}
          </S.Description>

          <S.ItineraryTittle>Melhores roteiros</S.ItineraryTittle>
          <S.SwiperDiv
            spaceBetween={mediaQuery.matches ? 10 : 20}
            slidesPerView={mediaQuery.matches ? 2 : 4}
            navigation={true}
            modules={[Navigation]}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {itineraries?.map(({ coverImage, id, title, author }) => {
              return (
                <S.Slide key={id}>
                  <S.Anchor
                    to={{
                      pathname: `/roteiro/${id}`
                    }}
                    src={!coverImage ? '/images/default-photo.png' : coverImage}
                  >
                    {title}
                  </S.Anchor>
                  <S.Author>{author?.firstname}</S.Author>
                </S.Slide>
              )
            })}

            <S.SwiperFooter>
              <S.RedirectButton
                to={{
                  pathname: `/destinos`,
                  search: `?city=${name.split(',')[0]}`
                }}
              >
                Ver mais
              </S.RedirectButton>
            </S.SwiperFooter>
          </S.SwiperDiv>

          <S.Row>
            <S.Tittle>
              <span>Confira nossas dicas de</span>{' '}
              <span>Viagem, aluguéis de carro e mais</span>
            </S.Tittle>
          </S.Row>
          <S.ButtonsAffiliate>
            {linkInfo.map((item) => (
              <S.AffiliateDiv key={item.title}>
                <S.AffiliateLink href={item.url} target="_blank">
                  <S.ButtonIcon src={item.image} alt="ícone" />
                </S.AffiliateLink>
                <S.AffiliateTitle> {item.title}</S.AffiliateTitle>
              </S.AffiliateDiv>
            ))}
          </S.ButtonsAffiliate>
        </S.BodyContainer>
      </S.Wrapper>
    </Modal>
  )
}

export default CityModal
