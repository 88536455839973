import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 70px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 0 0 40px 0;

  @media (max-width: 600px) {
    margin-top: 50px;
    width: 100%;
  }
`

export const Tittle = styled.h1`
  margin: 5px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const Description = styled.p`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
  }
`
