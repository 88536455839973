import { ChangeEventHandler, FocusEventHandler } from 'react'

import { Field } from 'formik'

import Button from 'components/button'

import * as S from '../../style'

type PainelFaceProps = {
  handleChange: ChangeEventHandler<HTMLInputElement>
  handleBlur: FocusEventHandler<HTMLInputElement>
  values: any
  className: string
  nextStep: () => void
}

const PainelFace = ({
  handleChange,
  handleBlur,
  values,
  className,
  nextStep
}: PainelFaceProps) => (
  <S.PainelCreateItinerary className={className}>
    <S.ContainerColumn>
      <S.Label>
        TIPO DE <span>ROTEIRO</span>
      </S.Label>

      <S.OptionsCreateItinerary>
        <S.ScriptCreateItinerary>
          <S.OptionCreateItinerary>
            <Field
              as={S.Checkbox}
              id="made_feito"
              name="made"
              value="true"
              type="radio"
            />
            <S.Label htmlFor="made_feito">Feito</S.Label>
            <S.FollowIcon
              src="/images/desktop/itinerary/check.png"
              height={15}
              width={15}
            />
          </S.OptionCreateItinerary>
          <S.Description>Essa viagem já foi feita</S.Description>
        </S.ScriptCreateItinerary>

        <S.ScriptCreateItinerary>
          <S.OptionCreateItinerary>
            <Field
              as={S.Checkbox}
              id="made_planejado"
              name="made"
              value="false"
              type="radio"
            />
            <S.Label htmlFor="made_planejado">Planejado</S.Label>
            <S.FollowIcon
              src="/images/desktop/itinerary/send.png"
              height={15}
              width={15}
            />
          </S.OptionCreateItinerary>
          <S.Description>Essa viagem não foi feita</S.Description>
        </S.ScriptCreateItinerary>
      </S.OptionsCreateItinerary>
    </S.ContainerColumn>

    <S.ContainerColumn>
      <S.Label>
        TÍTULO DA <span>VIAGEM</span>
      </S.Label>
      <Field
        as={S.Input}
        name="title"
        type="text"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="ORLANDO/DISNEY - MIAMI - KEY WEST E NOVA YORK"
        value={values.title}
      />
    </S.ContainerColumn>

    <S.ContainerColumn>
      <S.Label>
        DATA DA <span>VIAGEM</span>
      </S.Label>
      <Field
        as={S.MonthInput}
        name="tripDate"
        type="date"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.tripDate}
      />
    </S.ContainerColumn>

    <S.ContainerColumn>
      <S.Label>
        DIAS DE <span>VIAGEM</span>
      </S.Label>
      <Field
        as={S.MonthInput}
        name="days"
        type="number"
        min="1"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="1"
      />
    </S.ContainerColumn>

    <S.ContainerColumn>
      <S.Label>CUSTO TOTAL POR PESSOA EM BRL (R$)</S.Label>
      <Field
        as={S.MonthInput}
        name="spent"
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        placeholder="R$ 0.0"
        step="0.01"
        min="0"
        value={values.spent}
      />
    </S.ContainerColumn>

    <S.ContentCreateItinerary>
      <Button type="button" onClick={nextStep}>
        <p>Continuar</p>
      </Button>
    </S.ContentCreateItinerary>
  </S.PainelCreateItinerary>
)

export default PainelFace
