import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-color: white;
  align-items: center;
  justify-content: center;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
`

export const Button = styled.button`
  border-radius: 5px;
  padding: 10px;
  border: none;
  background-color: #e90000;
  color: white;
  font-size: 18px;
  font-weight: bold;
`
