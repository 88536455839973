import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;

  width: 100%;
  height: 100%;
`

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }
`

export const DesktopWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  & > img {
    width: 50%;
    height: 100%;
    background-position: cover;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 55%;
  flex-grow: 1;

  padding: 0 60px;
  background-color: #ffffff;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const Banner = styled.div`
  width: 45%;

  @media (max-width: 800px) {
    width: 100%;
    height: 250px;
  }
`
export const MailWrapper = styled.div`
  width: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;

    text-align: center;
  }
`

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  background-position: cover;
`

export const Title = styled.span`
  font-size: 45px;
  margin-top: 10px;
  font-weight: 400;

  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }
`

export const SecondFormTitle = styled.span`
  font-size: 34px;
  font-weight: 400;

  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }

  @media (max-width: 800px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    align-items: center;
  }
`

export const ThirdFormTitle = styled.span`
  font-size: 24px;
  font-weight: 400;
  width: 60%;
  margin: 40px 0;
  text-align: center;
  font-weight: bold;

  & span:nth-of-type(1) {
    color: #343434;
    margin-right: 10px;
  }

  & span:nth-of-type(2) {
    color: #ec0000;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
`

export const Text = styled.span`
  font-size: 14px;
  width: 50%;
  margin-bottom: 10px;
  font-weight: 300;
`

export const MailButton = styled.button`
  display: flex;
  text-align: center;
  align-items: center;

  padding: 10px 20px 10px 20px;

  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  cursor: pointer;

  color: #454545;
  font-size: 20px;

  margin-bottom: 10px;
  border-radius: 6px;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`

export const MailIcon = styled.img`
  width: 35px;
  height: 20px;
  margin-right: 10px;
`

export const TitleContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const Link = styled.span`
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  color: #337ab7;
  font-size: 14px;
`
export const Button = styled.button`
  margin-top: 21;
  width: auto;
  padding: 10px 40px 10px 40px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  background-color: #ec0000;
  align-self: center;
  color: #ffffff;
  text-transform: uppercase;
  margin: 20px 0;
`

export const Description = styled.div`
  font-size: 38px;
  margin-bottom: 10px;
  color: #646464;
`

export const SecondDescription = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;

  color: #646464;
  font-size: 24px;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 20px;
  }
`

export const Anchor = styled.a`
  margin-left: 10px;

  color: #454545;
  font-size: 20px;
  line-height: 16px;
  text-decoration: underline;

  @media (max-width: 800px) {
    width: 100%;
    font-size: 16px;
  }
`
export const Label = styled.label`
  margin-bottom: 10px;
  padding-top: 10px;

  color: #4f4f4f;
  font-size: 16px;
`

export const ThirdLabel = styled.label`
  margin-bottom: 20px;
  padding-top: 10px;

  color: #454545;
  font-size: 20px;
  text-transform: uppercase;
`

export const GridLabel = styled.label`
  width: 50%;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  color: #4f4f4f;
  font-size: 16px;
  white-space: nowrap;
`

export const Errors = styled.span`
  color: #b22222;
  font-size: 12px;
`
export const Info = styled.span`
  color: #646464;
  font-size: 12px;
`

export const TextArea = styled.textarea`
  margin-bottom: 10px;
  width: 100%;
  height: 80px;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  resize: vertical;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Input = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  padding: 12px 6px 12px 6px;
  border-radius: 5px;
`

export const InputDate = styled.input`
  width: auto;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
`

export const Attention = styled.span`
  color: #b22222;
  font-size: 15px;
`
export const Select = styled.select`
  width: auto;
  height: 40px;

  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px 30px;

  color: #4f4f4f;
`

export const Upload = styled.input`
  width: 300px;
  height: 30px;

  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;
  margin-bottom: 20px;
`

export const CheckboxWrapper = styled.div`
  width: 50%;

  display: flex;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 30px;
  padding-top: 20px;
`

export const Checkbox = styled.input`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`
export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: 20px 40px 20px 40px;

  @media (max-width: 800px) {
    padding: 20px 40px 60px 40px;
  }
`

export const InputWrapper = styled.div`
  width: 85%;

  display: flex;
  flex-direction: column;
  align-items: start;
`

export const InputDateWrapper = styled.div`
  width: 85%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 5px;
`

export const GridItem = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  gap: 2px;
`

export const SocialWrapper = styled.div`
  width: 80%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const NextButton = styled.button`
  background-color: #e90000;
  cursor: pointer;

  width: auto;
  border: none;

  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;

  border-radius: 6px;
  margin-top: 30px;
  padding: 10px 60px;
`

export const ImageCircle = styled.div`
  width: 160px;
  height: 160px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin: 0 0 60px 0;
  border-radius: 50%;
  background-color: #b7b7b7;
  border: 1px solid #9c9c9c;
`

export const CoverDiv = styled.div`
  width: 90%;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: #b7b7b7;
  border: 1px solid #9c9c9c;

  @media (max-width: 800px) {
    width: 100%;
    height: 200px;
  }
`

export const LabelUpload = styled.label`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ProfileUploadLogo = styled.img`
  width: 80px;
  height: 80px;
`

export const AgreeWrapper = styled.div`
  width: 60%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    width: 100%;
  }
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 0;
  border: none;
  margin: 5px 5px 0 0;
  background-color: #f5f5f5;
`

export const CloseButtonIcon = styled.img`
  width: 30px;
  height: 30px;
`

export const LoginButton = styled.button`
  border: none;
  background: unset;
  cursor: pointer;
  font-size: 16px;
  color: #616161;
`
