import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-repeat: none;
  flex-direction: column;
  padding: 50px 0 50px 0;

  @media (max-width: 800px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    height: fit-content;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-transform: uppercase;
`

export const ContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`
export const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 0 40px 0 40px;

  width: 100%;
  @media (max-width: 800px) {
    padding: 0;
  }
`

export const MediaTittle = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  color: #343434;
  @media (max-width: 800px) {
    font-size: 12px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
`

export const SternaLogo = styled.img`
  height: 90px;
  width: 90px;
  @media (max-width: 800px) {
    height: 40px;
    width: auto;
  }
`

export const Image = styled.img`
  height: 40px;
  width: auto;
  @media (max-width: 800px) {
    height: 30px;
    width: auto;
  }
`

export const RowMedia = styled.div`
  display: flex;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const MediaContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Logo = styled.div`
  align-self: center;

  display: flex;
`

export const Ancor = styled.a`
  font-size: 14px;
  cursor: pointer;
  color: #337ab7;
`

export const SocialLogo = styled.a`
  display: flex;
  align-self: center;
  margin: 0 10px 0 10px;
  cursor: pointer;
`
export const CopyContainer = styled.div`
  display: flex;
  align-self: center;
`

export const Icons = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  transform: uppercase;
`

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 800px) {
    padding: 0 0 0 20px;
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  color: #343434;
  font-size: 16px;
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 10px;
  }

  @media (max-width: 948px) {
    display: none;
  }
`

export const LinkContact = styled.a`
  display: flex;
  padding: 5px;
  align-items: center;
  color: #343434;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 800px) {
    font-size: 10px;
  }

  @media (max-width: 948px) {
    display: none;
  }
`

export const Contact = styled.a`
  display: flex;
  align-items: center;
  color: #343434;
  font-size: 16px;
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 10px;
  }

  @media (max-width: 500px) {
  }
`

export const Text = styled.span`
  font-size: 14px;
  width: 50%;
  margin-bottom: 10px;
  font-weight: 300;
`

export const Copyright = styled(Text)`
  color: #ffffff;
  font-size: 12px;
  align-self: center;
  margin: 20px 0 20px 0;
  text-transform: none;
  width: 100%;
`
export const DesignCopyright = styled(Text)`
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin: 20px 0 20px 0;
  text-transform: none;
  width: 100%;
  padding: 0 60px 0 60px;
`
