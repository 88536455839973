import styled from 'styled-components'

export const Collapse = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-right: 10px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 10px;
  @media (max-width: 1048px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }
`

export const Button = styled.button`
  display: flex;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  background-color: unset;
  border: 1px solid #7c7c7c;
  font-family: 'Baloo 2';
  margin: 10px 0 10px;
  cursor: pointer;

  width: 180px;
  justify-content: space-between;

  @media (max-width: 948px) {
    width: auto;
    margin: 0;
  }
`

export const Icon = styled.img`
  height: 25px;
  width: 25px;
`
