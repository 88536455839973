import { Link } from 'react-router-dom'

import styled from 'styled-components'

import { styleButton } from 'components/button'

export const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 70px;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 50px;
  }
`

export const LinkTittle = styled(Link)`
  display: flex;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  & h4 {
    margin-left: 20px;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
    width: 180px;
    font-size: 12px;
    margin-right: 5px;
  }
`

export const FollowTag = styled.div`
  background-color: white;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 8px;
  color: #454545;
  margin-right: 50px;
  border: 1px solid #d4d4d4;
  @media (max-width: 1024px) {
    font-size: 12px;
    width: fit-content;
    margin-right: 10px;
  }

  @media (max-width: 700px) {
    font-size: 11px;
    padding: 10px 20px;
  }
`

export const CollapseTag = styled.div`
  width: auto;
  padding: 14px;
  height: auto;
  align-items: center;
  display: flex;
  border-radius: 0px 0px 9px 9px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
  border-width: 0 1px 1px 1px;
  font-size: 18px;
  background-color: white;
  margin-right: 30px;
  @media (max-width: 980px) {
    font-size: 16px;
    margin-right: 0;
  }

  @media (max-width: 764px) {
    width: 100%;
  }
`

export const CollapseButton = styled.button`
  width: 31px;
  height: 31px;

  margin-left: 10px;
  cursor: pointer;
  background: #fefefe;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 4px rgba(27, 24, 24, 0.25);

  border-radius: 100%;
`

export const Collapse = styled.div`
  height: fit-content;
  padding: 10px;
`

export const ProfileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 764px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 33px 4px 41px rgba(57, 57, 57, 0.05);
  @media (max-width: 764px) {
    justify-content: center;
    flex-direction: column;
  }
`
export const AddDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 764px) {
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
  }
`

export const AdditionalInfosMobile = styled.div`
  @media (max-width: 764px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const AdditionalInfos = styled.div`
  display: flex;
  padding-bottom: 10px;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;

  @media (max-width: 764px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const SocialTag = styled.div`
  width: auto;
  padding: 14px;

  height: 50px;
  align-items: center;
  display: flex;
  border-radius: 0px 0px 9px 9px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
  border-width: 0 1px 1px 1px;
  font-size: 18px;
  background-color: white;
  margin-right: 30px;
  @media (max-width: 980px) {
    font-size: 16px;
    flex-direction: column;

    width: 50%;
    margin: 0;
    height: 100%;
  }
  @media (max-width: 500px) {
  }
`

export const AdditionalTag = styled.div`
  width: auto;
  padding: 14px;

  height: 50px;
  align-items: center;
  display: flex;
  border-radius: 0px 0px 9px 9px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
  border-width: 0 1px 1px 1px;
  font-size: 18px;
  background-color: white;
  margin-right: 30px;
  @media (max-width: 980px) {
    font-size: 16px;
  }
`

export const PostsTag = styled.div`
  background-color: white;
  font-size: 14px;
  padding: 10px 20px;
  align-items: center;
  display: flex;
  border-radius: 8px;
  color: #454545;
  margin-right: 50px;
  border: 1px solid #454545;
  @media (max-width: 980px) {
    font-size: 12px;
    padding: 10px 20px;
    margin: 10px 10px 0 0;
  }

  @media (max-width: 500px) {
    margin: 0 5px 0;
    font-size: 11px;
    padding: 10px 20px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const HeaderTab = styled.div`
  display: flex;
  padding: 32px 0 9px 0;
  width: 100%;
  text-transform: uppercase;
  justify-content: center;
  background-color: #fff;
`

export const ProfileImageContainer = styled.div`
  height: 240px;
  border-radius: 100%;
  min-width: 240px;
  margin-left: 58px;
  margin-top: -100px;
  @media (max-width: 800px) {
    margin-left: 20px;
    height: 220px;
    width: 180px;
  }
`

export const ProfileIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  object-position: center;
`

export const CoverWrapper = styled.div`
  height: 364px;
  width: 100%;

  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
`

export const CoverIcon = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export const Icon = styled.img`
  display: flex;
`

export const FollowIcon = styled.img`
  display: flex;
  margin: 0 15px 0 15px;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Mobile = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const RowMobile = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const DesktopProfilepic = styled.div`
  display: flex;
`

export const HeadingContainer = styled.div`
  display: flex;
  text-transform: uppercase;
  margin-left: 40%;
  justify-content: space-between;
  padding: 9px;
  @media (max-width: 800px) {
    margin: 0;
  }
`

export const SocialMediaButton = styled.a`
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
`

export const SocialIcon = styled.img`
  width: 100%;
  object-fit: contain;
`

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #555;
  width: 180px;
  height: auto;
  border-radius: 30px;
  text-transform: uppercase;
  color: #fff;
`

export const Destination = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 10px;
`

export const Itinerary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 10px;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Text = styled.div`
  display: flex;
  font-size: 14px;
  align-self: flex-start;
  text-transform: uppercase;

  overflow-y: auto;
`

export const SmallText = styled.div`
  display: flex;
  font-size: 12px;
  align-self: center;
  text-transform: uppercase;
  margin-left: 10px;
`

export const Title = styled.span`
  font-size: 20px;
  margin: 10px;
  text-transform: uppercase;
  @media (max-width: 800px) {
    padding-left: 5px;
    font-size: 18px;
  }
`

export const FollowWrapper = styled.div`
  display: flex;
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 764px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const PostWrapper = styled.div`
  display: flex;
  margin-top: 36px;
  @media (max-width: 700px) {
    margin: 0;
  }
`

export const UserName = styled.span`
  font-weight: 600;
`

export const SettingsIcon = styled.img`
  height: 15px;
  width: 15px;
`

export const SettingsText = styled.span`
  color: #8c8c8c;
  display: flex;
  font-size: 16px;
  justify-content: center;
  border-radius: 5px;
  margin-left: 20px;
`

export const SettingsButton = styled.button`
  width: 187px;
  margin-top: -20px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  border-radius: 8px;
  border: 1px solid #757575;
  font-size: 18px;
  background-color: white;
  position: absolute;
  right: 0;
  cursor: pointer;
`

export const SearchIcon = styled.img`
  height: 29px;
  width: 29px;
  margin-left: 20px;
`

export const SearchText = styled.span`
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  border-radius: 5px;
`

export const SearchButton = styled.button`
  width: fit-content;
  height: 50px;
  border-radius: 0px 0px 9px 9px;
  border: 1px solid #8c8c8c;
  border-width: 0 1px 1px 1px;
  font-size: 18px;
  background-color: white;
  position: absolute;
  right: 0;
  margin-right: 30px;
  cursor: pointer;
`

export const LargeText = styled.span`
  font-size: 24px;
  font-weight: 400;
  color: #545454;
`
export const CollapseDescription = styled.div`
  display: flex;
  flex-direction: column;
`

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const PublishCentralized = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  @media (max-width: 500px) {
    width: 95%;
    height: auto;
    margin-left: 5px;
  }
`

export const UnRated = styled.div`
  font-size: 14px;
  width: 70px;
  flex-wrap: wrap;
`

export const PublishContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  width: 800px;
  height: 137.75px;

  border: 1px solid #d9d9d9;
  box-shadow:
    0px 4px 32px 7px rgba(202, 202, 202, 0.38),
    inset 12px 0px 0px #c62a31;
  border-radius: 12px;

  @media (max-width: 764px) {
    width: 100%;
  }
`

export const Publisher = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ItineraryImageContainer = styled.div`
  border-radius: 100%;
  overflow: hidden;
  height: 100px;
  width: 100px;

  @media (max-width: 500px) {
    height: 70px;
    width: 70px;
  }
`
export const ItineraryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Rating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Favorite = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
`

export const Configurations = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
`

export const FollowGroupContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40%;
  @media (max-width: 500px) {
    width: 100%;
  }
`

export const InterestTag = styled.div`
  width: auto;
  padding: 14px;
  height: 50px;
  align-items: center;
  display: flex;
  border-radius: 0px 0px 9px 9px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
  border-width: 0 1px 1px 1px;
  font-size: 18px;
  background-color: white;
  margin-right: 30px;
  @media (max-width: 980px) {
    height: 90px;
    font-size: 16px;
  }
  @media (max-width: 764px) {
    width: 100%;
    margin: 0 10px 0 0;
    justify-content: center;
    height: 100%;
    font-size: 16px;
  }
`

export const InterestsIconsWrapper = styled.div`
  display: flex;
  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`

export const InterestsContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  height: auto;
  align-items: center;
  padding: 10px;
  height: auto;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 800px) {
    width: auto;
    justify-content: flex-start;
  }
`

export const InterestsHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
`

export const InterestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 500px) {
    margin-left: 10px;
  }
`

export const InterestsCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 100px;
  height: 50px;
  margin-bottom: 10px;
`
export const InterestDescription = styled.span`
  font-size: 12px;
`

export const InterestIconContainer = styled.div`
  height: 29px;
  border-radius: 100%;
  overflow: hidden;
  width: 29px;
  margin-left: 10px;
  @media (max-width: 800px) {
  }
`

export const InterestIcon = styled.img`
  width: 100%;
  object-fit: contain;
  @media (max-width: 800px) {
  }
`
export const FollowColumn = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
`

export const Counter = styled.div`
  display: flex;
  color: #e90000;
  align-items: center;
  font-weight: bold;
  padding-left: 20px;
`

export const Roadmap = styled.span`
  color: #e90000;
  font-weight: bold;
`

export const AboutContainer = styled.div`
  background-color: #f5f5f5;
  color: #000;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    width: auto;
  }
`

export const Select = styled.div`
  background-color: #f5f5f5;
  color: #000;
  width: 100%;
  height: auto;
  padding: 10px 20px 10px 20px;
`

export const SelectDescription = styled.span`
  font-size: 16px;
  color: #000;
  margin: 0;
  font-weight: bold;
`

export const Divider = styled.div`
  width: auto;
  height: 1px;
  background-color: #000;
  margin-bottom: 25px;
`

export const ItineraryTittle = styled.div`
  font-size: 32px;
  line-height: 51px;
  text-align: center;
  margin-top: 24px;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`

export const ItineraryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 102px 8px 0px;
`

export const ItineraryWrapper = styled.div`
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: fit-content;
`

export const HeadingItinerary = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  height: auto;
`

export const ItineraryButtonProfile = styled(Link)`
  ${styleButton}
  align-self: flex-end;
  margin: 0 20px 0 0;
`

export const FollowContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  margin: 20px 0 20px 0;
  padding: 20px;
  flex-direction: column;
`
