import Modal from 'react-modal'
import { toast } from 'react-toastify'

import api from 'services/api'

import { useAuth } from 'contexts/auth.context'

import * as S from './style'

const DeleteAccountModal = (props: any) => {
  const { signOut } = useAuth()

  const customStyles = {
    overlay: {
      zIndex: 1000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '550px',
      height: '300px',
      borderRadius: '15px'
    }
  }

  async function setDeleteAccount() {
    try {
      await api.delete(`/users`)

      toast.success('Conta excluida com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
      setTimeout(() => signOut(), 3500)
    } catch {
      toast.error('Não foi possível remover essa conta', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }
  }

  return (
    <div>
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.closeModal}
        style={customStyles}
      >
        <S.Wrapper>
          <S.Container>
            <S.TitleContainer>
              <S.Title>Excluir conta?</S.Title>
              <S.Description>
                Tem certeza que gostaria de excluir a sua conta? Essa ação não
                poderá ser revertida e perderá seu acesso atual.
              </S.Description>
            </S.TitleContainer>
            <S.RowContainer>
              <S.CancelButton onClick={props.closeModal}>
                Cancelar
              </S.CancelButton>
              <S.DeleteButton onClick={setDeleteAccount}>
                Excluir{' '}
              </S.DeleteButton>{' '}
            </S.RowContainer>
          </S.Container>
        </S.Wrapper>
      </Modal>
    </div>
  )
}

export default DeleteAccountModal
