import React from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './style'

const NotFound = () => {
  const history = useHistory()
  return (
    <S.Container>
      <S.Title>Ops! Algo deu errado...</S.Title>
      <S.ImageDiv>
        <S.Image src="/images/not-found.png" />
      </S.ImageDiv>
      <S.Description>
        Essa página não existe ou não está disponível. Tente novamente.
      </S.Description>
      <S.Button onClick={() => history.push('')}>Voltar ao ínicio</S.Button>
    </S.Container>
  )
}

export default NotFound
