import React, { useState, useEffect, ReactNode } from 'react'

import { CityPhoto } from './style'

type GoogleMapsImageProps = {
  name: string
  children: ReactNode
}

const GoogleMapsImage = ({ name, children }: GoogleMapsImageProps) => {
  const [cityImage, setCityImage] = useState<string>()

  useEffect(() => {
    const getPlacePhoto = async (placeName: string) => {
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      )

      service.findPlaceFromQuery(
        {
          query: placeName,
          fields: ['name', 'photos']
        },
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const place = results?.[0]
            const photoUrl = place?.photos?.[0].getUrl()
            setCityImage(photoUrl)
          }
        }
      )
    }

    getPlacePhoto(name)
  }, [name])

  return (
    <CityPhoto id="city-photo" src={cityImage}>
      {children}
    </CityPhoto>
  )
}

export default GoogleMapsImage
